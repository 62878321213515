<template>
  <div
    class="container-fluid"
    style="background-color: #5c5c5c; position: fixed; width: 100%; height: 100%"
  >
    <iframe
      :src="url"
      class="iframe-console"
    />
  </div>
</template>

<script>
import dataRequester from '../mixins/utilities/DataRequester'
import inputHelper from '../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: 'Console',
  mixins: [dataRequester, inputHelper],
  data: function () {
    return {
      url: 'https://console.levnode.com',
      objectDef: {
        name: 'vm'
      }
    }
  },
  mounted: function() {
    this.FixBugTheme()
  },
  created: function () {
    this.url += '?user=' + this.$cookies.get('username') + '&sessionID=' + this.$cookies.get('sessionId') + '&vmid=' + this.$route.params.VMID + '&secure=1&subsite=' + window.location.host
  },
  methods: {
    FixBugTheme
  }
}
</script>

<style scoped>
</style>
