<template>
  <div>
    <div class="card mb-3">
      <div
        v-if="this.rawDataFromDB.length > 0"
        class="card-header pb-1"
      >
        <div
          class="btn-group d-flex justify-content-between"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <div>
            <input
              id="btnradioAllVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              checked
              @change="onVPSFilterChange($event)"
            >
            <label
              class="btn btn-outline-success"
              for="btnradioAllVPS"
            >{{ $t('proxy.button.all', [this.rawDataFromDB.length]) }}</label>

            <input
              v-if="this.getListVPSOverDue().length > 0"
              id="btnradioOverDueVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              @change="onVPSFilterChange($event)"
            >
            <label
              v-if="this.getListVPSOverDue().length > 0"
              class="btn btn-outline-warning"
              for="btnradioOverDueVPS"
            >{{ $t('proxy.button.overdue', [this.getListVPSOverDue().length]) }}</label>

            <input
              v-if="this.getListVPSSuspend().length > 0"
              id="btnradioSuspendVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              @change="onVPSFilterChange($event)"
            >
            <label
              v-if="this.getListVPSSuspend().length > 0"
              class="btn btn-outline-danger"
              for="btnradioSuspendVPS"
            >{{ $t('proxy.button.suspended', [this.getListVPSSuspend().length]) }}</label>

            <input
              v-if="this.getListVPSCancel().length > 0"
              id="btnradioCancelVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              @change="onVPSFilterChange($event)"
            >
            <label
              v-if="this.getListVPSCancel().length > 0"
              class="btn btn-outline-dark"
              for="btnradioCancelVPS"
            >{{ $t('proxy.button.cancel', [this.getListVPSCancel().length]) }}</label>
          </div>
          <div>
            <div class="dropdown d-inline m-1">
              <button
                class="btn bg-gradient-primary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fas fa-cog" /><span> {{ $t('proxy.button.actionMenu') }}</span>
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButtonControl"
              >
                <li v-for="(actionMenu, index) in actionMenuList" :key="index">
                  <span
                    class="dropdown-item"
                    @click="actionMenu.action"
                  >
                    <i :class="`${actionMenu.icon} menu-icon text-${actionMenu.variant}`" /><a> {{ actionMenu.label }}</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="pt-0 pb-0">
      </div>
      <div class="card-body mt-1 pt-0">
        <div class="lev-table">
          <lev-table
            :table-data="dataObjectList"
            :fields="columns"
            :searchalbe-fields="searchableFields"
            :extended-fields="extendedFields"
            :visible-fields="[]"
            :on-cell-click-processing="onCellClickProcessing"
            @selected-changed="onSelectedChanged"
          />
        </div>
      </div>
    </div>

    <new-confirm-modal
      id="edit-remark-modal"
      :title="$t('proxy.modal.title.editNote', [selectedObjectList.length])"
      :confirm-button="$t('proxy.modal.button.editNote')"
      @modalConfirm="confirmEditNote"
    >
      <div>
        <span>{{ $t('proxy.modal.body.description.editNote') }}</span>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
              v-for="(item, index) in selectedObjectList"
              :key="index"
            >
              <strong>{{ item['ip'] }}</strong>
            </li>
          </ol>
        </div>
        <span>{{ $t('proxy.modal.body.description.inputNoteField') }}</span>
        <div class="input-group input-group-outline my-3">
          <input
            id="editNote"
            v-model="noteForEdit"
            type="text"
            class="form-control"
            aria-describedby="detail"
          >
        </div>
      </div>
    </new-confirm-modal>
    <new-confirm-modal
      id="transfer-modal"
      :title="$t('proxy.modal.title.transferProxy', [selectedObjectList.length])"
      :confirm-button="$t('proxy.modal.button.transfer')"
      @modalConfirm="confirmTransfer"
    >
      <div>
        <span v-html="$t('proxy.modal.body.description.transferProxy')" />
        <div class="form-row mt-3 mb-3">
          <label class="col-form-label col-3">Email</label>
          <div class="input-group input-group-outline">
            <input
              id="transferEmailTarget"
              v-model="transferEmailTarget"
              class="form-control col-9"
            >
          </div>
        </div>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
              v-for="(item, index) in selectedObjectList"
              :key="index"
            >
              <strong>{{ item['ip'] }}</strong>
            </li>
          </ol>
        </div>
        <div
          class="alert modal-alert-secondary"
          v-html="$t('proxy.modal.body.warning.transferProxy')"
        />
      </div>
    </new-confirm-modal>
    <new-confirm-modal
      id="renew-modal"
      :title="$t('proxy.modal.title.renewProxy', [selectedObjectList.length])"
      :confirm-button="$t('proxy.modal.button.renew')"
      @modalConfirm="confirmRenew"
    >
      <div class="input-group input-group-outline my-3">
        <label class="form-label">{{ $t('proxy.modal.body.description.periodRenew') }}</label>
        <input
          v-model="periodRenew"
          type="number"
          min="1"
          max="24"
          class="form-control"
          onkeydown="return false"
        >
      </div>
      <span v-html="$t('proxy.modal.body.description.confirmRenew')" />
      <div class="alert modal-alert-info text-left">
        <ol>
          <li
            v-for="(item, index) in selectedObjectList"
            :key="index"
          >
            <strong>{{ item['ip'] }}</strong>
          </li>
        </ol>
      </div>
      <div
        class="alert modal-alert-secondary"
        v-html="$t('proxy.modal.body.warning.renewProxy')"
      />
    </new-confirm-modal>
    <new-confirm-modal id="change-modal"
                       :title="$t('proxy.modal.title.changeProxy', [selectedObjectList.length])"
                       :confirm-button="$t('proxy.modal.button.change')"
                       @modalConfirm="confirmChange"
    >
      <span v-html="$t('proxy.modal.body.description.confirmChange')"></span>
      <div class="alert modal-alert-info text-left">
        <ol>
          <li v-for="(item, index) in selectedObjectList" :key="index">
            <strong>{{ item['ip'] }}</strong>
          </li>
        </ol>
      </div>
      <div class="alert modal-alert-secondary" v-html="$t('proxy.modal.body.warning.changeProxy')"></div>
    </new-confirm-modal>
    <new-confirm-modal id="auto-renew-modal"
                       :title="$t('proxy.modal.title.autoRenewProxy', [selectedObjectList.length])"
                       :additional-button="{is_visible: true, text: $t('proxy.modal.button.cancelAutoRenew')}"
                       :confirm-button="$t('proxy.modal.button.autoRenew')"
                       @modalConfirm="confirmAutoRenew"
                       @additionalButtonClick="cancelAutoRenew"
    >
      <span v-html="$t('proxy.modal.body.description.confirmAutoRenew')" />
      <div class="alert modal-alert-info text-left">
        <ol>
          <li
            v-for="(item, index) in selectedObjectList"
            :key="index"
          >
            <strong>{{ item['ip'] }}</strong>
          </li>
        </ol>
      </div>
      <div
        class="alert modal-alert-secondary"
        v-html="$t('proxy.modal.body.warning.autoRenew')"
      />
    </new-confirm-modal>
    <new-confirm-modal
      id="restart-modal"
      :title="$t('proxy.modal.title.restart', [selectedObjectList.length])"
      :confirm-button="$t('proxy.modal.button.restart')"
      @modalConfirm="confirmRestart"
    >
      <div>
        <span>{{ $t('proxy.modal.body.description.confirmRestart') }}</span>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
              v-for="(item, index) in selectedObjectList"
              :key="index"
            >
              <strong>{{ item['ip'] }}</strong>
            </li>
          </ol>
        </div>
      </div>
    </new-confirm-modal>
    <new-confirm-modal
        id="cancel-modal"
        :title="$t('proxy.modal.title.cancel', [selectedObjectList.length])"
        :confirm-button="$t('proxy.modal.button.cancel')"
        @modalConfirm="confirmCancel"
    >
      <div>
        <span>{{ $t('proxy.modal.body.description.confirmCancel') }}</span>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
                v-for="(item, index) in selectedObjectList"
                :key="index"
            >
              <strong>{{ item['ip'] }}</strong>
            </li>
          </ol>
        </div>
        <div class="d-flex d-inline">
          <span>{{ $t('detail.modal.bodySelectCancelMode') }}</span>
          <div class="form-check mb-3">
            <input value="end-of-billing" class="form-check-input" type="radio" name="rbCancallation" id="rbCancallation1" checked>
            <label class="custom-control-label" for="rbCancallation1">{{ $t('detail.modal.bodyCancelModeEndOfBilling') }}</label>
          </div>
          <div class="form-check">
            <input value="immediate" class="form-check-input" type="radio" name="rbCancallation" id="rbCancallation2">
            <label class="custom-control-label" for="rbCancallation2">{{ $t('detail.modal.bodyCancelModeImmediate') }}</label>
          </div>
        </div>
        <div class="alert modal-alert-secondary">
          <div v-html="$t('proxy.modal.body.warning.bodyCancelWarning')"/>
        </div>
      </div>
    </new-confirm-modal>
    <new-confirm-modal
        id="revoke-cancellation-modal"
        :title="$t('proxy.modal.title.revokeCancellation', [selectedObjectList.length])"
        :confirm-button="$t('proxy.modal.button.revokeCancellation')"
        @modalConfirm="confirmRevokeCancellation"
    >
      <div>
        <span>{{ $t('proxy.modal.body.description.confirmRevokeCancellation') }}</span>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
                v-for="(item, index) in selectedObjectList"
                :key="index"
            >
              <strong>{{ item['ip'] }}</strong>
            </li>
          </ol>
        </div>
      </div>
    </new-confirm-modal>

    <new-confirm-modal
      id="add-proxy-modal"
      :title="$t('proxy.modal.title.addProxy', [selectedObjectList.length])"
      :confirm-button="$t('proxy.modal.button.addProxy')"
      @modalConfirm="checkProxyUsernameExisted"
    >
      <div>
        <p
          for="proxyType"
          class="ms-0"
        >
          {{ $t('proxy.modal.body.label.selectProxyType') }}
        </p>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <div>
            <input
              id="btnradioProxyTypeHTTP"
              type="radio"
              class="btn-check"
              name="btnradioProxyType"
              autocomplete="off"
              checked
              value="https"
              @change="proxyTypeOnChange($event)"
            >
            <label
              class="btn btn-outline-success"
              for="btnradioProxyTypeHTTP"
            >HTTPS</label>

            <input
              id="btnradioProxyTypeSocks5"
              type="radio"
              class="btn-check"
              name="btnradioProxyType"
              autocomplete="off"
              value="socks5"
              @change="proxyTypeOnChange($event)"
            >
            <label
              class="btn btn-outline-info"
              for="btnradioProxyTypeSocks5"
            >SOCKS5</label>
          </div>
        </div>

        <div class="input-group input-group-outline my-3">
          <label class="form-label">{{ $t('proxy.modal.body.label.proxyPort') }}</label>
          <input
            v-model="proxyPort"
            type="number"
            class="form-control"
          >
        </div>
        <div class="input-group input-group-outline my-3">
          <label class="form-label">{{ $t('proxy.modal.body.label.proxyUsername') }}</label>
          <input
            v-model="proxyUsername"
            type="text"
            class="form-control"
          >
        </div>
        <div class="input-group input-group-outline my-3">
          <label class="form-label">{{ $t('proxy.modal.body.label.proxyPassword') }}</label>
          <input
            v-model="proxyPassword"
            type="text"
            class="form-control"
          >
        </div>
      </div>
    </new-confirm-modal>
    <new-confirm-modal
      id="check-username-existed-modal"
      :title="$t('proxy.modal.title.usernameExisted')"
      @modalCancel="checkProxyUsernameExistedCancel"
      @modalConfirm="confirmAddNewProxy"
    >
      <div v-html="modalMessage" />
    </new-confirm-modal>
    <!-- otp modal -->
    <div
      id="otp_modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otp_modal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <confirm2-fa
              @clicked-confirm-button="confirmOTP"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LevTable from "@/components/children/LevTable";
import dataObjectListMixin from '@/mixins/DataObjectList'
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'

import NewConfirmModal from "@/components/children/NewConfirmModal";
import confirm2Fa from "@/components/children/Confirm2Fa";

export default {
  name: "BasicList",
  components: {
    LevTable,
    NewConfirmModal,
    confirm2Fa
  },
  mixins: [dataObjectListMixin, dataRequester, inputHelper],
  props: {
    metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    },
    deleteList: {
      default: function () { return [] },
      type: Array
    },
    items: []
  },
  data: function () {
    let self = this
    return {
      rawDataFromDB:[],
      columns: [
        {
          name: 'total_proxy',
          title: `<span><i class="fas fa-network-wired"></i> ${self.$t('proxy.table.header.total_proxy')}</span>`,
          sortField: 'total_proxy',
          order: 2
        },
        {
          name: 'price',
          title: `<span><i class="fas fa-dollar-sign"></i> ${self.$t('proxy.table.header.price')}</span>`,
          sortField: 'price',
          formatter(cellData) {
            return `<strong class="text-info">$${cellData.toFixed(2)}</strong>`
          },
          order: 3
        },
        {
          name: 'vm_plan_name',
          title: `<span><i class="fab fa-hive"></i> ${self.$t('proxy.table.header.plan_name')}</span>`,
          sortField: 'vm_plan_name',
          formatter(cellData) {
            return `<strong>${cellData}</strong>`
          },
          order: 4
        },
        {
          name: 'start_date',
          title: `<span><i class="fas fa-calendar-plus"></i> ${self.$t('proxy.table.header.start_date')}</span>`,
          sortField: 'start_date',
          formatter(cellData) {
            return `<span>${self.convertISODate(cellData)}</span>`
          },
          order: 7
        },
        {
          name: 'end_date',
          title: `<span><i class="fas fa-calendar-times"></i> ${self.$t('proxy.table.header.end_date')}</span>`,
          sortField: 'end_date',
          formatter(cellData) {
            return `<span>${self.convertISODate(cellData)}</span>`
          },
          order: 8
        },
      ],
      extendedFields: [
        {
          name: 'ip',
          title: `<span><i class="fas fa-ethernet"></i> ${self.$t('proxy.table.header.ip')}</span>`,
          sortField: 'ip',
          formatter(rowData) {
            return `<strong><a name="ip" href="javascript:;">${rowData['ip']}</a></strong>`
          },
          order: 1
        },
        {
          name: 'region',
          title: `<span><i class="fas fa-map-marker-alt"></i> ${self.$t('proxy.table.header.region')}</span>`,
          sortField: 'region',
          formatter: function (rowData) {
            return `<span class="text-info btn-tooltip cursor-pointer small" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${rowData.country} (${rowData.state})">
                    ${rowData['country_code']} (${rowData['state_code']})</span>`
          },
          order: 5
        },
        {
          name: 'transfer_limit',
          title: `<span><i class="fas fa-ethernet"></i> ${self.$t('proxy.table.header.transfer_limit')}</span>`,
          sortField: 'ip',
          formatter(rowData) {
            return `${(rowData['total_tx'] + rowData['total_rx']).toFixed(2)}/${rowData['transfer_limit']}GB <strong>(${((rowData['total_tx'] + rowData['total_rx'])/rowData['transfer_limit']*100).toFixed(2)}%)</strong>`
          },
          order: 6
        },
        {
          name: 'auto_renew',
          title: '<span class="text-uppercase"><i class="fas fa-retweet"></i> ' + self.$t('proxy.table.header.auto_renew') + '</span>',
          formatter: function(rowData){
            if (rowData['auto_renew'].toString() === '1' || rowData['auto_renew'].toString() === 'true') {
              return '<span class="text-success small text-bold">✓</span>'
            } else {
              return '<span class="text-danger small text-bold">✗</span>'
            }
          },
          sortField: 'auto_renew',
        },
        {
          name: 'user_remark',
          title: `<span><i class="fas fa-sticky-note"></i></i> ${self.$t('proxy.table.header.remark')}</span>`,
          formatter: function (rowData) {
            if (rowData['is_editing_note']) {
              return '<div class="input-group input-group-dynamic align-items-center small">' +
                  '<input type="text" class="form-control text-primary text-sm" value="' + rowData['user_remark'] + '">' +
                  '<span class="cursor-pointer fas fa-save text-success mx-1 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('proxy.tooltip.save_note') + '" name="saveNote"></span>' +
                  '<span class="cursor-pointer fas fa-window-close text-secondary mx-1 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('proxy.tooltip.cancel_note') + '" name="cancelNote"></span>' +
                  '</div>'
            } else {
              return '<div class="small d-flex justify-content-between">' + rowData['user_remark'] +
                  '<div>' +
                  ' <i class="cursor-pointer fas fa-edit text-info btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('proxy.tooltip.edit_note') + '" name="editNote"></i>' +
                  ' <i class="cursor-pointer fas fa-copy text-primary btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('proxy.tooltip.copy_note') + '" name="copyNote"></i>' +
                  '</div>' +
                  '</div>'
            }
          },
          extended_data: function (rowData) {
            // To allow search on the table
            return rowData['user_remark']
          },
          sortField: 'user_remark',
          width: '30%'
        },
        {
          name: 'payment_status',
          title: '<span class="text-uppercase"><i class="fas fa-file-invoice-dollar"></i> ' + self.$t('proxy.table.header.payment_status') + '</span>',
          formatter: function(rowData) {
            if (rowData['payment_status'] === 'ok') {
              if (rowData['end_date'] <= self.getCurrentTime()) {
                return '<div class="small"><span class="badge badge-warning"><span>' + self.$t('proxy.table.tdOverdue') + '</span></span></div>'
              } else {
                return '<div class="small"><span class="badge badge-success"><span>' + self.$t('proxy.table.tdOK') + '</span></span></div>'
              }
            } else if (rowData['payment_status'] === 'suspend') {
              return '<div class="small"><span class="badge badge-danger"><span>' + self.$t('proxy.table.tdSuspend') + '</span></span></div>'
            } else if (rowData['payment_status'] === 'cancel') {
              return '<div class="small"><span class="badge badge-dark"><span>' + self.$t('proxy.table.tdCancel') + '</span></span></div>'
            } else {
              return '<div class="small"><span class="badge badge-info"><span>' + self.$t('proxy.table.tdUnknown') + '</span></span></div>'
            }
          },
          sortField: 'payment_status',
        },
        {
          name: 'is_editing_note',
          extended_data: false,
          visible: false
        }
      ],
      searchableFields: ['ip', 'total_proxy', 'price', 'vm_plan_name', 'state_date', 'end_date', 'region', 'user_remark'],
      noteForEdit: '',
      periodRenew: 1,
      transferEmailTarget: '',
      otp_code: '',
      otpConfirmAction: 'None',

      proxyType: 'https',
      proxyPort: '',
      proxyUsername: '',
      proxyPassword: '',
      usernameExisted: null,
      modalMessage: '',
      actionMenuList: [
        {
          label: this.$t('proxy.button.addProxy'),
          icon: 'fas fa-plus',
          variant: 'success',
          action: this.addProxyModal
        },
        {
          label: this.$t('proxy.button.editNote'),
          icon: 'fas fa-edit',
          variant: 'primary',
          action: this.editNoteModal
        },
        {
          label: this.$t('proxy.button.restart'),
          icon: 'fas fa-sync',
          variant: 'danger',
          action: this.restartModal
        },
        {
          label: this.$t('proxy.button.transfer'),
          icon: 'fas fa-exchange-alt',
          variant: 'danger',
          action: this.transferModal
        },
        {
          label: this.$t('proxy.button.change'),
          icon: 'fas fa-sync-alt',
          variant: 'info',
          action: this.changeModal
        },
        {
          label: this.$t('proxy.button.renew'),
          icon: 'fas fa-calendar',
          variant: 'info',
          action: this.renewModal
        },
        {
          label: this.$t('proxy.button.cancel'),
          icon: 'fas fa-ban',
          variant: 'danger',
          action: this.cancelModal
        },
        {
          label: this.$t('proxy.button.revokeCancellation'),
          icon: 'fas fa-undo',
          variant: 'success',
          action: this.revokeCancellationModal
        },
        {
          label: this.$t('proxy.button.autorenew'),
          icon: 'fas fa-thumbs-up',
          variant: 'info',
          action: this.setAutoRenewModal
        }
      ]
    }
  },
  watch: {
    deleteList: {
      handler: function () {
        this.deleteList.forEach(value => {
          this.dataObjectList.forEach((item, index) => {
            if (item['id'] === value['vm_id']) {
              item['total_proxy'] = item['total_proxy'] - 1
            }
          })
        })
      },
      deep: true
    },
    items: {
      handler: function () {
        this.dataObjectList = this.items
        this.rawDataFromDB = [...this.items]
      },
      deep: true
    }
  },
  methods: {
    onSelectedChanged(selectedRowID) {
      this.selectedObjectList = this.dataObjectList.filter(d => selectedRowID.includes(d['id']))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      let self = this
      switch (cellField.name) {
        case 'user_remark':
          if (event.target.getAttribute('name') === 'editNote') {
            rowData['is_editing_note'] = true
          } else if (event.target.getAttribute('name') === 'copyNote') {
            self.copyContent(rowData['user_remark'])
          }
          else if (event.target.getAttribute('name') === 'cancelNote') {
            rowData['is_editing_note'] = false
          }
          else if (event.target.getAttribute('name') === 'saveNote') {
            let currentObjectList = [rowData]
            let newNote = event.target.parentElement.getElementsByTagName('input')[0].value
            this.submitObjectList(this.$config.apiPath.vpsNote, currentObjectList.map(function (a) { return {id: a.id} }), 'vm', {note: newNote}, function (result) {
              self.$toasted.success(result.msg)
              self.setItemProperty(rowData.id, 'user_remark', newNote)
            }, function () {
            })
            rowData['is_editing_note'] = false
          }
          break
        case 'ip':
          if (event.target.getAttribute('name') === 'ip') {
            this.$emit('filter-detail-by-ip', rowData['ip'])
          }
          break
        default:
          break
      }
      updatePostTable()
    },
    onVPSFilterChange: function(event) {
      let self = this
      switch(event.target.id) {
        case 'btnradioAllVPS':
          self.dataObjectList = self.rawDataFromDB
          break
        case 'btnradioOverDueVPS':
          self.dataObjectList = self.getListVPSOverDue()
          break
        case 'btnradioSuspendVPS':
          self.dataObjectList = self.getListVPSSuspend()
          break
        case 'btnradioCancelVPS':
          self.dataObjectList = self.getListVPSCancel()
          break
        case 'btnradioUnknownVPS':
          self.dataObjectList = self.getListVPSUnknown()
          break
          // case 'btnradioVPSShare':
          //   self.dataObjectList = self.getListVPSTeam()
          // default:
          break
      }
    },
    proxyTypeOnChange: function (event) {
      this.proxyType = event.target.value
    },

    validOpenModal: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('proxy.toast.errNoSelect'))
        return false
      }
      return true
    },
    editNoteModal: function () {
      let self = this
      if (self.validOpenModal()) {
        self.noteForEdit = self.selectedObjectList[0].user_remark
        if (self.selectedObjectList.length > 1) {
          self.noteForEdit = ''
        }

        $('#edit-remark-modal').modal('show')
      }
    },
    transferModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#transfer-modal').modal('show')
      }
    },
    renewModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#renew-modal').modal('show')
      }
    },
    cancelModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#cancel-modal').modal('show')
      }
    },
    revokeCancellationModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#revoke-cancellation-modal').modal('show')
      }
    },
    setAutoRenewModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#auto-renew-modal').modal('show')
      }
    },
    restartModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#restart-modal').modal('show')
      }
    },
    changeModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#change-modal').modal('show')
      }
    },
    addProxyModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#add-proxy-modal').modal('show')
      }
    },

    confirmEditNote: function () {
      $('#edit-remark-modal').modal('hide')
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsNote,
          this.selectedObjectList.map(function (a) { return {id: a.id} }),
          'vm',
          {note: self.noteForEdit},
          function (result) {
        self.$toasted.success(result.msg)
        for (let i = 0; i < self.selectedObjectList.length; i++) {
          self.setItemProperty(self.selectedObjectList[i].id, 'user_remark', self.noteForEdit)
        }
      })
    },
    confirmTransfer: function () {
      $('#transfer-modal').modal('hide')
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsTransfer,
          this.selectedObjectList.map(function (a) { return {id: a.id} }),
          'vm',
          {
            targetEmail: self.transferEmailTarget,
            otp_code: self.otp_code
          }, function (transferResult) {
            if (transferResult.data.is_enable_2fa) {
              self.otpConfirmAction = 'confirmTransfer'
              $('#otp_modal').modal('show')
            } else {
              self.$toasted.success(transferResult.msg)
              self.dataObjectList = self.dataObjectList.filter(ar => !self.selectedObjectList.find(rm => ar.id === rm.id))
            }
          }, null, true, function () {
            self.otp_code = ''
          })
    },
    confirmRenew: function () {
      $('#renew-modal').modal('hide')
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsRenew,
          this.selectedObjectList.map(function (a) {
            return {
              id: a.id
            }
          }),
          'vm',
          {
            period: self.periodRenew
          },
          function (renewResult) {
            self.$toasted.success(renewResult.msg)
            window.open('/#/home/order/detail/' + renewResult.data.id, '_blank')
          })
    },
    confirmChange: function () {
      $('#renew-modal').modal('hide')
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsChange,
          this.selectedObjectList.map(function (a) {
            return {
              id: a.id
            }
          }),
          'vm',
          undefined,
          function (result) {
            self.$toasted.success(result.msg)
            window.open('/#/home/order/detail/' + result.data.id, '_blank')
          })
    },
    confirmAutoRenew: function () {
      $('#auto-renew-modal').modal('hide')
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsAutoRenew, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'vm', {autoRenew: 1}, function (renewResult) {
        self.$toasted.success(renewResult.msg)
        for (let i = 0; i < self.selectedObjectList.length; i++) {
          let index = self.dataObjectList.findIndex(x => x.id == self.selectedObjectList[i].id)
          self.dataObjectList[index]['auto_renew'] = 1
        }
      })
    },
    cancelAutoRenew: function () {
      $('#auto-renew-modal').modal('hide')
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsAutoRenew, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'vm', {autoRenew: 0}, function (renewResult) {
        self.$toasted.success(renewResult.msg)
        for (let i = 0; i < self.selectedObjectList.length; i++) {
          let index = self.dataObjectList.findIndex(x => x.id == self.selectedObjectList[i].id)
          self.dataObjectList[index]['auto_renew'] = 0
        }
      })
    },
    confirmAddNewProxy: function () {
      $('#add-proxy-modal').modal('hide')
      $('#check-username-existed-modal').modal('hide')
      let self = this

      if (this.proxyPort == 2258 || this.proxyPort >= 65535 || this.proxyPort <= 1000) {
        this.$toasted.error(this.$t('proxy.toast.invalidPort', [this.proxyPort]))
      } else {
        let submitDataObjectList = this.selectedObjectList.map(function (obj) {
        return {
          'vm_id': obj['id'],
          'proxy_type': self.proxyType,
          'port': self.proxyPort,
          'proxy_username': self.proxyUsername,
          'proxy_password': self.proxyPassword
        }
      })
        self.showLoadingModal()
        this.submitObjectList(this.$config.apiPath.proxyDetailAdd,
            submitDataObjectList,
            'proxyDetail',
            undefined,
            function (result) {
              window.location.reload()
              self.$toasted.success(result.msg)
            }, function (err) {
              self.hiddenLoadingModal()
            })
      }
    },
    confirmRestart: function () {
      $('#restart-modal').modal('hide')
      let self = this
      this.submitPVEAction(this.$config.apiPath.vpsRestart,
          'vm',
          self.selectedObjectList,
          function (rowDataObject, status) {
      })
      this.$toasted.success(this.$t('proxy.toast.requestRestartingSuccess', [this.selectedObjectList.length]))
    },
    confirmCancel: function () {
      $('#cancel-modal').modal('hide')
      let self = this
      let cancelType = $('input[name=rbCancallation]:checked').val()
      this.submitObjectList(this.$config.apiPath.vpsCancel,
          this.selectedObjectList.map(function (a) { return {id: a.id} }),
          'vm',
          {cancelMode: cancelType},
          function (result) {
            self.$toasted.success(result.msg)
            if (cancelType === 'immediate') {
              self.dataObjectList = self.dataObjectList.filter(ar => !self.selectedObjectList.find(rm => ar.id === rm.id))
            } else if (cancelType === 'end-of-billing') {
              for (let i = 0; i < self.selectedObjectList.length; i++) {
                let index = self.dataObjectList.findIndex(x => x.id == self.selectedObjectList[i].id)
                self.dataObjectList[index]['payment_status'] = 'cancel'
              }
            }
          })
    },
    confirmRevokeCancellation: function () {
      $('#revoke-cancellation-modal').modal('hide')
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsRevokeCancellation,
          this.selectedObjectList.map(function (a) { return {id: a.id} }),
          'vm',
          undefined,
          function (result) {
            self.$toasted.success(result.msg)
            for (let i = 0; i < self.selectedObjectList.length; i++) {
              let index = self.dataObjectList.findIndex(x => x.id == self.selectedObjectList[i].id)
              self.dataObjectList[index]['payment_status'] = 'ok'
            }
          })
    },

    checkProxyUsernameExisted: function () {
      let self = this
      this.submitObjectList(self.$config.apiPath.checkProxyUsernameExisted,
          self.selectedObjectList.map(function (obj) {
            return {
              'vm_id': obj['id'],
              'proxy_username': self.proxyUsername
            }
          }),
          'proxyDetails',
          null,
          function (result) {
            if (![null, undefined].includes(result.data)) {
              self.modalMessage = self.$t('proxy.modal.body.warning.usernameExistedWarning', [self.proxyUsername])
              result.data.forEach(value => {
                self.modalMessage += self.$t('proxy.modal.body.warning.usernameExisted', value)
              })
              $('#check-username-existed-modal').modal('show')
              $('#add-proxy-modal').modal('hide')
            } else {
              self.confirmAddNewProxy()
            }
          }, undefined, true)
    },
    checkProxyUsernameExistedCancel: function () {
      $('#add-proxy-modal').modal('show')
    },

    getListVPSOverDue: function () {
      let currentDate = new Date(this.getCurrentTime())
      return this.rawDataFromDB.filter(item => item.end_date <= this.convertISODatePlusDays(currentDate, 3))
    },
    getListVPSCancel: function () {
      return this.rawDataFromDB.filter(item => item.payment_status === 'cancel')
    },
    getListVPSSuspend: function () {
      return this.rawDataFromDB.filter(item => item.payment_status === 'suspend')
    },

    confirmOTP: function (otp_code) {
      this.otp_code = otp_code
      switch(this.otpConfirmAction) {
        case 'confirmTransfer':
          this.confirmTransfer()
          break
        default:
          this.$toasted.error('OTP action not found')
          break
      }
      this.otpConfirmAction = 'none'
      $('#otp_modal').modal('hide')
    },
    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('detail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    },
  }
}
</script>

<style scoped>

</style>
