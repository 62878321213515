<template>
  <div
      id="confirmModal"
      ref="confirmModal"
      class="modal fade"
      role="dialog"
  >
    <div
        class="modal-dialog modal-lg"
    >
      <div class="modal-content" id="modal-content">
        <div class="modal-header">
          <div v-if="!!$slots['title']">
            <slot name="title"/>
          </div>
          <div v-else>
            <span
              class="modal-title"
              v-html="title"
            />
          </div>
        </div>
        <div class="modal-body mt-3">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <div class="d-flex justify-content-between w-100">
            <div>
              <slot name="left-footer"/>
            </div>
            <div class="d-flex align-content-center gap-3">
              <lev-button
                  class="my-2"
                  :disabled="isButtonLoading"
                  variant="secondary"
                  @click="onModalCancel"
              >
                {{ cancelButton }}
              </lev-button>
              <lev-button
                  class="my-2"
                  :loading="isButtonLoading"
                  :variant="confirmButtonVariant"
                  @click="onModalConfirm"
              >
                {{ confirmButton }}
              </lev-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import levButton from "@/components/children/lev_components/LevButton.vue";

export default {
  name: "LevModal",
  components: {
    levButton
  },
  props: {
    title: {
      type: String,
      default: "Modal title",
    },
    confirmButton: {
      type: String,
      default: function () {
        return this.$t('general.button.confirm')
      }
    },
    confirmButtonVariant: {
      type: String,
      default: "primary"
    },
    cancelButton: {
      type: String,
      default: function () {
        return this.$t('general.button.cancel')
      },
    },
    isManualClose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isButtonLoading: false
    }
  },
  methods: {
    onModalConfirm: function () {
      this.$emit('modalConfirm')
      if (this.isManualClose) {
        this.isButtonLoading = true
      } else {
        $(this.$refs.confirmModal).modal('hide')
      }
    },
    onModalCancel: function () {
      this.$emit('modalCancel')
      $(this.$refs.confirmModal).modal('hide')
    },
    hide: function () {
      if (this.isManualClose) {
        this.isButtonLoading = false
      }
      $(this.$refs.confirmModal).modal('hide')
    }
  },
}
</script>

<style scoped>

</style>
