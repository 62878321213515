<template>
  <div
      class="btn-group mt-3 width-full row"
      role="group"
  >
    <div
        v-for="(value, key) in items"
        :key="key"
        :class="{'col-lg-4 col-md-6 col-sm-6': true, 'mt-sm-0 mt-4': key !== 0}"
    >
      <input
          :id="value['os_type'] + value['id']"
          type="radio"
          class="btn-check"
          name="btnradioImage"
          :value="value['id']"
          autocomplete="off"
          :checked="value['id'] === imageIdDefault"
          @change="$emit('change', value['id'])"
      >
      <label
          :class="{'btn min-height-120 p-1 d-flex': true, 'btn-outline-info': value['os_type'] === 'Windows', 'btn-outline-primary': value['os_type'] !== 'Windows'}"
          :for="value['os_type'] + value['id']"
          :value="value['id']"
      >
        <div class="card width-full min-height-120 d-flex align-items-center justify-content-center">
          <div class="d-flex align-items-center m-2">
            <i
                v-if="value['os_type'] === 'Windows'"
                class="fab fa-5x fa-windows text-info"
            />
            <i
                v-else-if="value['os_type'] === 'Ubuntu'"
                class="fab fa-5x fa-ubuntu text-primary"
            />
            <i
                v-else-if="value['os_type'] === 'Linux'"
                class="fab fa-5x fa-linux text-primary"
            />
            <i
                v-else-if="value['os_type'] === 'Centos'"
                class="fab fa-5x fa-centos text-primary"
            />
            <span :class="{'ms-3': true, 'text-info': value['os_type'] === 'Windows', 'text-primary': value['os_type'] !== 'Windows'}">{{ value['name'] }}</span>
          </div>
          <div
              v-if="![undefined, null].includes(value['description']) && isValidJSON(value['description'])"
              class="text-start mx-2"
          >
            <span class="all-init text-danger font-italic text-sm">* {{ getImageDescription(value['description']) }}</span>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>

import inputHelper from "@/mixins/utilities/InputHelper";

export default {
  name: "OsList",
  mixins: [inputHelper],
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    imageIdDefault: {
      type: Number,
      default: null
    },
    languageSelected: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {

    }
  },
  methods: {
    getImageDescription: function (jsonString) {
      if (this.isValidJSON(jsonString)) {
        try {
          return JSON.parse(jsonString)['description'][this.languageSelected.substring(0,2)]
        } catch (ex) {
          return null
        }
      } else {
        return null
      }
    }
  }
}
</script>
