<template>
  <div class="">
    <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex justify-content-start">
          <router-link to="/home/cloud-vps">
            <i class="fa fa-arrow-circle-left align-bottom">
              <span class="align-bottom"> Back</span>
            </i>
          </router-link>
          <span class="ml-2"><h4 class="text-info">&ensp;{{ getIPPortFormat(VMObject['ip'], VMObject['port'], this.VMObject['os_type']) }}</h4></span>
        </div>
      </div>
    </div>
    <div class="row margin-right-0">
      <div class="col-sm-8">
        <!--          card General Information-->
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">
              <span class="text-primary"><i class="fa fa-globe" /> {{ $t('detail.cardBody.titleGeneral') }}</span>
            </h6>
            <div class="table-responsive">
              <table class="table table-sm table-hover table-striped">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleMainIP') }}
                    </td>
                    <td>
                      <span class="text-danger">
                        <strong>{{ getIPPortFormat(VMObject['ip'], VMObject['port'], VMObject['os_type']) }}</strong>
                        &ensp;
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="$t('detail.tooltip.tdClickToCopy')"
                          class="badge bg-gradient-primary cursor-pointer"
                          @click="copyContent(getIPPortFormat(VMObject['ip'], VMObject['port'], VMObject['os_type']))"
                        >
                          <span class="fa fa-copy" /> {{ $t('detail.table.tdClickToCopy') }}
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="VMObject['rgc_server_name'] !== null && VMObject['rgc_server_name'] !== undefined">
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleRGCName') }}
                    </td>
                    <td>
                      <span class="text-success">
                        {{ VMObject['rgc_server_name'] }}:{{ VMObject['rgc_forwarded_port'] }}
                        &ensp;
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="$t('detail.tooltip.tdClickToCopy')"
                          class="badge bg-gradient-primary cursor-pointer"
                          @click="copyContent(VMObject['rgc_server_name'] + ':' + VMObject['rgc_forwarded_port'])"
                        >
                          <span class="fa fa-copy" /> {{ $t('detail.table.tdClickToCopy') }}
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleStatus') }}
                    </td>
                    <td><span v-html="VMOperStatus" /></td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitlePay') }}
                    </td>
                    <td><span v-html="VMPaymentStatus" /></td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleLocation') }}
                    </td>
                    <td>{{ VMObject.country }} ({{ VMObject.state }})</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleSpec') }}
                    </td>
                    <td><strong class="text-info">{{ VMObject['plan_reseller_name'] }}</strong>{{ VMObject['vcores'] }} CPU, {{ VMObject['ram'] }}MB RAM, {{ VMObject['disk'] }}GB {{ VMObject['disk_type'] }} Disk, Transfer {{ (VMObject['transfer_limit'] > 0) ? `${VMObject['transfer_limit']}GB`: 'Unlimited' }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleOS') }}
                    </td>
                    <td><span :class="OSTypeIcon" /> {{ VMObject['image_name'] }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleCpuOptimization') }}
                    </td>
                    <td>
                      <span v-if="VMObject['cpu_optimization'] === 'normal'">
                        {{ $t('detail.table.tdOptimizeNormal') }}
                      </span>
                      <span v-else-if="VMObject['cpu_optimization'] === 'virtualization'">
                        {{ $t('detail.table.tdOptimizeForEmulator') }}
                      </span>
                      <span v-else>
                        {{ VMObject['cpu_optimization'] }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleUser') }}
                    </td>
                    <td class="">
                      {{ VMObject['login_user'] }}
                      <span
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="$t('detail.tooltip.tdClickToCopy')"
                        class="badge bg-gradient-primary cursor-pointer"
                        @click="copyContent(VMObject['login_user'])"
                      >
                        <span class="fa fa-copy" /> {{ $t('detail.table.tdClickToCopy') }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitlePass') }}
                    </td>
                    <td class="">
                      {{ VMObject['login_password'] }}
                      <span
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="$t('detail.tooltip.tdClickToCopy')"
                        class="badge bg-gradient-primary cursor-pointer"
                        @click="copyContent(VMObject['login_password'])"
                      >
                        <span class="fa fa-copy" /> {{ $t('detail.table.tdClickToCopy') }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTitleNote') }}
                    </td>
                    <td>
                      {{ VMObject.user_remark }}
                      <i
                        class="cursor-pointer fas fa-copy text-primary btn-tooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-container="body"
                        data-animation="true"
                        :title="$t('detail.tooltip.copyRemark')"
                        name="copyNote"
                        @click="copyContent(VMObject.user_remark)"
                      />
                      <label />
                      <i
                        class="cursor-pointer fas fa-edit text-info btn-tooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-container="body"
                        data-animation="true"
                        :title="$t('detail.tooltip.editRemark')"
                        name="editNote"
                        @click="editRemark"
                      />
                    </td>
                  </tr>
                  <tr v-if="currentUser['team_private_key'] === null && VMObject['co_owner_id'] !== null">
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdOwner') }}
                    </td>
                    <td>
                      <a
                        :href="`https://facebook.com/${VMObject['owner_facebook_uid']}`"
                        target="_blank"
                      ><i class="fab fa-facebook-square" /> {{ VMObject['owner_full_name'] }}</a>
                    </td>
                  </tr>
                  <tr v-if="currentUser['team_private_key'] !== null && VMObject['co_owner_id'] !== null">
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdCo_Owner') }}
                    </td>
                    <td>
                      <a
                        :href="`https://facebook.com/${VMObject['co_owner_facebook_uid']}`"
                        target="_blank"
                      ><i class="fab fa-facebook-square" /> {{ VMObject['co_owner_full_name'] }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('detail.table.tdTransferLimit') }}
                    </td>
                    <td>
                      <div class="lev-progress">
                        <div class="lev-progress-bar">
                          <div class="progress-bg">
&nbsp;
                          </div>
                          <div
                            class="progress-percent"
                            :style="`width: ${((VMObject['total_tx'] + VMObject['total_rx'])/VMObject['transfer_limit'] * 100).toFixed(2)}%;`"
                          >
&nbsp;
                          </div>
                        </div>
                        <div class="percent-text">
                          {{ ((VMObject['total_tx'] + VMObject['total_rx'])/VMObject['transfer_limit'] * 100).toFixed(2) }}% ({{ (VMObject['total_tx'] + VMObject['total_rx']).toFixed(2) }}/{{ VMObject['transfer_limit'] }}GB)
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--            control button-->
        <div class="card mt-3">
          <div class="card-body">
            <h6 class="card-title">
              <span class="text-primary"><i class="fas fa-cog" /> {{ $t('detail.cardBody.titleControl') }}</span>
            </h6>
            <div class="row">
              <div name="power button">
                <button
                  class="btn btn-detail-control btn-success"
                  @click="confirmVM('start')"
                >
                  <i class="fa fa-play fa-2x" /><br>{{ $t('detail.button.start') }}
                </button>
                <button
                  class="btn btn-detail-control btn-info"
                  @click="confirmVM('shutdown')"
                >
                  <i class="fa fa-power-off fa-2x" /><br>{{ $t('detail.button.shutdown') }}
                </button>
                <button
                  class="btn btn-detail-control btn-danger"
                  @click="confirmVM('stop')"
                >
                  <i class="fa fa-stop fa-2x" /><br>{{ $t('detail.button.stop') }}
                </button>
                <button
                  class="btn btn-detail-control btn-warning"
                  @click="confirmVM('restart')"
                >
                  <i class="fa fa-refresh fa-2x" /><br>{{ $t('detail.button.restart') }}
                </button>
                <button
                    class="btn btn-detail-control btn-success"
                    @click="changeOptimization"
                >
                  <i class="fas fa-microchip fa-2x" /><br>{{ $t('detail.button.cpuOptimization') }}
                </button>
              </div>
              <div name="remote button">
                <button
                  class="btn btn-detail-control btn-outline-info"
                  @click="consoleVM"
                >
                  <span class="fa-stack fa-1x">
                    <i class="fa fa-television fa-stack-2x" />
                    <span class="text-center">VNC</span>
                  </span><br>
                  {{ $t('detail.button.console') }}
                </button>
                <button
                  class="btn btn-detail-control btn-outline-info"
                  @click="generateRDPFile"
                >
                  <i class="fa fa-windows fa-2x" /><br>{{ $t('detail.button.rdp') }}
                </button>
                <button
                  v-if="VMObject['rgc_server_name'] === null || VMObject['rgc_server_name'] === undefined"
                  class="btn btn-detail-control btn-outline-success"
                  @click="createRGC(VMObject)"
                >
                  <i class="fas fa-link fa-2x" /><br>{{ $t('detail.button.createRGC') }}
                </button>
                <button
                  v-if="VMObject['rgc_server_name'] !== null && VMObject['rgc_server_name'] !== undefined"
                  class="btn btn-detail-control btn-outline-danger"
                  @click="deleteRGC(VMObject)"
                >
                  <i class="fas fa-unlink fa-2x" /><br>{{ $t('detail.button.deleteRGC') }}
                </button>
              </div>
              <div
                v-if="!isTeamMember"
                name="os button"
              >
                <button
                  class="btn btn-detail-control btn-danger"
                  @click="rebuildVM"
                >
                  <i class="fa fa-floppy-o fa-2x" /><br>{{ $t('detail.button.reinstall') }}
                </button>
                <button
                  v-if="metaData.isMenuSnapshotEnable"
                  class="btn btn-detail-control btn-secondary"
                  :disabled="VMObject['disk'] > 60"
                  @click="takeSnap"
                >
                  <i class="fa fa-2x fa-cloud-upload" /><br>{{ $t('detail.button.snapshot') }}
                </button>
                <button
                  v-if="metaData.isMenuSnapshotEnable"
                  class="btn btn-detail-control btn-secondary"
                  :disabled="VMObject['is_outsource'] === 1"
                  @click="restoreVM"
                >
                  <i class="fa fa-2x fa-cloud-download" /><br>{{ $t('detail.button.restore') }}
                </button>
                <button
                  v-if="metaData.isMenuBackupEnable && (VMObject['backup_id'] == null)"
                  class="btn btn-detail-control btn-info"
                  :disabled="VMObject['disk'] > 60"
                  @click="enableBackup"
                >
                  <i class="fa fa-2x fa-hdd-o" /><br>{{ $t('detail.button.enableBackup') }}
                </button>
                <button
                  v-else-if="metaData.isMenuBackupEnable && (VMObject['backup_id'] !== null)"
                  class="btn btn-detail-control btn-danger"
                  @click="disableBackup"
                >
                  <i class="fa fa-2x fa-hdd-o" /><br>{{ $t('detail.button.disableBackup') }}
                </button>
              </div>
              <div
                v-if="!isTeamMember"
                name="other button"
              >
                <button
                  v-if="currentUser['team_private_key'] !== null"
                  class="btn btn-detail-control btn-outline-info"
                  @click="teamMemberShare"
                >
                  <i class="fas fa-users fa-2x" /><br>{{ $t('detail.button.team') }}
                </button>
                <button
                  class="btn btn-detail-control btn-outline-warning"
                  @click="transfer"
                >
                  <i class="fa fa-2x fa-exchange" /><br>{{ $t('detail.button.transfer') }}
                </button>
                <button
                    class="btn btn-detail-control btn-outline-info"
                    @click="change"
                >
                  <i class="fa fa-2x fa-sync" /><br>{{ $t('detail.button.change') }}
                </button>
                <button
                  class="btn btn-detail-control btn-outline-primary"
                  @click="renew"
                >
                  <i class="fa fa-2x fa-calendar" /><br>{{ $t('detail.button.renew') }}
                </button>
                <button
                  v-if="VMObject['auto_renew'] === 0"
                  class="btn btn-detail-control btn-outline-success"
                  @click="setAutoRenew"
                >
                  <i class="fa fa-2x fa-thumbs-o-up" /><br>{{ $t('detail.button.setAutoRenew') }}
                </button>
                <button
                  v-else
                  class="btn btn-detail-control btn-outline-danger"
                  @click="setAutoRenew"
                >
                  <i class="fa fa-2x fa-hand-stop-o" /><br>{{ $t('detail.button.cancelAutoRenew') }}
                </button>
                <button v-if="VMObject['payment_status'] === 'ok'"
                  class="btn btn-detail-control btn-outline-danger"
                  @click="cancel"
                >
                  <i class="fa fa-2x fa-times" /><br>{{ $t('detail.button.cancel') }}
                </button>
                <button v-if="VMObject['payment_status'] === 'cancel'"
                    class="btn btn-detail-control btn-outline-success"
                    @click="revokeCancellation"
                >
                  <i class="fa fa-2x fa-times" /><br>{{ $t('detail.button.revokeCancellation') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <!--            card Payment Information-->
          <div class="card">
            <div class="card-body">
              <h6 class="card-title">
                <span class="text-primary">
                  <i class="fa fa-puzzle-piece" /> {{ $t('detail.cardBody.titlePayment') }}
                </span>
              </h6>
              <div class="table-responsive">
                <table class="table table-sm table-hover table-striped table-responsive">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleStart') }}
                      </td>
                      <td>{{ convertISODate(VMObject['start_date']) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleEnd') }}
                      </td>
                      <td>{{ convertISODate(VMObject['end_date']) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleAutoRenew') }}
                      </td>
                      <td
                        v-if="VMObject['auto_renew'] === 1"
                        class="text-success"
                      >
                        {{ $t('detail.table.tdAutoRenewEnabled') }}
                      </td>
                      <td
                        v-else
                        class="text-danger"
                      >
                        {{ $t('detail.table.tdAutoRenewDisabled') }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitlePlan') }}
                      </td>
                      <td>{{ VMObject['vm_plan_name'] }} (<span class="text-primary">${{ VMObject.price_output }} {{ $t('detail.table.tdDataEachMonth') }}</span>)</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleTotalAddonPrice') }}
                      </td>
                      <td><span class="text-primary">${{ VMObject.addon_revenue !== null ? VMObject.addon_revenue : 0 }} {{ $t('detail.table.tdDataEachMonth') }}</span></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleTotalPrice') }}
                      </td>
                      <td><span class="text-primary">${{ parseFloat(VMObject.price_output) + parseFloat(VMObject.addon_revenue !== null ? VMObject.addon_revenue : 0) }} {{ $t('detail.table.tdDataEachMonth') }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--            card Network Information-->
          <div class="card mt-3">
            <div class="card-body">
              <h6 class="card-title">
                <span class="text-primary"><i class="fa fa-wifi" /> {{ $t('detail.cardBody.titleNetwork') }}</span>
              </h6>
              <div class="table-responsive">
                <table
                  class="table table-sm table-hover table-striped"
                  style="table-layout: fixed"
                >
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">
                        <div class="text-wrap">
                          {{ $t('detail.table.tdTitleOutgoing') }}
                        </div>
                      </td>
                      <td><span class="text-success"><strong>{{ VMObject['ip'] }}</strong></span></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleDedicatedIP') }}
                      </td>
                      <td>{{ VMObject['no_ipv4'] }}</td>
                    </tr>
                    <tr>
                      <td
                        colspan="2"
                      >
                        <div class="text-wrap">
                          <small>{{ $t('detail.label.localNetwork') }}</small>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleLocalIP') }}
                      </td>
                      <td>{{ VMObject['local_ip'] }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleLocalMask') }}
                      </td>
                      <td>{{ VMObject['local_netmask'] }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleLocalGw') }}
                      </td>
                      <td>{{ VMObject['local_gateway'] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--            card Addon-->
          <div class="card mt-3">
            <div class="card-body">
              <h6 class="card-title">
                <span class="text-primary">
                  <i class="fa fa-puzzle-piece" /> {{ $t('detail.cardBody.titleAddon') }}
                </span>
              </h6>
              <div class="table-responsive">
                <table class="table table-sm table-hover table-striped">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleBackup') }}
                      </td>
                      <td v-if="VMObject['backup_period'] === 7">
                        Weekly
                      </td>
                      <td v-else-if="VMObject['backup_period'] === 1">
                        Daily
                      </td>
                      <td v-else>
                        No
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('detail.table.tdTitleAddon') }}
                      </td>
                      <td v-if="VMAddonObject.activeAddonObjectList.length === 0">
                        No
                      </td>
                      <!-- To prevent the undefined to JSON.parse -->
                      <td v-else-if="VMAddonObject.activeAddonObjectList.length > 0">
                        <table class="table table-sm table-hover table-striped">
                          <thead>
                            <tr>
                              <td><strong class="text-info">#</strong></td>
                              <td><strong class="text-info">{{ $t('detail.table.tdTitleAddonName') }}</strong></td>
                              <td><strong class="text-info">{{ $t('detail.table.tdTitleAddonPrice') }}</strong></td>
                              <td><strong class="text-info">{{ $t('detail.table.tdTitleAddonAmount') }}</strong></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(addon, index) in VMAddonObject.activeAddonObjectList"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td class="text-wrap">
                                {{ addon['name'] }}
                              </td>
                              <td>${{ addon['price'] === undefined ? 0:addon['price'].toFixed(2) }}</td>
                              <td class="text-center">
                                {{ addon['amount'] }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="VMObject['port'] !== '3389'"
      class="card mt-3"
    >
      <div class="card-body">
        <h6 class="card-title">
          <span class="text-primary">
            <i class="fas fa-link" /> {{ $t('detail.cardBody.titlePortMap') }}
          </span>
        </h6>
        <div
          class="alert modal-alert-secondary mt-1"
          v-html="$t('detail.label.portMap', [VMObject.ip + ':' + (parseInt(VMObject.port) + 2)])"
        />
        <div class="table-responsive">
          <table class="table table-sm table-hover table-striped">
            <tbody>
              <tr>
                <th>{{ $t('detail.table.tdServiceName') }}</th>
                <th>{{ $t('detail.table.tdNormalPort') }}</th>
                <th>{{ $t('detail.table.tdMappedPort') }}</th>
              </tr>
              <tr>
                <td>Remote desktop connection (RDP)</td>
                <td>3389</td>
                <td>{{ (parseInt(VMObject.port) + 0) }}</td>
              </tr>
              <tr>
                <td>SSH</td>
                <td>22</td>
                <td>{{ (parseInt(VMObject.port) + 1) }}</td>
              </tr>
              <tr>
                <td>HTTP</td>
                <td>80</td>
                <td>{{ (parseInt(VMObject.port) + 2) }}</td>
              </tr>
              <tr>
                <td>HTTPS</td>
                <td>443</td>
                <td>{{ (parseInt(VMObject.port) + 3) }}</td>
              </tr>
              <tr>
                <td>Microsoft SQL Server</td>
                <td>1433</td>
                <td>{{ (parseInt(VMObject.port) + 4) }}</td>
              </tr>
              <tr>
                <td>Samba file server</td>
                <td>445</td>
                <td>{{ (parseInt(VMObject.port) + 5) }}</td>
              </tr>
              <tr>
                <td>MySQL / Maria DB Server</td>
                <td>3306</td>
                <td>{{ (parseInt(VMObject.port) + 6) }}</td>
              </tr>
              <tr>
                <td>L2TP VPN</td>
                <td>1701</td>
                <td>{{ (parseInt(VMObject.port) + 7) }}</td>
              </tr>
              <tr>
                <td>Tomcat / NodeJS Web server</td>
                <td>8080</td>
                <td>{{ (parseInt(VMObject.port) + 8) }}</td>
              </tr>
              <tr>
                <td>Samba server</td>
                <td>139</td>
                <td>{{ (parseInt(VMObject.port) + 9) }}</td>
              </tr>
              <tr>
                <td>FTP server</td>
                <td>21</td>
                <td>{{ (parseInt(VMObject.port) + 10) }}</td>
              </tr>
              <tr>
                <td>{{ $t('detail.table.tdCustomPort') }}</td>
                <td>{{ (parseInt(VMObject.port) + 50) }} -> {{ (parseInt(VMObject.port) + 99) }}</td>
                <td>{{ (parseInt(VMObject.port) + 50) }} -> {{ (parseInt(VMObject.port) + 99) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <confirm-modal
      :action="action"
      :active="active"
      :confirm-modal-display-object="confirmModalDisplayObject"
      @modalCancel="finishModal"
      @modalConfirm="onConfirmButton('')"
      @modalAdditionalButton="onModalAdditionalButton"
      @finishAction="finishAction"
    />
    <!-- otp modal -->
    <div
      id="otp_modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otp_modal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <confirm2-fa
              @clicked-confirm-button="confirmOTP"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- rebuild images vm -->
    <lev-modal
      id="modalRebuild"
      :title="`<h4>${this.$t('detail.modal.titleConfirmRebuild')}</h4>`"
      :confirm-button="this.$t('detail.modal.buttonRebuild')"
      @modalConfirm="confirmRebuild"
    >
      <div class="form-group">
        <label for="selectImageRebuild">{{ this.$t('detail.modal.bodySelectOS') }}</label>
        <select
          id="selectImageRebuild"
          class="form-select ps-3 mb-3"
          @change="imageSelectChange"
        >
          <option
            v-for="(image, index) in this.imageValues.imageListCache"
            :key="index"
            :value="image.id"
          >
            {{ image.imageName }}
          </option>
        </select>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li><strong>{{ getIPPortFormat(VMObject['ip'], VMObject['port'], VMObject['os_type']) }}</strong></li>
          </ol>
        </div>
        <div>
          <p
            v-if="isValidJSON(this.imageValues.currentImageSelection['description'])"
            class="text-success"
          >
            <strong>{{ this.$t('detail.label.note') }}: </strong>
            <span v-html="JSON.parse(this.imageValues.currentImageSelection['description'])['description'][languageSelected.substring(0,2)]" />
          </p>
          <p
            class="text-danger"
            v-html="this.$t('detail.modal.bodyRebuildWarning')"
          />
        </div>
      </div>
    </lev-modal>
    <!-- restore snapshot modal -->
    <lev-modal
      id="restore-snapshot-modal"
      :title="`<h4>${this.$t('detail.modal.titleConfirmRestore')}</h4>`"
      :confirm-button="this.$t('detail.modal.buttonRestore')"
      @modalConfirm="confirmRestore"
    >
      <div class="form-group">
        <label for="selectSnapshotRestore">
          {{ this.$t('detail.modal.bodySelectSnapshot') }}
        </label>
        <select
          id="selectSnapshotRestore"
          class="form-select ps-3 mb-3"
          @change="snapshotSelectChange"
        >
          <option
            v-for="(snapshot, index) in this.imageValues.snapshotListCache"
            :key="index"
            :value="snapshot.id"
          >
            {{ snapshot.snapshotName }} ({{ snapshot.note }}) (OS - {{ snapshot.imageName }})
          </option>
        </select>
        <div class="alert modal-alert-info text-left">
          <strong>IP {{ getIPPortFormat(VMObject['ip'], VMObject['port']) }}</strong>
        </div>
        <div class="text-danger">
          <p
            v-if="this.imageValues.currentSnapshotSelection['note']"
            class="text-success"
          >
            <strong>{{ this.$t('detail.label.note') }}: </strong>
            <span> {{ this.imageValues.currentSnapshotSelection['note'] }} </span>
          </p>
          <span v-html="this.$t('detail.modal.bodyRestoreWarning')" />
        </div>
      </div>
    </lev-modal>
    <!-- change cpu optimization modal -->
    <lev-modal
        id="change-optimization-modal"
        :title="`<h4>${this.$t('detail.modal.titleConfirmChangeCpuOPtimization')}</h4>`"
        :confirm-button="this.$t('detail.modal.buttonConfirm')"
        @modalConfirm="confirmChangeCpuOptimization"
    >
      <div class="form-group">
        <label for="selectCpuOptimization">
          {{ this.$t('detail.modal.bodySelectCpuOptimization') }}
        </label>
        <select
            id="selectCpuOptimization"
            class="form-select ps-3 mb-3"
            @change="cpuOptimizationChange"
        >
          <option
              v-for="(item, index) in this.cpuOptions"
              :key="index"
              :value="item.key"
          >
            {{ item.value }}
          </option>
        </select>
        <div class="alert modal-alert-info text-left">
          <strong>IP {{ getIPPortFormat(VMObject['ip'], VMObject['port']) }}</strong>
        </div>
        <div class="text-danger">
          <span v-html="this.$t('detail.modal.bodyCpuOptimizationWarning')" />
        </div>
      </div>
    </lev-modal>
    <!-- revoke cancellation modal -->
    <lev-modal
        id="revoke-cancellation-modal"
        :title="`<h4>${this.$t('detail.modal.titleConfirmRevokeCancellation')}</h4>`"
        :confirm-button="this.$t('detail.modal.buttonRevokeCancellation')"
        @modalConfirm="confirmRevokeCancellation"
    >
      <div class="form-group">
        <div class="alert modal-alert-info text-left">
          <strong>IP {{ getIPPortFormat(VMObject['ip'], VMObject['port']) }}</strong>
        </div>
        <div class="text-danger">
          <span v-html="this.$t('detail.modal.bodyRevokeCancellationWarning')" />
        </div>
      </div>
    </lev-modal>
    <!-- cancel modal -->
    <new-confirm-modal
        id="cancel-modal"
        :title="$t('detail.modal.titleConfirmCancel')"
        :confirm-button="$t('detail.modal.buttonCancelVPS')"
        @modalConfirm="confirmCancel"
    >
      <div>
        <span v-html="$t('detail.modal.bodyConfirmCancel')"/>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
                v-for="(item, index) in [VMObject]"
                :key="index"
            >
              <strong>{{ item['ip'] }}</strong>
            </li>
          </ol>
        </div>
        <div class="d-flex d-inline">
          <span>{{ $t('detail.modal.bodySelectCancelMode') }}</span>
          <div class="form-check mb-3">
            <input value="end-of-billing" class="form-check-input" type="radio" name="rbCancallation" id="rbCancallation1" checked>
            <label class="custom-control-label" for="rbCancallation1">{{ $t('detail.modal.bodyCancelModeEndOfBilling') }}</label>
          </div>
          <div class="form-check">
            <input value="immediate" class="form-check-input" type="radio" name="rbCancallation" id="rbCancallation2">
            <label class="custom-control-label" for="rbCancallation2">{{ $t('detail.modal.bodyCancelModeImmediate') }}</label>
          </div>
        </div>
        <div class="alert modal-alert-secondary">
          <div v-html="$t('detail.modal.bodyCancelWarning')"/>
        </div>
      </div>
    </new-confirm-modal>
  </div>
</template>

<script>
// @todo: This screen and VPS screen share a lot of function/code. Consider to write children component
import async from 'async'

import confirmModal from '@/components/children/ConfirmModal'
import confirm2Fa from "@/components/children/Confirm2Fa";

import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'

import RDPGenerator from '@/mixins/utilities/RDPGenerator'
import {FixBugTheme} from "@/mixins/MaterialTheme";

import levModal from '@/components/children/lev_components/LevModal'
import NewConfirmModal from "@/components/children/NewConfirmModal.vue";

export default {
  name: 'VPSDetail',
  components: {
    NewConfirmModal,
    confirmModal,
    confirm2Fa,
    levModal
  },
  mixins: [dataRequester, inputHelper, confirmModalBuilder, RDPGenerator],
  props: {
    metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    },
    languageSelected: String
  },
  data: function () {
    return {
      otpConfirmAction: 'none',
      action: 'none',
      VMObject: {},
      VMAddonObject: {
        activeAddonObjectList: []
      },
      rgcObject: {
        serverList: null,
        vmObject: null
      },
      VMOperStatus: '<span id="btn-status" class="badge badge-pill bg-gradient-primary"><span>' + this.$t('detail.table.tdDataChecking') + '</span></span>',
      OSTypeIcon: '',
      imageValues: {
        imageListCache: [],
        selectedImageId: 0,
        snapshotListCache: [],
        selectedSnapshotId: 0,
        currentImageSelection: {},
        currentSnapshotSelection: {}
      },
      teamMembers: [],
      cpuOptions: [
        {
          key: 'normal',
          value: this.$t('detail.label.cpuOptimizationNormal')
        },
        {
          key: 'virtualization',
          value: this.$t('detail.label.cpuOptimizationForEmulator')
        }
      ],
      cpuOptimization: null
    }
  },
  computed: {
    isTeamMember: function () {
      return this.VMObject['co_owner_id'] === this.currentUser['userID']
    },
    VMPaymentStatus () {
      switch(this.VMObject['payment_status']) {
        case 'ok':
          if (this.VMObject['end_date'] <= this.getCurrentTime()) {
            return '<span class="badge badge-warning"><span>' + this.$t('dedicatedDetail.table.tdDataOverdue') + '</span></span>'
          } else {
            return '<span class="badge badge-success"><span>' + this.$t('dedicatedDetail.table.tdDataOK') + '</span></span>'
          }
        case 'cancel':
          return '<span class="badge badge-dark"><span>' + this.$t('dedicatedDetail.table.tdDataCancel') + '</span></span>'
        case 'suspend':
          return '<span class="badge badge-danger"><span>' + this.$t('dedicatedDetail.table.tdDataSuspend') + '</span></span>'
        default:
          return '<span class="badge badge-info"><span>' + this.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
      }
    }
  },
  updated() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this

    this.submitObjectList(this.$config.apiPath.vpsDetail, {id: this.$route.params.VMID}, 'vm', undefined, function (result) {
      self.VMObject = result.data.VM
      self.cpuOptimization = self.VMObject['cpu_optimization']

      self.submitObjectList(self.$config.apiPath.vpsAddon, {
        serviceTypeID: result.data.VM.service_type_id,
        serviceID: result.data.VM.id
      }, 'addon', undefined, function (result) {
        self.VMAddonObject = result.data
      })

      // Display OS icon
      if (self.VMObject['os_type'] === 'Windows') {
        self.OSTypeIcon = 'text-info fa fa-windows'
      } else if (self.VMObject['os_type'] === 'Linux') {
        self.OSTypeIcon = 'text-warning fa fa-linux'
      }
    })

    this.submitObjectList(this.$config.apiPath.vpsStatus, {id: this.$route.params.VMID}, 'vm', undefined, function (result) {
      if (result.data.VMStatus === 'Running') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataRunning') + '</span></span>'
      } else if (result.data.VMStatus === 'Stopped') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataStopped') + '</span></span>'
      } else if (result.data.VMStatus === 'Suspended') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-warning"><span>' + self.$t('detail.table.tdDataSuspend') + '</span></span>'
      } else {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-info"><span>' + result.data.VMStatus + '</span></span>'
      }
    })

    this.getDataFromAPI(this.$config.apiPath.listMemberOfTeam, function (result) {
      self.teamMembers = result.data['users'].filter(x => x['id'] !== self.currentUser['userID'])
    }, null, false)
  },
  methods: {
    FixBugTheme,
    finishAction: function () {
      this.action = 'none'
    },
    confirmVM: function (actionToConfirm) {
      let self = this
      if (this.action === 'none') {
        this.action = actionToConfirm
        this.initConfirmVMAction([this.VMObject], actionToConfirm, function () {
          if (self.action === 'stop' || self.action === 'restart') { // means stop or restart
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('detail.modal.bodyDatalossWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          } else if (self.action === 'renew') {
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('detail.modal.bodyRenewWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          }
        })
        self.showModal(true)
      } else {
        self.$toasted.error(this.$t('detail.toast.errPendingTask'))
      }
    },
    onConfirmButton: function (otp_code) {
      let self = this
      if (this.action === 'start') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataStarting') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsStart, {id: this.VMObject['id']}, 'vm', undefined, function (result) {
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataRunning') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.action = 'none'
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('detail.table.tdDataUnknown') + '</span></span>'
        })
      } else if (this.action === 'shutdown') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataShuttingDown') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsShutdown, {id: this.VMObject['id']}, 'vm', undefined, function (result) {
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataStopped') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('detail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'stop') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataStopping') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsStop, {id: this.VMObject['id']}, 'vm', undefined, function (result) {
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataStopped') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('detail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'restart') {
        self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataRestarting') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsRestart, {id: this.VMObject['id']}, 'vm', undefined, function (result) {
          self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataRunning') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('detail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'editRemark') {
        let self = this
        let note = $('#editRemark').val()
        this.submitObjectList(this.$config.apiPath.vpsNote, {id: self.VMObject['id']}, 'vm', {note: note}, function (result) {
          self.$toasted.success(result.msg)
          self.action = 'none'
          self.VMObject['user_remark'] = note
        }, function () {
        })
      } else if (this.action === 'confirmSnapshot') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataTakingSnap') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsSnapshot, {
          id: this.VMObject['id'],
          note: $('#snapshotNote').val()
            }, 'vm', undefined,
            function (snapshotResult) {
              self.$toasted.success(snapshotResult.msg)
              self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataSuccess') + '</span></span>'
              self.action = 'none'
            },
            function (snapshotResult) {
              self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataRunning') + '</span></span>'
              self.action = 'none'
            })
      } else if (this.action === 'confirmTransfer') {
        this.submitObjectList(this.$config.apiPath.vpsTransfer, {id: self.VMObject['id']}, 'vm', {
          targetEmail: $('#transferEmailTarget').val(),
          otp_code: otp_code
            }, function (transferResult) {
              if (transferResult.data.is_enable_2fa) {
                $('#otp_modal').modal('show')
                self.otpConfirmAction = 'confirmTransfer'
              } else {
                self.$toasted.success(transferResult.msg)
                self.$router.push('/home/cloud-vps')
              }
            },
            null, true, function () {
              self.action = 'none'
              $('#otp_modal').modal('hide')
            })
      } else if (this.action === 'teamMemberShare') {
        let memberId = $('#selectTeamMembers').children('option:selected').val()
        this.submitObjectList(this.$config.apiPath.shareTeam,
            {
              id: self.VMObject['id']
            },
            'vm',
            {
              member_id: memberId
            }, function (transferResult) {
              window.location.reload()
              self.$toasted.success(transferResult.msg)
            }, null, true, function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmRenew') {
        let periodRenew = $('#periodRenew').val()
        this.submitObjectList(this.$config.apiPath.vpsRenew,
            {id: this.VMObject.id}, 'vm',
            {period: periodRenew}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          self.action = 'none'
          window.open('/#/home/order/detail/' + renewResult.data.id, '_blank')
        })
      } else if (this.action === 'confirmChangeVps') {
        this.submitObjectList(this.$config.apiPath.vpsChange,
            {id: this.VMObject.id}, 'vm',
            undefined, function (result) {
              self.$toasted.success(result.msg)
              self.action = 'none'
              window.open('/#/home/order/detail/' + result.data.id, '_blank')
            })
      } else if (this.action === 'confirmAutoRenew') {
        this.submitObjectList(this.$config.apiPath.vpsAutoRenew, {id: this.VMObject.id}, 'vm', {autoRenew: (self.VMObject['auto_renew'] * -1) + 1}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          self.action = 'none'
          self.VMObject['auto_renew'] = (self.VMObject['auto_renew'] * -1) + 1
        })
      } else if (this.action === 'confirmCancel') {
        let cancelMode = $('#selectCancelMode').children('option:selected').val()
        this.submitObjectList(this.$config.apiPath.vpsCancel, {id: this.VMObject.id}, 'vm', { cancelMode: cancelMode }, function (cancelResult) {
          self.$toasted.success(cancelResult.msg)
          self.action = 'none'
          self.VMObject['payment_status'] = 'cancel'
          if (cancelMode === 'immediate') {
            self.$router.push('/home/cloud-vps')
          }
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'showAddonDetail') {
        self.action = 'none'
      } else if (this.action === 'confirmEnableBackup') {
        let backupPeriod = {backupPeriod: $('#selectBackupPeriod').children('option:selected').val()}
        if (backupPeriod.backupPeriod >= 7) {
          this.submitObjectList(this.$config.apiPath.backupEnable, {id: this.VMObject.id}, 'vm', backupPeriod, function (enableResult) {
            self.$toasted.success(enableResult.msg)
            self.VMObject['backup_id'] = -1
          })
        } else {
          window.open('https://www.messenger.com/t/LowendVietServer', '_blank')
        }
        self.action = 'none'
      } else if (this.action === 'confirmDisableBackup') {
        this.submitObjectList(this.$config.apiPath.backupDelete, {id: this.VMObject['backup_id']},
            'backup', undefined, function (deleteResult) {
              self.$toasted.success(deleteResult.msg)
              self.VMObject['backup_id'] = null
            })
        self.action = 'none'
      } else if (this.action === 'confirmCreateRGC') {
        let rgcServerId = $('#selectRGCServer').children('option:selected').val()
        let portRGC = $('#rgcPortCreating')[0].value
        self.$toasted.info(self.$t('detail.toast.rgcCreating', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['os_type'])]))
        this.submitObjectList(this.$config.apiPath.rgcCreate, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id'],
          RGCServerID: rgcServerId,
          remotePort: portRGC
        },function (rowDataObject, status) {
          self.VMObject['rgc_server_name'] = rowDataObject.data['rgc_server_name']
          self.VMObject['rgc_forwarded_port'] = rowDataObject.data['rgc_forwarded_port']
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'confirmDeleteRGC') {
        self.$toasted.info(self.$t('detail.toast.rgcDelete', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['os_type'])]))
        this.submitObjectList(this.$config.apiPath.rgcDelete, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id']
        },function (rowDataObject, status) {
          self.VMObject['rgc_server_name'] = null
          self.VMObject['rgc_forwarded_port'] = null
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      }
      self.finishModal()
    },
    onModalAdditionalButton: function () {
      let self = this
      if (this.action === 'teamMemberShare') {
        this.submitObjectList(this.$config.apiPath.cancelShare,
            {id: self.VMObject.id},
            'vm',
            null, function (transferResult) {
              self.$toasted.success(transferResult.msg)
              self.VMObject['co_owner_id'] = null
              self.VMObject['co_owner_full_name'] = null
              self.VMObject['co_owner_facebook_uid'] = null
            }, null, true, function () {
              self.action = 'none'
            })
      }
      self.finishModal()
    },
    editRemark: function () {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleEditRemark', [this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port'], this.VMObject['os_type'])]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyEditRemark')
      this.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="editRemark" aria-describedby="detail" value="' + this.VMObject['user_remark'] + '">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonEditRemark')
      this.action = 'editRemark'
      this.showModal(true)
    },
    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('detail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    },
    showAddonDetail: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleAddonDetail') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(JSON.parse(this.VMObject['addon_detail']), function (addon, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<strong>' + (key + 1) + ': </strong>'
        self.confirmModalDisplayObject.bodyMsg += addon['amount'] + ' x ' + addon['addon_name'] + ' ($' + addon['addon_price'] + self.$t('detail.table.tdDataEachMonth') + ') <br/>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.confirmButtonTitle = 'OK'
        self.action = 'showAddonDetail'
        self.showModal(true)
      })
    },
    consoleVM: function () {
      window.open('./#/vm/console/' + this.VMObject.id, '_blank')
    },
    generateRDPFile: function () {
      let self = this
      this.generateSingleRDP(self.VMObject, function (xml) {
        self.action = 'none'
      })
    },
    rebuildVM: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        let self = this

        // Get the image list
        if (self.imageValues.imageListCache.length === 0) {
          this.getDataFromAPI(this.$config.apiPath.imageList, function (images) {
            self.imageValues.imageListCache = images.data.map(x => {
              return {id: x['id'], imageName: x['name'], description: x['description']}
            })
            if (images.data.length > 0) {
              self.imageValues.currentImageSelection = self.imageValues.imageListCache[0]
            }
            $('#modalRebuild').modal('show')
          })
        } else {
          $('#modalRebuild').modal('show')
        }
      }
    },
    takeSnap: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmSnap') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmSnapshot')
        this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><strong>'
        this.confirmModalDisplayObject.bodyMsg += this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port'], this.VMObject['os_type']) + '</strong>'
        this.confirmModalDisplayObject.bodyMsg += '</div>'
        this.confirmModalDisplayObject.bodyMsg += `
            <div class="input-group input-group-outline my-3">
              <label class="form-label">${this.$t('vps.modal.labelTypeNote')}</label>
              <input id="snapshotNote" type="text" class="form-control">
            </div>
          `
        this.confirmModalDisplayObject.bodyMsg += '<div class="text-danger">' + this.$t('detail.modal.bodySnapshotWarning') + '</div>'
        this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonSnapshot')

        this.action = 'confirmSnapshot'
        this.showModal(true)
      }
    },
    restoreVM: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        let self = this

        // Get the image list
        if (self.imageValues.snapshotListCache.length === 0) {
          this.getDataFromAPI(this.$config.apiPath.snapList, function (snapshots) {
            self.imageValues.snapshotListCache = snapshots.data.map(x => {
              return {
                id: x['id'],
                snapshotName: x['snapshot_name'],
                imageName: x['image_name'],
                note: x['note']
              }
            })
            $('#restore-snapshot-modal').modal('show')
          })
        } else {
          $('#restore-snapshot-modal').modal('show')
        }
      }
    },
    changeOptimization: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        let self = this
        self.cpuOptimization = self.cpuOptions[0].key
        $('#change-optimization-modal').modal('show')
      }
    },
    revokeCancellation: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        let self = this
        $('#revoke-cancellation-modal').modal('show')
      }
    },
    transfer: function () {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmTransfer') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmTransfer')
      this.confirmModalDisplayObject.bodyMsg += '<div class="form-row mt-3 mb-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="col-form-label col-3">Email</label>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<input class="form-control col-9" id="transferEmailTarget">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      this.confirmModalDisplayObject.bodyMsg += '<strong>Transfer IP:  ' + this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + this.$t('detail.modal.bodyTransferWarning') + '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonTransfer')

      this.action = 'confirmTransfer'
      this.showModal(true)
    },
    teamMemberShare: function () {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmShare') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = '<div class="form-group"><label for="selectTeamMembers">' + self.$t('detail.modal.bodySelectTeamMember') + '</label><select class="form-select ps-3 mb-3" id="selectTeamMembers">'


        async.forEachOf(self.teamMembers, function (member, key, cb) {
          self.confirmModalDisplayObject.bodyMsg += '<option value="' + member.id + '">' + member.full_name + '</option>'
          cb()
        }, function () {
          self.confirmModalDisplayObject.bodyMsg += '</select></div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>Share IP:  ' + self.getIPPortFormat(self.VMObject['ip'], self.VMObject['port']) + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('detail.modal.bodyTeamMember') + '</div>'
          self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonShare')
          self.confirmModalDisplayObject.additionalButton = {
            isShow: true,
            title: self.$t('detail.modal.buttonDeleteShare'),
            class: 'btn btn-danger'
          }

          self.action = 'teamMemberShare'
          self.showModal(true)
        })

      }
    },
    change: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmChange') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmChange')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('detail.modal.bodyChangeWarning') + '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonChange')

      self.action = 'confirmChangeVps'
      self.showModal(true)
    },
    renew: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmRenew') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="form-label">' + `${this.$t('detail.modal.period')} (${this.$t('detail.modal.month')})` + '</label>'
      this.confirmModalDisplayObject.bodyMsg += '<input id="periodRenew" class="form-control" type="number" min="1" max="24" value="1" onKeyDown="return false">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += this.$t('detail.modal.bodyConfirmRenew')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('detail.modal.bodyRenewWarning') + '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonRenew')

      self.action = 'confirmRenew'
      self.showModal(true)
    },
    setAutoRenew: function () {
      let self = this
      if (this.VMObject['auto_renew'] === 0) {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmSetAutoRenew') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmSetAutoRenew')
      } else {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmCancelAutoRenew') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmCancelAutoRenew')
      }

      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      if (this.VMObject['auto_renew'] === 0) {
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonSetAutoRenew')
      } else {
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonCancelAutoRenew')
      }
      self.action = 'confirmAutoRenew'
      self.showModal(true)
    },
    cancel: function () {
      $('#cancel-modal').modal('show')
    },
    enableBackup: function () {
      let self = this

      // Build the modal
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmBackup') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="form-group"><label for="selectBackupPeriod">' +
          this.$t('detail.modal.bodySelectBackupPeriod') + '</label><select class="form-select ps-3 mb-3" id="selectBackupPeriod">'
      this.confirmModalDisplayObject.bodyMsg += '<option value="7"> ' + this.$t('detail.modal.selectBackupPeriod7') + '</option>'
      this.confirmModalDisplayObject.bodyMsg += '<option value="1"> ' + this.$t('detail.modal.selectBackupPeriod1') + '</option>'
      this.confirmModalDisplayObject.bodyMsg += '</select></div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="text-primary">' + self.$t('detail.modal.bodyBackupWarning',
          [this.VMObject['disk'], this.VMObject['disk'] * 0.03]) + '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonEnableBackup')
      self.action = 'confirmEnableBackup'
      self.showModal(true)
    },
    disableBackup: function () {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
        return
      }

      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmDisableBackup') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmDisableBackup')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port'], this.VMObject['os_type']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonDisableBackup')

      this.action = 'confirmDisableBackup'
      this.showModal(true)
    },
    createRGC: function (rowData) {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        let self = this
        self.rgcObject.vmObject = rowData
        let buildImageSelection = function () {

          self.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>IP. ' + self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['os_type']) + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<strong>Remote Port</strong>'
          self.confirmModalDisplayObject.bodyMsg += '<span class="smaller d-block">' + self.$t('vps.modal.bodyRGCCreateWarning') + '</span>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline mb-3">'
          self.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="rgcPortCreating" aria-describedby="detail" value="' + rowData['port'] + '">'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<div class="form-group"><label for="selectImageRebuild">' + self.$t('detail.modal.bodySelectRGC') + '</label><select class="form-select ps-3 mb-3" id="selectRGCServer">'

          async.forEachOf(self.rgcObject.serverList, function (item, key, cb) {
            self.confirmModalDisplayObject.bodyMsg += '<option value="' + item['id'] + '">' + item['rgc_server_name'] + ' (' + item['location'] + ')</option>'
            cb()
          }, function () {
            self.confirmModalDisplayObject.bodyMsg += '</select></div>'
            self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonCreate')

            self.action = 'confirmCreateRGC'
            self.showModal(true)
          })
        }
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmCreateRGC') + '</h4>'

        // Get the image list
        if (self.rgcObject.serverList === null) {
          this.getDataFromAPI(this.$config.apiPath.rgcServerAvailable, function (result) {
            self.rgcObject.serverList = result.data['RGCServerObjectList']
            buildImageSelection()
          })
        } else {
          buildImageSelection()
        }
      }
    },
    deleteRGC: function (rowData) {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
        return
      }
      self.rgcObject.vmObject = rowData
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmDeleteRGC') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmDeleteRGC')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['os_type']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonDelete')

      this.action = 'confirmDeleteRGC'
      this.showModal(true)
    },
    confirmOTP: function (otp_code) {
      this.action = this.otpConfirmAction
      this.onConfirmButton(otp_code)
      this.otpConfirmAction = 'none'
      $('#otp_modal').modal('hide')
    },
    imageSelectChange() {
      let imageId = document.getElementById("selectImageRebuild").selectedOptions[0].value
      this.imageValues.currentImageSelection = this.imageValues.imageListCache.find(x => x.id == imageId)
    },
    snapshotSelectChange() {
      let snapshotId = document.getElementById("selectSnapshotRestore").selectedOptions[0].value
      this.imageValues.currentSnapshotSelection = this.imageValues.snapshotListCache.find(x => x.id == snapshotId)
    },
    cpuOptimizationChange() {
      this.cpuOptimization = document.getElementById("selectCpuOptimization").selectedOptions[0].value
    },
    confirmRebuild() {
      let self = this

      self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataReinstalling') + '</span></span>'
      let reinstallImageID = {reinstallImageID: document.getElementById("selectImageRebuild").selectedOptions[0].value}
      this.submitObjectList(this.$config.apiPath.vpsRebuild, {id: self.VMObject['id']}, 'vm', reinstallImageID, function (rebuildResult) {
            self.$toasted.success(self.$t('detail.toast.okRebuildRequestSent'))
            self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataSuccess') + '</span></span>'
            self.action = 'none'
          },
          function (rebuildResult) {
            self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataError') + '</span></span>'
            self.$toasted.error(self.getIPPortFormat(self.VMObject['ip'], self.VMObject['port']) + ' : ' + rebuildResult.data[0]['detail'])
            self.action = 'none'
          })
    },
    confirmRestore() {
      let self = this
      self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataRestoring') + '</span></span>'
      let snapshotID = {snapshotID: document.getElementById("selectSnapshotRestore").selectedOptions[0].value}
      self.$toasted.success(self.$t('detail.toast.okRestoreRequestSent'))
      this.submitObjectList(this.$config.apiPath.vpsRestore, {id: self.VMObject['id']}, 'vm', snapshotID, function (restoreResult) {
            self.$toasted.success(restoreResult.msg)
            self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataSuccess') + '</span></span>'
            self.action = 'none'
          },
          function (restoreResult) {
            self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataError') + '</span></span>'
            self.$toasted.error(self.getIPPortFormat(self.VMObject['ip'], self.VMObject['port']) + ' : ' + restoreResult.data[0]['detail'])
            self.action = 'none'
          })
    },
    confirmChangeCpuOptimization() {
      let self = this
      if (self.cpuOptimization === self.VMObject['cpu_optimization']) {
        self.$toasted.error(self.$t('detail.toast.cpuOptimizationNoChange'))
      } else {
        this.submitObjectList(this.$config.apiPath.vpsChangeResourceLimit,
            {
              id: self.VMObject['id'],
              cpu_optimization: self.cpuOptimization
            },
            'vm',
            undefined,
            function (restoreResult) {
              self.$toasted.success(restoreResult.msg)
              self.VMObject['cpu_optimization'] = self.cpuOptimization
            }, undefined, true)
      }
    },
    confirmRevokeCancellation() {
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsRevokeCancellation, {id: self.VMObject['id']}, 'vm', undefined, function (revokeResult) {
        self.$toasted.success(revokeResult.msg)
        self.VMObject['payment_status'] = 'ok'
      })
    },
    confirmCancel: function () {
      $('#cancel-modal').modal('hide')
      let self = this
      let cancelMode = $('input[name=rbCancallation]:checked').val()
      this.submitObjectList(this.$config.apiPath.vpsCancel,
          {id: self.VMObject['id']}, 'vm',
          {cancelMode: cancelMode},
          function (revokeResult) {
        self.$toasted.success(revokeResult.msg)
        self.VMObject['payment_status'] = 'cancel'
      })
      if (cancelMode === 'immediate') {
        self.$router.push('/home/cloud-vps')
      }
    },
  }
}
</script>

<style scoped>

</style>
