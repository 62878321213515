<template>
  <div :class="`alert modal-alert-${color} text-left`">
    <ol>
      <li
          v-for="(value, index) in this.itemList"
          :key="index"
      >
        <span v-if="$scopedSlots['item-content']">
          <slot name="item-content" :item="value"/>
        </span>
        <span v-else>
          {{ value }}
        </span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "LevItemList",
  props: {
    itemList: {
      type: Array,
      default: function () {
        return []
      }
    },
    color: {
      type: String,
      default: "info"
    }
  }
}
</script>