<template>
  <div class="mt-1">
    <div class="card mb-3">
      <div class="card-body mt-1">
        <div class="form-group row align-content-center pb-0 mb-0">
          <div class="col-sm-2 offset-2 mt-2">
            <label>{{ $t('log.cardHeader.description') }}</label>
          </div>
          <div class="col-sm-1 mt-2 justify-content-end">
            <label>{{ $t('log.cardHeader.from') }}</label>
          </div>
          <div class="col-sm-1">
            <input
              id="dateFrom"
              v-model="filter.dateFrom.currentSelection"
              type="date"
              class="form-control"
            >
          </div>
          <div class="col-sm-1 mt-2">
            <label>{{ $t('log.cardHeader.to') }}</label>
          </div>
          <div class="col-sm-1">
            <input
              id="dateTo"
              v-model="filter.dateTo.currentSelection"
              type="date"
              class="form-control"
            >
          </div>
          <button  class="btn btn-success btn-sm col-sm-2"
            @click="filterLog"
          >
            <i class="fa fa-filter" /> {{ $t('log.cardHeader.buttonFilter') }}
          </button>
        </div>
        <hr>
        <div class="lev-table">
          <lev-table
            :table-data="dataObjectList"
            :fields="tableLogColumns"
            :searchalbe-fields="searchableFields"
            :extended-fields="extendtedFields"
            :visible-fields="visibleFields"
            :on-cell-click-processing="onCellClickProcessing"
            @selected-changed="onSelectedChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LevTable from "@/components/children/LevTable";

import dataObjectList from '@/mixins/DataObjectList'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'
import dataRequester from '../mixins/utilities/DataRequester'
import inputHelper from '../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';


export default {
  name: "LogList",
  components: {LevTable},
  mixins: [dataObjectList, dataRequester, confirmModalBuilder, inputHelper],
  data: function () {
    let self = this
    return {
      action: 'none',
      filter: {
        dateFrom: {
          currentSelection: null
        },
        dateTo: {
          currentSelection: null
        }
      },
      selectedObjectIndex: null,
      tableLogColumns: [
        {
          name: 'action',
          title: '<i class="fas fa-hand-point-up"></i> ' + this.$t('log.table.thAction'),
          width: "10%"
        },
        {
          name: 'status',
          title: '<i class="fas fa-info"></i> ' + this.$t('log.table.thStatus'),
          width: "10%"
        },
        // {
        //   name: 'content',
        //   title: '<i class="fas fa-indent"></i> ' + this.$t('log.table.thDetail'),
        //   width: "50%"
        // },
        {
          name: 'ip',
          title: '<i class="fas fa-ethernet"></i> ' + this.$t('log.table.thAccessIP'),
          width: "15%"
        },
        {
          name: 'created_at',
          sortField: 'created_at',
          title: '<i class="fas fa-clock"></i> ' + this.$t('log.table.thUpdated'),
          width: "10%"
        }
      ],
      extendtedFields: [
        {
          name: 'content',
          title: '<i class="fas fa-indent"></i> ' + this.$t('log.table.thDetail'),
          width: "50%",
          order: 3,
          formatter: function (rowData) {
            if (rowData['action'] === 'Change') {
              return `<div>
                        <div>${rowData['content']}</div>
                        <div>${self.displayParameters(rowData['parameters'])}</div>
                      </div>`
            } else {
              return rowData['content']
            }
          }
        }
      ],
      visibleFields: [],
      searchableFields:['action', 'status', 'content', 'ip', 'created_at', 'parameters']
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    let cookieStorage = this.$cookies.get('storage')
    let logFilter = {}
    if (cookieStorage != null && cookieStorage['logFilter'] !== undefined) {
      logFilter = cookieStorage['logFilter']
      this.filter.dateFrom.currentSelection = logFilter.dateFrom
      this.filter.dateTo.currentSelection = logFilter.dateTo
    } else {
      this.filter.dateFrom.currentSelection = this.getDateFromCurrent()
      this.filter.dateTo.currentSelection = this.getDateFromCurrent(1)
    }
    self.submitObjectList(self.$config.apiPath.logList, undefined, 'log',
        {
          filter: {
            dateFrom: self.convertISODate(self.filter.dateFrom.currentSelection),
            dateTo: self.convertISODate(self.filter.dateTo.currentSelection)
          }
        }, function (result) {
          self.dataObjectList = result.data['logObjectList']
          self.dataObjectList.forEach(function (v, k) {
            self.dataObjectList[k]['created_at'] = self.convertISODate(v['created_at'])
          })
        })
  },
  methods: {
    FixBugTheme,
    filterLog: function () {
      let cookieStorage = this.$cookies.get('storage')
      if (cookieStorage === null) {
        cookieStorage = {}
      }

      cookieStorage['logFilter'] = {
        dateFrom: this.filter.dateFrom.currentSelection,
        dateTo: this.filter.dateTo.currentSelection
      }
      this.$cookies.set('storage', JSON.stringify(cookieStorage))
      // Force re-render
      setTimeout(() => {
        window.location.reload()
      }, 800)
    },
    onSelectedChanged(selectedRowID) {
      // console.log(selectedRowID)
      // console.log(this.dataObjectList.filter(d => selectedRowID.includes(d['id'])))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      // if (cellField.name === 'status') {
      //   if (rowData['status'] === 'success') {
      //     rowData['status'] = 'Totally success'
      //   }
      //   updatePostTable()
      // }
    },
    displayParameters(parameters) {
      let result = ''
      let params = JSON.parse(parameters)
      if (Array.isArray(params)) {
        params.forEach(function (item) {
          if (item['dataChanged']) {
            let dataChangedList = item['dataChanged']
            dataChangedList.forEach(function (dataChanged) {
              result += `<div><strong>${dataChanged['field']}</strong> updated. From "<strong>${dataChanged['old']}</strong>" to "<strong>${dataChanged['new']}</strong>"</div>`
            })
          }
        })
      }
      return result
    }
  }
}
</script>
