<template>
  <div class="mt-1">
    <div class="card mb-3">
      <div
        class="card-header pb-1"
      >
        <div
          class="btn-group d-flex justify-content-between"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <div />
          <div>
            <lev-button @click="addNewTicketClick">
              <i class="fas fa-plus me-3" />
              <span>{{ $t('ticket.button.addNew') }}</span>
            </lev-button>
          </div>
        </div>
        <hr class="pt-0 pb-0">
      </div>
      <div class="card-body pt-0">
        <div class="lev-table">
          <lev-table
            :table-data="dataObjectList"
            :fields="tableColumns"
            :searchalbe-fields="searchableFields"
            :extended-fields="extendedFields"
            :visible-fields="visibleFields"
            :on-cell-click-processing="onCellClickProcessing"
            @selected-changed="onSelectedChanged"
          />
        </div>
      </div>
    </div>

    <!-- Modal Add New -->
    <lev-modal
      id="modal-add-new"
      :title="$t('ticket.modal.title.addNewTicket')"
      :confirm-button="$t('ticket.modal.button.confirm')"
      @modalConfirm="confirmAddNewTicket"
    >
      <lev-input
        v-model="newTicketObj['title']"
        :label="$t('ticket.modal.label.title')"
      />
      <lev-editor v-model="newTicketObj['content']" />
    </lev-modal>
  </div>
</template>

<script>

import LevTable from "@/components/children/LevTable";
import dataObjectListMixin from "@/mixins/DataObjectList";
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import {FixBugTheme} from "@/mixins/MaterialTheme";

import LevButton from "@/components/children/lev_components/LevButton";
import LevEditor from "@/components/children/lev_components/LevEditor";
import LevModal from "@/components/children/lev_components/LevModal";
import LevInput from "@/components/children/lev_components/LevInput";

export default {
  name: "Ticket",
  components: {
    LevTable,
    LevButton,
    LevEditor,
    LevModal,
    LevInput
  },
  mixins: [dataObjectListMixin, dataRequester, inputHelper],
  data () {
    let self = this
    return {
      statusList: [],
      tableColumns: [
        {
          name: 'id',
          sortField: 'id',
          formatter: function (value) {
            return `<a href="#/home/ticket/detail/${value}"><strong>LEV${value}</strong></a>`
          }
        },
        {
          name: 'title',
          title: this.$t('ticket.table.header.title'),
          sortField: 'title'
        },
        {
          name: 'conversation_count',
          title: this.$t('ticket.table.header.conversationCount'),
          sortField: 'conversation_count'
        },
        {
          name: 'status',
          // title: this.$t('ticket.table.header.status'),
          // sortField: 'status',
          title:
          `<div class="dropdown">
            <button class="btn bg-gradient-primary dropdown-toggle m-0" type="button" id="dropdownStatusMenu" data-bs-toggle="dropdown" aria-expanded="false">
              ${this.$t('ticket.table.header.status')}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownStatusMenu">
              <li><a class="dropdown-item" href="#/home/ticket?status=all">${this.$t('ticket.label.showAll')}</a></li>
            </ul>
          </div>`,
          formatter: function (value) {
            switch (value) {
              case 'Open':
              case 'Reopen':
                return `<span class="badge badge-success">${value}</span>`
              case 'Answered':
                return `<span class="badge badge-info">Admin Reply</span>`
              case 'Customer Reply':
                return `<span class="badge badge-info">Answered</span>`
              case 'On Hold':
                return `<span class="badge badge-warning">${value}</span>`
              case 'Closed':
                return `<span class="badge badge-secondary">${value}</span>`
              default:
                return '<span class="badge badge-secondary">${value}</span>'
            }
          }
        },
        {
          name: 'created_at',
          title: this.$t('ticket.table.header.createdAt'),
          sortField: 'created_at',
          formatter: function (value) {
            return self.convertISOTime(value)
          }
        },
        {
          name: 'updated_at',
          title: this.$t('ticket.table.header.lastMessage'),
          sortField: 'updated_at',
          formatter: function (value) {
            return self.convertISOTime(value)
          }
        }
      ],
      searchableFields: ['id','title','conversation_count','status','created_at','updated_at'],
      extendedFields: [
        {
          name: 'action',
          title: this.$t('ticket.table.header.action'),
          sortField: 'action',
          formatter: function (item) {
            return `<a href="#/home/ticket/detail/${item['id']}" class="badge bg-gradient-primary px-3"><i class="fas fa-eye pr-3"></i> ${self.$t('ticket.table.button.view')}</a>`
          }
        }
      ],
      visibleFields: [],

      newTicketObj: {
        title: '',
        content: ''
      }
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  created() {
    let self = this
    this.getDataFromAPI(this.$config.apiPath.ticketList, function (result) {
      if (self.$route.query['status'] === 'all') {
        self.dataObjectList = result.data['ticketObjectList']
      } else {
        self.dataObjectList = result.data['ticketObjectList'].filter(x => x['status'] !== 'Closed')
        self.statusList = [...new Set(self.dataObjectList.map(x => x['status']))]
      }
    })
  },
  methods: {
    FixBugTheme,
    onSelectedChanged(selectedRowID) {
      this.selectedObjectList = this.dataObjectList.filter(d => selectedRowID.includes(d['id']))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      let self = this
      switch (cellField.name) {
        default:
          break
      }
      updatePostTable()
    },

    addNewTicketClick() {
      $('#modal-add-new').modal('show')
    },
    confirmAddNewTicket () {
      let self = this
      this.submitObjectList(this.$config.apiPath.ticketAddNew, {
        title: self.newTicketObj['title'],
        content: self.newTicketObj['content']
      }, 'ticketObj', undefined, function (result) {
        self.dataObjectList.push(result.data['ticketObj'])
        self.$toasted.success(result.data['msg'])
        $('#modal-add-new').modal('hide')
      }, null)
    }
  }
}
</script>

<style scoped>

</style>
