<template>
  <div>
    <div class="card mb-4 ">
      <div class="d-flex">
        <div class="icon icon-shape icon-lg bg-gradient-info shadow text-center border-radius-xl mt-n3 ms-4">
          <i class="fas fa-user-shield"></i>
        </div>
        <h6 class="mt-3 mb-2 ms-3 ">{{ $t('two_fa.label.two_fa_title') }}</h6>
      </div>
      <div class="card-body p-3">
        <div class="text-center">
          <div v-if="!isEnable2Fa">
            <div class="max-width-500 m-auto">
              <h4>{{ $t('two_fa.label.setup_2fa') }}</h4>
              <label v-html="$t('two_fa.message.setup_2fa')"></label>
            </div>
            <br/>
            <img :src="qrCode2Fa" alt="qr-code"/>
            <div class="">
              <div class="">
                <strong>Account: </strong>
                <span>{{ accountService }}</span>
              </div>
              <div class="">
                <strong>Key: </strong>
                <span>{{ secretKeyOtpToken }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <h4>{{ $t('two_fa.label.two_fa_enable') }}</h4>
            <label>{{ $t('two_fa.message.your_account_safe') }}</label>
            <br/>
            <span class="material-icons text-success icon-verify">&#xe8e8;</span>
          </div>
          <div class="input-group input-group-outline mt-3 max-width-300 m-auto">
            <label class="form-label">{{ $t('two_fa.label.otp_code') }}</label>
            <input type="text" maxlength="6" class="form-control" v-model="digitToken">
          </div>
          <div class="input-group input-group-outline mt-3 max-width-300 m-auto">
            <label class="form-label">{{ $t('two_fa.label.password') }}</label>
            <input type="password" class="form-control" v-model="password">
          </div>
          <button v-if="!isEnable2Fa" class="btn btn-icon btn-3 mt-4 btn-success" type="button" @click="enable2FaToken">
            <span class="btn-inner--icon me-3"><i class="fas fa-user-shield"></i></span>
            <span class="btn-inner--text">{{ $t('two_fa.button.enable') }}</span>
          </button>
          <button v-else class="btn btn-icon btn-3 mt-4 btn-danger" type="button" @click="disable2FaToken">
            <span class="btn-inner--icon me-3"><i class="fas fa-user-shield"></i></span>
            <span class="btn-inner--text">{{ $t('two_fa.button.disable') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import md5 from 'md5'
import qrcode from 'qrcode'
import { FixBugTheme } from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'

export default {
  name: "two_fa",
  data: function () {
    return {
      isEnable2Fa: false,
      qrCode2Fa: null,
      secretKeyOtpToken: null,
      accountService: null,
      digitToken: '',
      password: ''
    }
  },
  mixins: [dataRequester],
  created() {
    let self = this
    this.getDataFromAPI(this.$config.apiPath.check2FaEnable, function (result) {
      self.isEnable2Fa = result.data
      if (!self.isEnable2Fa) {
        self.getDataFromAPI(self.$config.apiPath.generate2FaToken, function (result) {
          self.secretKeyOtpToken = result.data['secretKey']
          self.accountService = result.data['siteName']
          self.generateQRCode(result.data['otpToken']).then(qrCode => {
            self.qrCode2Fa = qrCode
          })
        })
      }
    }, function (error) {
    })
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    generateQRCode: async function (otpAuth) {
      try {
        const QRCodeImageUrl = await qrcode.toDataURL(otpAuth)
        return QRCodeImageUrl
      } catch (error) {
        return null
      }
    },
    enable2FaToken: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.enable2FaToken, {
        password: md5(self.password),
        token: self.digitToken,
        secret_key: self.secretKeyOtpToken
      }, 'otp2fa', null, function (result) {
        if (result.status = 'success') {
          self.$toasted.success(result.msg)
          self.isEnable2Fa = true
        }
      })
    },
    disable2FaToken: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.disable2FaToken, {
        password: md5(self.password),
        token: self.digitToken
      }, 'otp2fa', null, function (result) {
        if (result.status = 'success') {
          self.$toasted.success(result.msg)
          window.location.reload()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>