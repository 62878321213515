<template>
  <div>
    <div class="mt-1">
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <lev-button
              data-bs-toggle="collapse"
              data-bs-target="#collapse-editor"
              aria-expanded="false"
              aria-controls="collapse-editor"
              :disabled="!(ticketDetail['status'] !== 'Closed' && ![null, undefined].includes(ticketDetail['id']))"
            >
              <i class="me-3 fas fa-pencil-alt" />
              <span class="btn-inner--text">{{ $t('ticket.button.ticketReply') }}</span>
            </lev-button>
            <lev-button
              v-if="ticketDetail['status'] !== 'Closed' && ![null, undefined].includes(ticketDetail['id'])"
              variant="secondary"
              @click="closeTicket"
            >
              <i class="fas fa-window-close me-3" />
              <span class="btn-inner--text">{{ $t('ticket.button.closeTicket') }}</span>
            </lev-button>
            <lev-button
              v-else
              variant="success"
              @click="reopenTicket"
            >
              <i class="fas fa-lock-open me-3" />
              <span class="btn-inner--text">{{ $t('ticket.button.reopenTicket') }}</span>
            </lev-button>
          </div>
          <div
            id="collapse-editor"
            class="collapse"
          >
            <lev-editor v-model="editorContent" />
            <div class="d-flex justify-content-end">
              <lev-button
                class="mt-3"
                :loading="buttonSendLoading"
                @click="sendConversation"
              >
                {{ $t('ticket.button.send') }}
              </lev-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-for="(conversation, index) in ticketDetail['conversationList']"
      :key="index"
      class="mt-1"
    >
      <div class="card mb-3">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <i
                v-if="['staff', 'admin'].includes(conversation['user_type'])"
                class="fas fa-user-tie fa-2x"
              />
              <i
                v-else
                class="fas fa-user fa-2x"
              />
              <div class="ms-2">
                <div class="d-flex align-items-center">
                  <span
                    v-if="['staff', 'admin'].includes(conversation['user_type'])"
                    class="text-primary"
                  ><strong>{{ conversation['full_name'] }}</strong></span>
                  <span v-else>{{ conversation['full_name'] }}</span>
                  <lev-badge
                    v-if="['staff', 'admin'].includes(conversation['user_type'])"
                    class="ms-1"
                    variant="success"
                    :text="conversation['user_type']"
                  />
                  <!--                  <lev-badge v-else class="ms-1" variant="info" :text="conversation['user_type']"/>-->
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span class="text-sm">{{ convertISOTime(conversation['created_at']) }}</span>
            </div>
          </div>
          <hr>
        </div>
        <div class="card-body overflow-auto">
          <div v-html="conversation['content']" />
        </div>
      </div>
    </div>

    <!-- Modal Closed -->
    <lev-modal
      id="modal-closed"
      :title="$t('ticket.modal.title.closeTicket')"
      :confirm-button="$t('ticket.modal.button.confirm')"
      @modalConfirm="confirmCloseTicket"
    >
      <div class="d-flex justify-content-center">
        <div class="d-flex align-items-center">
          <div v-html="$t('ticket.modal.content.closeTicket')" />
        </div>
      </div>
    </lev-modal>
    <!-- Modal Reopen -->
    <lev-modal
      id="modal-reopen"
      :title="$t('ticket.modal.title.reopenTicket')"
      :confirm-button="$t('ticket.modal.button.confirm')"
      @modalConfirm="confirmReopenTicket"
    >
      <div class="d-flex justify-content-center">
        <div class="d-flex align-items-center">
          <div v-html="$t('ticket.modal.content.reopenTicket')" />
        </div>
      </div>
    </lev-modal>
  </div>
</template>

<script>

import dataObjectListMixin from "@/mixins/DataObjectList";
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import {FixBugTheme} from "@/mixins/MaterialTheme"

import LevButton from "@/components/children/lev_components/LevButton.vue";
import LevEditor from "@/components/children/lev_components/LevEditor.vue";
import LevModal from "@/components/children/lev_components/LevModal.vue";
import LevBadge from "@/components/children/lev_components/LevBadge.vue";

export default {
  name: "TicketDetail",
  components: {
    LevModal,
    LevButton,
    LevEditor,
    LevBadge
  },
  mixins: [dataObjectListMixin, dataRequester, inputHelper],
  data() {
    return {
      ticketDetail: {
        conversationList: []
      },

      editorContent: '',
      buttonSendLoading: false
    }
  },
  mounted() {
    FixBugTheme()
  },
  updated() {
    FixBugTheme()
  },
  created() {
    let self = this
    let ticketId = this.$route.params['ticketID']
    this.submitObjectList(this.$config.apiPath.ticketDetail,
        {id: ticketId},
        'ticketObj', undefined,(result) => {
          self.ticketDetail = result.data
          document.getElementById('breadcrumb').textContent = result.data.title
          document.title = result.data.title + ' - Cloud Service Manager';
    }, function () {
      self.$router.push({name: 'ticket'})
    })
  },
  methods: {
    sendConversation () {
      let self = this
      self.buttonSendLoading = true
      this.submitObjectList(this.$config.apiPath.ticketReply, {
        ticket_id: self.ticketDetail['id'],
        content: self.editorContent
      }, 'conversationObj', undefined, (result) => {
        self.ticketDetail.conversationList.unshift(result.data['conversationObj'])
        self.editorContent = ''
        self.$toasted.success(result.msg)
      }, undefined, true, () => {
        self.buttonSendLoading = false
      })
    },
    closeTicket () {
      $('#modal-closed').modal('show')
    },
    reopenTicket () {
      $('#modal-reopen').modal('show')
    },
    confirmCloseTicket () {
      this.submitObjectList(this.$config.apiPath.ticketClose, {
        id: this.ticketDetail['id']
      }, 'ticketObj', undefined, (result) => {
        $('#collapse-editor').collapse('hide')
        this.ticketDetail.status = result.data['ticketObj'].status
        this.$toasted.success(result.msg)
      })
    },
    confirmReopenTicket () {
      this.submitObjectList(this.$config.apiPath.ticketReopen, {
        id: this.ticketDetail['id']
      }, 'ticketObj', undefined, (result) => {
        $('#collapse-editor').collapse('show')
        this.ticketDetail.status = result.data['ticketObj'].status
        this.$toasted.success(result.msg)
      })
    }
  }
}
</script>
