<template>
  <span :class="`badge badge-${variant}`">{{ text }}</span>
</template>

<script>
export default {
  name: "LevBadge",
  props: {
    text: {
      type: String,
      default: "Badge",
    },
    variant: {
      type: String,
      default: "primary",
    },
  }
}
</script>
