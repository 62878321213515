<template>
  <main class="main-content  mt-0">
    <div class="page-header align-items-start min-vh-100" style="background-image: url('https://images.unsplash.com/uploads/1413399939678471ea070/2c0343f7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container my-auto">
        <div class="row">
          <div class="col-lg-12 m-auto text-center">
            <h1 class="display-1 text-bolder text-white">Error 404</h1>
            <h2 class="text-white">Erm. Page not found</h2>
            <p class="lead text-white">We suggest you to go to the homepage while we solve this issue.</p>
            <button type="button" class="btn btn-outline-white mt-4"
                    v-on:click="gotoHome">Go to Homepage</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "404",
  methods: {
    gotoHome: function () {
      this.$router.push({name: 'dashboard'})
    }
  }
}
</script>

<style scoped>

</style>