<template>
  <main class="main-content  mt-0">
    <div
      class="page-header align-items-start min-vh-100"
      style="background-image: url('../../assets/img/bg/register.jpeg');"
    >
      <span class="mask bg-gradient-dark opacity-6" />
      <div class="container my-auto">
        <div class="row">
          <div class="col-lg-4 col-md-8 mx-auto">
            <div class="card z-index-0">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div class="bg-gradient-light shadow-success border-radius-lg py-3 pe-1">
                  <img
                    :src="metaData.logoPath"
                    class="img-logo-login"
                    :alt="metaData.siteName + ' logo'"
                  >
                </div>
              </div>
              <div class="card-body">
                <form role="form">
                  <div class="input-group input-group-dynamic mb-3">
                    <label class="form-label">{{ $t('register.label.email') }}</label>
                    <input
                      v-model="email"
                      type="email"
                      class="form-control"
                      :aria-label="$t('register.label.email')"
                    >
                  </div>
                  <div class="row">
                    <div class="col input-group input-group-dynamic mb-3">
                      <label class="form-label">{{ $t('register.label.password') }}</label>
                      <input
                        v-model="password"
                        type="password"
                        class="form-control"
                        :aria-label="$t('register.label.password')"
                      >
                    </div>
                    <div class="col input-group input-group-dynamic mb-3">
                      <label class="form-label">{{ $t('register.label.passwordConfirm') }}</label>
                      <input
                        v-model="confirmPassword"
                        type="password"
                        class="form-control"
                        :aria-label="$t('register.label.passwordConfirm')"
                      >
                    </div>
                  </div>
                  <div class="input-group input-group-dynamic mb-3">
                    <label class="form-label">{{ $t('register.label.phoneNo') }}</label>
                    <input
                      v-model="phoneNo"
                      type="text"
                      class="form-control"
                      :aria-label="$t('register.label.phoneNo')"
                    >
                  </div>
                  <div class="input-group input-group-dynamic mb-3">
                    <label class="form-label">{{ $t('register.label.fullName') }}</label>
                    <input
                      v-model="fullName"
                      type="text"
                      class="form-control"
                      :aria-label="$t('register.label.fullName')"
                    >
                  </div>
                  <div class="input-group input-group-dynamic mb-3">
                    <label class="form-label">{{ $t('register.label.facebook') }}</label>
                    <input
                      v-model="facebook"
                      type="text"
                      class="form-control"
                      :aria-label="$t('register.label.facebook')"
                    >
                  </div>
                  <div class="form-check text-start ps-0">
                    <input
                      id="flexCheckDefault"
                      class="form-check-input bg-dark border-dark"
                      type="checkbox"
                      value=""
                      checked
                    >
                    <label
                      class="form-check-label"
                      for="flexCheckDefault"
                    >
                      I agree the <a
                        href="javascript:;"
                        class="text-dark font-weight-bolder"
                      >Terms and Conditions</a>
                    </label>
                  </div>
                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn bg-gradient-success w-100 my-4 mb-2"
                      @click.prevent="register"
                    >
                      {{ $t('register.button.register') }}
                    </button>
                    <button
                      type="button"
                      class="btn bg-gradient-primary w-100 my-1 mb-2"
                      @click.prevent="login"
                    >
                      <span class="fa fa-arrow-left" /> {{ $t('register.button.login') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer position-absolute bottom-2 py-2 w-100">
        <div class="container">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-12 col-md-6 my-auto">
              <div class="copyright text-center text-sm text-white text-lg-start">
                <div v-html="metaData.footNote" />
              </div>
            </div>
            <div class="col-lg-6 justify-content-end">
              <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                <li class="nav-item">
                  <a
                      href="https://fb.com/LowendVietServer"
                      class="nav-link pe-0 text-white"
                      target="_blank"
                  ><i class="fab fa-facebook-square"></i> Facebook</a>
                </li>
                <li class="nav-item">
                  <div class="dropup">
                    <a
                        id="navbarDropdownMenuLink2"
                        href="javascript:;"
                        class="dropdown-toggle nav-link pe-0 text-white"
                        data-bs-toggle="dropdown"
                    >
                      <i class="fa fa-fw fa-globe" /> Language&nbsp;
                      <flag
                          :iso="this.$i18n.locale.slice(-2)"
                          :squared="false"
                      />
                    </a>
                    <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink2"
                    >
                      <li>
                        <div
                            class="dropdown-item"
                            aria-labelledby="language"
                        >
                            <span
                                v-for="(lang, i) in metaData.availableLang"
                                :key="i"
                            >
                              <span
                                  class="dropdown-item"
                                  @click="changeLocale(lang['locale'])"
                              >
                                <span class="">
                                  <flag
                                      :iso="lang['locale'].slice(-2)"
                                      :squared="false"
                                  />
                                </span> {{ lang['description'] }}
                              </span>
                            </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </main>
</template>

<script>
/* eslint-disable vue/no-v-html */
import dataRequester from '../../mixins/utilities/DataRequester'
import inputHelper from '../../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "Register",
  mixins: [dataRequester, inputHelper],
  data: function () {
    return {
      fullName: '',
      username: '',
      password: '',
      confirmPassword: '',
      phoneNo: '',
      email: '',
      facebook: '',
      metaData: {
        logoPath: '',
        siteName: '',
        footNote: '',
        shortFootNote: '',
        availableLang: ''
      }
    }
  },
  mounted: function () {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    this.setReferralToLocalStorage()

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }

    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.shortFootNote = metaData.domainObjectList['shortfootnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
    }, function () {
      self.$toasted.error(self.$t('domain.toast.errMetaData'))
    })
  },
  methods: {
    FixBugTheme,
    register: function () {
      let self = this
      if (this.password !== this.confirmPassword) {
        this.$toasted.error(this.$t('register.toast.differentPassword'))
      } else if (!this.validateEmail(this.email)) {
        this.$toasted.error(this.$t('register.toast.invalidEmail'))
      } else {
        let userInfo = {
          full_name: this.fullName,
          password: this.password,
          email: this.email,
          phone_no: this.phoneNo,
          facebook: this.facebook,
          ref: this.$cookies.get('ref')
        }

        this.submitObjectList(this.$config.apiPath.register, userInfo, 'user', undefined, function (result) {
          self.$cookies.set('username', result.data.username, '90D')
          self.$cookies.set('sessionId', result.data.sessionId, '90D')
          if (self.$cookies.get('redirectAfterLogin') !== null) {
            self.$router.push(self.$cookies.get('redirectAfterLogin'))
            self.$cookies.remove('redirectAfterLogin')
          } else {
            self.$router.push('/#/home/vps')
          }
          self.$toasted.success(result.msg)
          self.$emit('loginEvent', result.data)
        })
      }
    },
    login: function () {
      this.$router.push('/login')
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    },
    setReferralToLocalStorage: function () {
      if (this.$route.query.ref !== undefined) {
        this.$cookies.set('ref', this.$route.query.ref, '30D')
      }
      if (this.$route.query.r !== undefined) {
        this.$cookies.set('ref', this.$route.query.r, '30D')
      }
    }
  }
}
</script>

<style scoped>

</style>
