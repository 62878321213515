<template>
  <div class="container pb-5">
    <div class="card card-input-box mx-auto mt-3 max-width-800">
      <div class="card-body d-flex justify-content-center">
        <form>
          <div class="form-group">
            <label class="text-center m-0">{{$t('topup.label.topupAmount')}}</label>
            <div class="input-group input-group-static mb-4">
              <span v-if="exchangeRate !== 0"
                    class="input-group-text me-3">{{currencyFormat(topupAmount * exchangeRate, defaultCurrency)}}</span>
              <input id="topupAmount"
                     type="number"
                     class="form-control text-center text-5xl"
                     aria-describedby="topupAmount"
                     required
                     :placeholder="$t('topup.placeholder.topupAmount')"
                     max="10000"
                     min="1"
                     v-model="topupAmount">
            </div>
            <div class="mb-3 d-flex justify-content-center mb-5">
              <span class="badge badge-danger cursor-pointer" @click="quickAmountClick(5)">$5</span>
              <span class="badge badge-secondary cursor-pointer ms-1" @click="quickAmountClick(10)">$10</span>
              <span class="badge badge-success cursor-pointer ms-1" @click="quickAmountClick(50)">$50</span>
              <span class="badge badge-info cursor-pointer mx-1" @click="quickAmountClick(100)">$100</span>
              <span class="badge badge-primary cursor-pointer mx-1" @click="quickAmountClick(200)">$200</span>
              <span class="badge badge-violet cursor-pointer" @click="quickAmountClick(300)">$300</span>
            </div>

            <div class="mb-4">
              <div class="input-group input-group-dynamic"
                   v-if="currentUser['customerType'] === 'supervip'"
              >
                <label class="form-label" for="basic-url">Promotion code</label>
                <span class="input-group-text" id="basic-addon3">
                <label class="text-success">${{promotionValue.toFixed(2)}}</label>
                <label class="badge bg-gradient-violet link cursor-pointer on-top" @click="promotionCheck"><span>Apply</span></label>
              </span>
                <input type="text" class="form-control" v-model="promotionCode">
              </div>
              <span v-if="promotionMsg !== ''" v-html="promotionMsg"/>
            </div>

            <div class="text-right pt-2 d-flex justify-content-center">
              <button class="btn btn-icon btn-3 btn-secondary mx-1" type="button" @click.prevent="reset">
                <span class="btn-inner--icon"><i class="material-icons">&#xe5c9;</i></span>
                <span class="btn-inner--text">{{this.$t('topup.button.reset')}}</span>
              </button>

              <button class="btn btn-success mx-1" @click.prevent="finish">
                <span>{{this.$t('topup.button.finishOrder')}}</span>
                <span class="btn-inner--icon"><i class="material-icons">&#xeaaa;</i></span>
              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { FixBugTheme } from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'

export default {
  name: 'Topup',
  mixins: [dataRequester, inputHelper],
  components: {
  },
  props: {
    currentUser: {},
    metaData: {},
  },
  watch: {
    metaData: {
      handler: function () {
        this.defaultCurrency = this.metaData.defaultCurrency
        this.exchangeRate = this.metaData.exchangeRate
      },
      deep: true
    },
  },
  data: function () {
    return {
      topupAmount: 100,
      exchangeRate: 0,
      defaultCurrency: undefined,

      promotion: undefined,
      promotionMsg: '',
      promotionCode: ''
    }
  },
  computed: {
    promotionValue: function () {
      if (this.promotion !== undefined) {
        return this.topupAmount / 100 * this.promotion.promotion_rate
      } else {
        return 0
      }
    }
  },
  created() {
    this.FixBugTheme()
    this.defaultCurrency = this.metaData.defaultCurrency
    this.exchangeRate = this.metaData.exchangeRate
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    reset: function () {
      this.topupAmount = 100
      this.promotion = undefined
      this.promotionMsg = ''
      this.promotionCode = ''
    },
    finish: function () {
      let self = this
      self.submitObjectList(self.$config.apiPath.orderPlace, {
        type: 'topup',
        total_amount: self.topupAmount,
        promotion_code: self.promotion ? self.promotion.promotion_code : undefined
      }, 'order', undefined, function (result) {
        function openOrderTab (url) {
          window.open(url, '_blank')
        }
        self.$toasted.success(result.msg)
        setTimeout(openOrderTab('/#/home/order/detail/' + result.data.id), 1500)
      })
    },
    quickAmountClick: function (value) {
      this.topupAmount = value
    },

    promotionCheck: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.promotionCheck, {
        promotion_code: self.promotionCode
      }, 'promotion', undefined, function (result) {
        self.promotion = result.data.promotion
        if (result.status === 'success') {
          self.promotionMsg = self.$t('topup.label.promotionCodeValid')
        } else {
          self.promotionMsg = self.$t('topup.label.promotionCodeInvalid')
        }
      })
    }
  },
  mounted() {
    this.FixBugTheme()
  }
}
</script>

<style scoped>

</style>
