<template>
  <div>
    <div class="row"
    v-if="overviewData !== null">
      <div class="col-lg-4 col-md-6 col-sm-6 height-full">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2">
            <div class="icon icon-lg icon-shape bg-gradient-info shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute">
              <i class="material-icons opacity-10">&#xe30a;</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0 text-capitalize">
                {{ $t('dashboard.overview.services') }}
              </p>
              <h1 class="mb-0">
                {{ overviewData['total_services'] || 0 }}
              </h1>
            </div>
          </div>
          <hr class="dark horizontal my-0">
          <div class="card-footer p-3">
            <p class="mb-0">
              <a href="#/home/cloud-vps">
                <span class="text-info font-weight-bolder text-2xl">{{ overviewData['total_vps'] || 0 }} </span>{{ $t('dashboard.overview.vpsAvailable') }}
              </a>
            </p>
            <p class="mb-0">
              <a href="#/home/dedicated-server">
                <span class="text-info font-weight-bolder text-2xl">{{ overviewData['total_dedicated'] || 0 }} </span>{{ $t('dashboard.overview.dedicatedServerAvailable') }}
              </a>
            </p>
            <p class="mb-0">
              <a href="#/home/cloud-vps">
                <span class="text-danger font-weight-bolder text-2xl">{{ overviewData['service_expired_3'] || 0 }} </span>{{ $t('dashboard.overview.servicesExpired', [3]) }}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 mt-sm-0 mt-4 height-full">
        <div class="card  mb-2">
          <div class="card-header p-3 pt-2">
            <div class="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
              <i class="material-icons opacity-10">&#xe8b0;</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0 text-capitalize">
                {{ $t('dashboard.overview.orders') }}
              </p>
              <h1 class="mb-0">
                {{ overviewData['total_orders'] || 0 }}
              </h1>
            </div>
          </div>
          <hr class="dark horizontal my-0">
          <div class="card-footer p-3">
            <p class="mb-0">
              <a href="/#/home/order/history">
                <span class="text-warning font-weight-bolder text-2xl">{{ overviewData['total_pending'] || 0 }} </span>{{ $t('dashboard.overview.ordersPending') }}
              </a>
            </p>
            <p class="mb-0">
              <a href="/#/home/order/history">
                <span class="text-info font-weight-bolder text-2xl">{{ overviewData['total_installing'] || 0 }} </span>{{ $t('dashboard.overview.ordersInstalling') }}
              </a>
            </p>
            <p class="mb-0 text-2xl">
              &ensp;
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4 height-full">
        <div class="card  mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
              <i class="material-icons opacity-10">&#xe53f;</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0 text-capitalize ">
                Tickets
              </p>
              <h1 class="mb-0 ">
                {{ticketOverview['total']}}
              </h1>
            </div>
          </div>
          <hr class="horizontal my-0 dark">
          <div class="card-footer p-3">
            <p class="mb-0 ">
              <a href="javascript:;">
                <span class="text-success font-weight-bolder text-2xl">{{ticketOverview['total_open']}} </span>{{ $t('dashboard.overview.ticketOpen') }}
              </a>
            </p>
            <p class="mb-0 ">
              <a href="javascript:;">
                <span class="text-danger font-weight-bolder text-2xl">{{ticketOverview['total_closed']}} </span>{{ $t('dashboard.overview.ticketClosed') }}
              </a>
            </p>
            <p class="mb-0 text-2xl">
              &ensp;
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4"
    v-if="guideData !== null">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="card height-full">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-md-6">
                <h4 class="mb-0 text-primary">
                  {{ $t('dashboard.overview.guide') }}
                </h4>
              </div>
            </div>
          </div>
          <hr class="dark horizontal my-0">
          <div class="card-body p-3 min-height-150 max-height-400 overflow-auto">
            <ul class="list-group">
              <li
                  v-for="(guide, key) in guideData.filter(x => x['category'] === 'Guide' && x['lang'] === languageSelected.substring(0, 2))"
                  :key="key"
                  class="list-group-item border-0 justify-content-between ps-0 pb-0 border-radius-lg"
              >
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <button class="btn btn-icon-only btn-rounded btn-outline-info mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                      <i class="material-icons text-lg">&#xf1c8;</i>
                    </button>
                    <div class="d-flex flex-column">
                      <a
                          :href="guide['url']"
                          target="_blank"
                      >
                        <strong>{{ guide['title'] }}</strong>
                      </a>
                      <span class="text-xs">{{ guide['short_content'] }}</span>
                    </div>
                  </div>
<!--                  <div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold ms-auto">-->
<!--                    {{ guide['category'] }}-->
<!--                  </div>-->
                </div>
                <hr class="horizontal dark mt-3 mb-2">
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 mt-sm-0 mt-4">
        <div class="card height-full">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-md-6">
                <h4 class="mb-0 text-primary">
                  {{ $t('dashboard.overview.security') }}
                </h4>
              </div>
            </div>
          </div>
          <hr class="dark horizontal my-0">
          <div class="card-body p-3 min-height-150 max-height-400 overflow-auto">
            <ul class="list-group">
              <li
                  v-for="(guide, key) in guideData.filter(x => x['category'] === 'Security' && x['lang'] === languageSelected.substring(0, 2))"
                  :key="key"
                  class="list-group-item border-0 justify-content-between ps-0 pb-0 border-radius-lg"
              >
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <button class="btn btn-icon-only btn-rounded btn-outline-info mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                      <i class="material-icons text-lg">&#xf1c8;</i>
                    </button>
                    <div class="d-flex flex-column">
                      <a
                          :href="guide['url']"
                          target="_blank"
                      >
                        <strong>{{ guide['title'] }}</strong>
                      </a>
                      <span class="text-xs">{{ guide['short_content'] }}</span>
                    </div>
                  </div>
<!--                  <div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold ms-auto">-->
<!--                    {{ guide['category'] }}-->
<!--                  </div>-->
                </div>
                <hr class="horizontal dark mt-3 mb-2">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { FixBugTheme } from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'

export default {
  name: "Dashboard",
  mixins: [dataRequester],
  props: {
    languageSelected: String
  },
  data() {
    return {
      overviewData: null,
      ticketOverview: null,
      guideData: null
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  created() {
    let self = this
    this.submitObjectList(this.$config.apiPath.dashboardOverview, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.overviewData = result.data['serviceSummaryObject'],
        self.ticketOverview = result.data['ticketSummaryObject']
      } else {
        this.$toasted.error(result.msg)
      }
    })
    this.submitObjectList(this.$config.apiPath.dashboardGuide, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.guideData = result.data['dashboardLinkObjectList']
      } else {
        this.$toasted.error(result.msg)
      }
    })
  },
  methods: {
    FixBugTheme
  }
}



</script>
