<template>
  <span class="dropdown">
    <span class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
      <slot name="dropdown-button"/>
    </span>
    <ul class="dropdown-menu z-index-sticky" aria-labelledby="dropdownMenuButton">
      <li v-for="(item, index) in items" :key="index">
        <a class="dropdown-item" href="javascript:;">
          <span class="text-wrap" v-html="item.label"/>
        </a>
      </li>
    </ul>
  </span>
</template>

<script>
export default {
  props: {
    items: []
  }
}
</script>
