<template>
  <button
      :class="`btn btn-${variant}`"
      type="button"
      @click="$emit('click')"
      :disabled="loading"
  >
    <i v-if="loading" class="fas fa-sync-alt fa-spin fa-lg me-3"></i>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "LevButton",
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
