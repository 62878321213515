<template>
  <div class="mt-1">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <div class="nav-wrapper position-relative end-0">
          <ul
            class="nav nav-pills nav-fill"
            role="tablist"
          >
            <li class="nav-item">
              <a
                id="tab-basic"
                class="nav-link mb-0 px-0 py-1 active"
                data-bs-toggle="tab"
                href="#pills-basic"
                role="tab"
                aria-controls="pills-basic"
                aria-selected="true"
              >
                <span class="material-icons align-middle mb-1">
                  &#xe8ef;
                </span>
                {{ $t('proxy.basic') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                id="tab-detail"
                class="nav-link mb-0 px-0 py-1"
                data-bs-toggle="tab"
                href="#pills-detail"
                role="tab"
                aria-controls="pills-detail"
                aria-selected="false"
              >
                <span class="material-icons align-middle mb-1">
                  &#xe873;
                </span>
                {{ $t('proxy.detail') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      id="pills-tabContent"
      class="tab-content mt-3"
    >
      <div
        id="pills-basic"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="pills-basic-tab"
      >
        <basic-list
            :items="proxyBasicList"
            :delete-list="proxyDetailDeleteList"
            @filter-detail-by-ip="filterDetailByIp"
        />
      </div>
      <div
        id="pills-detail"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="pills-detail-tab"
      >
        <detail-list
            :items="proxyDetailList"
            :search-text="searchText"
            @delete-proxy="deleteProxy"
        />
      </div>
    </div>
  </div>
</template>

<script>

import BasicList from "@/components/services/proxy/BasicList";
import DetailList from "@/components/services/proxy/DetailList";
import {FixBugTheme} from "@/mixins/MaterialTheme";
import dataRequester from "@/mixins/utilities/DataRequester";

export default {
  name: "List",
  mixins: [dataRequester],
  components: {
    BasicList,
    DetailList
  },
  props: {
    metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    }
  },
  data: function () {
    return {
      searchText: '',
      proxyDetailDeleteList: [],
      proxyBasicList: [],
      proxyDetailList: []
    }
  },
  mounted() {
    FixBugTheme()
  },
  updated() {
    FixBugTheme()
  },
  created () {
    let self = this
    this.getDataFromAPI(this.$config.apiPath.proxyList, function (result) {
      self.proxyBasicList = result.data['proxyObjectList']
      self.getDataFromAPI(self.$config.apiPath.proxyDetailList, function (result) {
        self.proxyDetailList = result.data['proxyDetailObjectList']
        self.proxyDetailList = self.proxyDetailList.map(x => {
          let obj = self.proxyBasicList.find(y => x['vm_id'] === y['id'])
          x['user_remark'] = obj['user_remark']
          return x
        })
      })
    })
  },
  methods: {
    FixBugTheme,
    filterDetailByIp(ip) {
      this.searchText = ip

      let basicTab = document.getElementById('tab-basic')
      let detailTab = document.getElementById('tab-detail')
      basicTab.classList.remove('active')
      detailTab.classList.add('active')

      let basicContent = document.getElementById('pills-basic')
      let detailContent = document.getElementById('pills-detail')
      basicContent.classList.remove('show')
      basicContent.classList.remove('active')
      detailContent.classList.add('show')
      detailContent.classList.add('active')
    },
    deleteProxy(proxyDetailList) {
      this.proxyDetailDeleteList = proxyDetailList
    }
  }
}
</script>

<style scoped>

</style>
