<template>
  <div class="p-0 m-0">
    <div>
      <label class="width-full p-1">
        <div class="card">
          <div class="card-body p-2">
            <div class="row text-center text-bold small text-uppercase">
              <div v-for="(column, index) in columns" :key="index" class="col">
                <span>{{ column['label'] }}</span>
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div
        v-for="(value, index) in this.items"
        :key="index"
    >
      <input
          :id="'planId_' + value['service_type']['service_type_id'] + value['service_plan_id']"
          type="radio"
          class="btn-check"
          name="btnradioPlan"
          :value="value['service_plan_id']"
          autocomplete="off"
          @change="$emit('change', value['service_plan_id'])"
      >
      <label
          class="btn btn-outline-primary width-full p-1"
          :for="'planId_' + value['service_type']['service_type_id'] + value['service_plan_id']"
      >
        <div class="card">
          <div class="card-body row p-2 align-items-center text-dark">
            <div class="col text-lg">
              <div>{{ value['vm_plan_name'] }}</div>
            </div>
            <span class="col text-lg">
              {{ value['vcores'] }}
            </span>
            <span class="col">
              <span class="text-lg">{{ (value['ram'] / 1024).toFixed(0) }}</span>GB
            </span>
            <span class="col">
              <span class="text-lg">{{ value['disk'] }}</span>GB {{ value['disk_type'] }}
            </span>
            <span v-if="value['transfer_limit'] > 0" class="col">
              <span class="text-lg">{{ value['transfer_limit'] }}</span> GB
            </span>
            <span v-else class="col">
              {{ $t('orderNew.table.unlimited') }}
            </span>
            <span class="col">
              <span class="text-lg">${{ value['price'] }}</span>/{{ $t('orderNew.table.mo') }}
            </span>
            <span class="col text-justify">
              <label class="text-uppercase-none smaller">{{ value['description'] }}</label>
            </span>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "VmPlans",
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    let self = this
    return {
      columns: [
        {
          key: 'vm_plan_name',
          label: self.$t('orderNew.table.h_name')
        },
        {
          key: 'vcores',
          label: self.$t('orderNew.table.h_cpu')
        },
        {
          key: 'ram',
          label: self.$t('orderNew.table.h_ram')
        },
        {
          key: 'disk',
          label: self.$t('orderNew.table.h_storage')
        },
        {
          key: 'transfer_limit',
          label: self.$t('orderNew.table.h_traffic')
        },
        {
          key: 'price',
          label: self.$t('orderNew.table.h_price')
        },
        {
          key: 'description',
          label: self.$t('orderNew.table.h_note')
        },
      ]
    }
  }
}
</script>
