<template>
<!--  <div class="modal fade" id="modal-default" tabindex="-1" role="dialog" aria-labelledby="modal-default" aria-hidden="true">-->
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title font-weight-normal"><strong v-html="title"></strong></h4>
          <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <slot/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn bg-gradient-secondary mr-auto" data-bs-dismiss="modal"
                  @click="$emit('modalCancel')"
          >{{ closeButton }}</button>
          <button type="button" class="btn bg-gradient-danger"
                  v-if="additionalButton['is_visible']"
                  @click="$emit('additionalButtonClick')"
          >{{ additionalButton['text'] }}</button>
          <button type="button" class="btn bg-gradient-primary"
                  @click="$emit('modalConfirm')"
          >{{ confirmButton }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewConfirmModal",
  props: {
    title: {
      type: String,
      default: "Modal Title",
    },
    confirmButton: {
      type: String,
      default: function () {
        return this.$t("vps.modal.buttonConfirm");
      },
    },
    closeButton: {
      type: String,
      default: function () {
        return this.$t("vps.modal.buttonCancel");
      },
    },
    additionalButton: {
      type: Object,
      is_visible: false,
      default: function () {
        return {
          is_visible: false,
          text: "Additional Button",
        }
      },
    },
  }
}
</script>


