<template>
  <div class="mt-1">
    <div class="card mb-3">
      <div class="card-body mt-1">
        <div class="lev-table">
          <lev-table
            :table-data="dataObjectList"
            :fields="tableSnapshotColumns"
            :searchalbe-fields="searchableFields"
            :extended-fields="VPSExtendtedFields"
            :on-cell-click-processing="onCellClickProcessing"
            @selected-changed="onSelectedChanged"
          />
        </div>
      </div>
      <confirm-modal
        :action="action"
        :active="active"
        :confirm-modal-display-object="confirmModalDisplayObject"
        @modalConfirm="confirmedCallback"
        @modalCancel="finishModal"
        @finishAction="finishAction"
      />
    </div>
  </div>
</template>

<script>
import LevTable from "@/components/children/LevTable";

import dataObjectList from '@/mixins/DataObjectList'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'
import dataRequester from '../../../mixins/utilities/DataRequester'
import inputHelper from '../../../mixins/utilities/InputHelper'

import confirmModal from '../../children/ConfirmModal'

import moment from 'moment'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "SnapshotVPS",
  components: {
    LevTable,
    confirmModal
  },
  mixins: [dataObjectList, dataRequester, confirmModalBuilder, inputHelper],
  props: {
    currentUser: {}
  },
  data: function () {
    let self = this
    return {
      action: 'none',
      selectedObjectIndex: null,
      tableSnapshotColumns: [
        {
          name: 'id',
          title: 'ID',
          width: '5%',
          order: 1,
          visible: false
        },
        {
          name: 'snapshot_name',
          title: this.$t('snapshot.table.thName'),
          width: '20%',
          order: 2
        },
        {
          name: 'image_name',
          title: '<i class="fa fa-windows"></i><span class="text-bolder"> ' + this.$t('snapshot.table.thImageName') + '</span>',
          width: '35%',
          order: 3
        },
        {
          name: 'disk',
          title: '<i class="fas fa-hdd"></i><span class="text-bolder"> ' + this.$t('snapshot.table.thSize') + '</span>',
          width: '10%',
          order: 4,
          formatter: function (cellData) {
            return cellData + ' GB'
          }
        },
        {
          name: 'status',
          title: '<i class="fas fa-info"></i><span class="text-bolder"> ' + this.$t('snapshot.table.thStatus') + '</span>',
          width: '10%',
          order: 6
        }
      ],
      searchableFields:['image_name', 'snapshot_name'],
      VPSExtendtedFields: [
        {
          name: 'note',
          title: `<i class="fas fa-sticky-note"></i> <span class="text-bolder">${this.$t('snapshot.table.thNote')}</span>`,
          width: '15%',
          order: 5,
          formatter: function (rowData) {
            if (rowData['is_update_note']) {
              return `<div class="d-flex justify-content-between align-items-center">
                        <div class="input-group input-group-static">
                          <input type="text" class="form-control" id="inputNoteUpdate" value="${rowData['note']}">
                        </div>
                        <div class="d-flex justify-content-between">
                          <i class="cursor-pointer fas fa-save text-success btn-tooltip me-1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-container="body"
                          data-animation="true"
                          title="${self.$t('snapshot.tooltip.saveNote')}" name="saveNote"></i>
                          <i class="cursor-pointer fas fa-window-close text-secondary btn-tooltip me-1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-container="body"
                          data-animation="true"
                          title="${self.$t('snapshot.tooltip.cancelEditNote')}" name="cancelEditNote"></i>
                        </div>
                    </div>`
            } else {
              return `<div class="d-flex justify-content-between align-items-center">
                        <span>${rowData['note'] || '-'}</span>
                        <i class="cursor-pointer fas fa-edit text-info btn-tooltip me-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-container="body"
                        data-animation="true"
                        title="${self.$t('snapshot.tooltip.editNote')}" name="editNote"
                        ></i>
                    </div>`
            }
          }
        },
        {
          name: 'manage',
          title: '<i class="fas fa-cog"></i><span class="text-bolder"> ' + this.$t('snapshot.table.thManage') + '</span>',
          width: '20%',
          formatter: function () {
            return '<span class="badge badge-pill bg-gradient-primary cursor-pointer" name="delete">Delete</span>'
          }
        },
        {
          name: 'is_update_note',
          extended_data: false,
          visible: false
        },
      ]
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    self.getDataFromAPI(self.$config.apiPath.snapList, function (result) {
      self.dataObjectList = result.data
    })
  },
  methods: {
    FixBugTheme,
    onSelectedChanged(selectedRowID) {
      // console.log(this.dataObjectList.filter(d => selectedRowID.includes(d['id'])))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      switch (cellField.name) {
        case 'manage':
          if (event.target.getAttribute('name') === 'delete') {
            this.selectedObjectIndex = this.dataObjectList.findIndex( item => item.id === rowData.id)
            this.delete(rowData)
            break
          }
        case 'note':
          if (event.target.getAttribute('name') === 'editNote') {
            this.editNote(rowData)
            break
          }
          if (event.target.getAttribute('name') === 'saveNote') {
            this.saveNote(rowData)
            break
          }
          if (event.target.getAttribute('name') === 'cancelEditNote') {
            this.cancelEditNote(rowData)
            break
          }
          break
        default:
          break
      }
      updatePostTable()
    },
    delete: function (rowData) {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('snapshot.modal.title') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('snapshot.modal.bodyWarningDelete')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><strong>'
      this.confirmModalDisplayObject.bodyMsg += `${rowData['snapshot_name']} - ${rowData['image_name']} (${rowData['disk']} GB)`
      this.confirmModalDisplayObject.bodyMsg += '</strong></div>'

      if (moment(this.currentUser['latest_snap']).add(7, 'days') > moment()) {
        this.confirmModalDisplayObject.bodyMsg += '<div class="text-danger">' + this.$t('snapshot.modal.bodyWarningTooSoon',
            [this.convertISOTime(moment(this.currentUser['latest_snap']).add(7, 'days'))]) + '</div>'
      }
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('snapshot.modal.buttonDelete')
      this.action = 'deleteSnap'
      this.showModal(true)
    },
    confirmedCallback: function () {
      let self = this
      if (this.action === 'deleteSnap') {
        this.submitObjectList(this.$config.apiPath.snapDelete, {id: this.dataObjectList[this.selectedObjectIndex].id},
            'snapshot', undefined, function (deleteResult) {
              self.$toasted.success(deleteResult.msg)
              self.dataObjectList.splice(self.selectedObjectIndex, 1)
              // $('#dataTable').DataTable().rows(self.selectedObjectIndex).remove().draw(false)
            })
      }
      self.finishModal()
    },
    finishAction: function () {
      this.action = 'none'
    },

    editNote: function (rowData) {
      rowData['is_update_note'] = true
    },
    saveNote: function (rowData) {
      let self = this
      rowData['is_update_note'] = false
      let newNote = document.getElementById('inputNoteUpdate').value
      this.submitObjectList(this.$config.apiPath.snapShotSaveNote, {
        id: rowData['id'],
        note: newNote
      }, 'snapshot', null, function (result) {
        rowData['note'] = newNote
        self.$toasted.success(result.msg)
      })
    },
    cancelEditNote: function (rowData) {
      rowData['is_update_note'] = false
    }
  }
}
</script>
