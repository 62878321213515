<template>
  <div class="bg-gray-200">
    <main class="main-content  mt-0">
      <div
        class="page-header align-items-start min-vh-100"
        style="background-image: url('../../assets/img/bg/login.jpeg');"
      >
        <span class="mask bg-gradient-dark opacity-6" />
        <div class="container my-auto">
          <div class="row">
            <div class="col-lg-4 col-md-8 col-12 mx-auto">
              <div class="card z-index-0 fadeIn3 fadeInBottom">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div class="bg-gradient-light shadow-primary border-radius-lg py-3 pe-1">
                    <img
                      :src="metaData.logoPath"
                      class="img-logo-login"
                      :alt="metaData.siteName + ' logo'"
                    >
                  </div>
                </div>
                <div
                  v-if="is_enable_2fa"
                  class="card-body"
                >
                  <confirm2-fa
                    @clicked-confirm-button="login"
                  />
                </div>
                <div
                  v-else
                  class="card-body"
                >
                  <form
                    role="form"
                    class="text-start"
                  >
                    <div class="input-group input-group-outline my-3">
                      <label class="form-label">{{ $t('login.label.username') }}</label>
                      <input
                        id="username"
                        v-model="username"
                        class="form-control"
                        required
                      >
                    </div>
                    <div class="input-group input-group-outline mb-3">
                      <label class="form-label">{{ $t('login.label.password') }}</label>
                      <input
                        id="password"
                        v-model="password"
                        type="password"
                        class="form-control"
                        required
                      >
                    </div>
                    <div class="form-check form-switch d-flex align-items-center mb-3">
                      <input
                        id="rememberMe"
                        class="form-check-input"
                        type="checkbox"
                      >
                      <label
                        class="form-check-label mb-0 ms-2"
                        for="rememberMe"
                      >{{ $t('login.label.remember') }}</label>
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn bg-gradient-success w-100 my-4 mb-2"
                        @click.prevent="login('')"
                      >
                        {{ $t('login.button.login') }}
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-primary w-100 my-1 mb-2"
                        @click="register"
                      >
                        {{ $t('login.button.register') }} <span class="fa fa-arrow-right" />
                      </button>
                    </div>
                    <p class="mt-4 text-sm text-center">
                      <button
                        class="btn btn-link btn-block text-primary text-gradient font-weight-bold"
                        @click="recoverPassword"
                      >
                        {{ $t('login.button.forgotpw') }}
                      </button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer position-absolute bottom-2 py-2 w-100">
        <div class="container">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-12 col-md-6 my-auto">
              <div class="copyright text-center text-sm text-white text-lg-start">
                <div v-html="metaData.footNote" />
              </div>
            </div>
            <div class="col-lg-6 justify-content-end">
              <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                <li class="nav-item">
                  <a
                    href="https://fb.com/LowendVietServer"
                    class="nav-link pe-0 text-white"
                    target="_blank"
                  ><i class="fab fa-facebook-square" /> Facebook</a>
                </li>
                <li class="nav-item">
                  <div class="dropup">
                    <a
                      id="navbarDropdownMenuLink2"
                      href="javascript:;"
                      class="dropdown-toggle nav-link pe-0 text-white"
                      data-bs-toggle="dropdown"
                    >
                      <i class="fa fa-fw fa-globe" /> Language&nbsp;
                      <flag
                        :iso="this.$i18n.locale.slice(-2)"
                        :squared="false"
                      />
                    </a>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink2"
                    >
                      <li>
                        <div
                          class="dropdown-item"
                          aria-labelledby="language"
                        >
                          <span
                            v-for="(lang, i) in metaData.availableLang"
                            :key="i"
                          >
                            <span
                              class="dropdown-item"
                              @click="changeLocale(lang['locale'])"
                            >
                              <span class="">
                                <flag
                                  :iso="lang['locale'].slice(-2)"
                                  :squared="false"
                                />
                              </span> {{ lang['description'] }}
                            </span>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </main>
  </div>
</template>

<script>
import axios from 'axios'
import md5 from 'md5'
import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'
import { FixBugTheme } from '../../mixins/MaterialTheme';
import confirm2Fa from "@/components/children/Confirm2Fa";
export default {
  name: "Login",
  components: {confirm2Fa},
  mixins: [ inputHelper, dataRequester ],
  props: {
    currentUser: {}
  },
  data: function () {
    return {
      username: '',
      password: '',
      remember: true,
      is_enable_2fa: false,
      fbLoginOptions: {
        scope: 'id,name,email'
      },
      metaData: {
        logoPath: '',
        siteName: '',
        footNote: '',
        shortFootNote: '',
        availableLang: ''
      }
    }
  },
  mounted: function () {
   this.FixBugTheme()
  },
  created: function () {
    let self = this

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }

    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.shortFootNote = metaData.domainObjectList['shortfootnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
    }, function () {
      this.$router.push('https://levcs.com')
    })

    self.loginByApiKey()
  },
  methods: {
    FixBugTheme,
    login: function (otp_code) {
      let self = this
      let loginData = {
        username: this.username.toString().trim(),
        encryptedPassword: md5(this.password),
        otp_code: otp_code
      }
      if (this.checkValidEmail(this.username)) {
        loginData['email'] = this.username
      }
      axios.post(this.$config.apiPath.login, loginData)
          .then(function (response) {
                if (response.data.status === 'success') {
                  if (otp_code === '') {
                    self.is_enable_2fa = response.data.data['is_enable_2fa']
                  }
                  if (!response.data.data['is_enable_2fa']) {
                    let expiredTime = '0'
                    if (self.remember === true) {
                      expiredTime = '90D'
                    }
                    self.$cookies.set('username', response.data.data.username, expiredTime)
                    self.$cookies.set('email', response.data.data.email, expiredTime)
                    self.$cookies.set('sessionId', response.data.data.sessionId, expiredTime)
                    self.$toasted.success(response.data.msg)
                    self.$emit('loginEvent', response.data.data)
                    if (self.$cookies.get('redirectAfterLogin') !== null) {
                      self.$router.push(self.$cookies.get('redirectAfterLogin'))
                      self.$cookies.remove('redirectAfterLogin')
                    } else {
                      self.$router.push('/home/dashboard')
                    }
                  }
                } else {
                  self.$emit('loginEvent', null)
                  self.$toasted.error(response.data.msg)
                }
              }
          )
    },
    loginByApiKey: function () {
      let username = this.$router.currentRoute.query['username']
      let apiKey = this.$router.currentRoute.query['apiKey']
      if (username !== undefined && apiKey !== undefined) {
        let self = this
        let loginData = {
          username: username,
          APIKey: apiKey
        }
        axios.post(this.$config.apiPath.login, loginData)
            .then(function (response) {
                  if (response.data.status === 'success') {
                    let expiredTime = '0'
                    if (self.remember === true) {
                      expiredTime = '90D'
                    }
                    self.$cookies.set('username', response.data.data.username, expiredTime)
                    self.$cookies.set('email', response.data.data.email, expiredTime)
                    self.$cookies.set('sessionId', response.data.data.sessionId, expiredTime)
                    self.$toasted.success(response.data.msg)
                    self.$emit('loginEvent', response.data.data)
                    if (self.$cookies.get('redirectAfterLogin') !== null) {
                      self.$router.push(self.$cookies.get('redirectAfterLogin'))
                      self.$cookies.remove('redirectAfterLogin')
                    } else {
                      self.$router.push('/home/dashboard')
                    }
                  } else {
                    self.$emit('loginEvent', null)
                    self.$toasted.error(response.data.msg)
                  }
                }
            )
      }
    },
    register: function () {
      this.$router.push('/register')
    },
    recoverPassword: function () {
      this.$router.push('/forgotpw')
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    }
  }
}
</script>
