<template>
  <div :class="`input-group input-group-${formType}`">
    <label v-if="label !== undefined" class="form-label">{{ label }}</label>
    <input :value="value" min="1" :type="type" class="form-control" @input="updateValue">
  </div>
</template>

<script>
import {FixBugTheme} from "@/mixins/MaterialTheme";

export default {
  name: "LevInput",
  props: {
    label: {
      type: String,
      default: undefined,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    formType: {
      type: String,
      default: 'outline'
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    updateValue (value) {
      if (this.type === 'number') {
        this.$emit('input', parseInt(value.target.value))
      } else {
        this.$emit('input', value.target.value)
      }
    }
  }
}
</script>

<style scoped>

</style>
