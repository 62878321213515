<template>
  <div class="container mt-3">
    <div v-if="notifications !== null && notifications.length > 0" class="list-group list-group-flush small">
      <div
          v-for="(notification, index) in notifications"
          :key="index"
          class="card mb-4"
      >
        <div class="card-header mb-1 pb-0">
          <div class="d-flex d-inline align-items-center">
            <i class="decorate-icon fa fa-bullhorn fa-3x text-info me-3" />
            <div>
              <h4 class="m-0 text-primary">{{ notification['title'] }}</h4>
              <span>{{ convertISOTime(notification['updated_at']) }}</span>
            </div>
          </div>
          <hr/>
        </div>
        <div class="card-body mt-1 pt-0">
          <div class="overflow-auto" v-html="notification['content']"/>
        </div>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body text-center small text-secondary">
        {{$t('notification.no_notification')}}
      </div>
    </div>
  </div>
</template>

<script>

import inputHelper from '../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "Notification",
  mixins: [ inputHelper ],
  props: {
    notifications: null
  },
  mounted() {
    this.FixBugTheme()
  },
  created() {

  },
  methods: {
    FixBugTheme
  }
}
</script>

<style scoped>

</style>
