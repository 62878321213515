<template>
  <div class="d-flex justify-content-start align-items-center">
    <div class="number-circle">
      <span>{{ number }}</span>
    </div>
    <span
        class="font-weight-bold text-primary"
        style="font-size: 32px"
    >&ensp;{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "TitleNumber",
  props: {
    title: {
      type: String,
      default: "",
      required: true
    },
    number: {
      type: [Number, String],
      default: 0,
      required: true
    }
  }
}
</script>
