<template>
  <div class="g-sidenav-show g-sidenav-pinned">
    <div
      id="loading-modal"
      class="loading loading-hidden"
    >
      Loading&#8230;
    </div>
    <aside
      id="sidenav-main"
      class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark"
    >
      <div class="sidenav-header">
        <i
          id="iconSidenav"
          class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
        />
        <router-link
          class="btn navbar-brand m-0"
          to="/home"
        >
          <img
            id="main_logo"
            :src="metaData.logoPath"
            class="navbar-brand-img h-100"
            alt="main_logo"
          >
        </router-link>
      </div>
      <hr class="horizontal light mt-0 mb-2">
      <div
        id="sidenav-collapse-main"
        class="collapse navbar-collapse  w-auto h-auto"
      >
        <ul class="navbar-nav">
          <!-- Dashboard -->
          <li class="nav-item">
            <router-link
              :class="{'active': this.$route.path.includes('dashboard'), 'nav-link': true, 'text-white': true}"
              to="/home/dashboard"
            >
              <i class="material-icons-round opacity-10">&#xe871;</i>
              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.dashboard') }}</span>
            </router-link>
          </li>
          <!-- Orders -->
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              href="#orders"
              :class="{'active': this.$route.meta.breadcrumb === 'Order', 'nav-link': true, 'text-white': true}"
              class="nav-link text-white"
              aria-controls="dashboardsExamples"
              role="button"
              aria-expanded="true"
            >
              <i class="material-icons-round opacity-10">&#xe8cc;</i>
              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.order') }}</span>
            </a>
            <div
              id="orders"
              class="collapse show"
            >
              <ul class="nav ">
                <li :class="{'active': isOrderScreen, 'nav-item': true}">
                  <router-link
                    :class="{'active': isOrderScreen, 'nav-link nav-sub-menu text-white': true}"
                    to="/home/order"
                  >
                    <span class="sidenav-mini-icon"> <i class="fas fa-cart-plus" /> </span>
                    <span class="sidenav-normal  ms-2  ps-1">{{ $t('home.link.orderNew') }}</span>
                  </router-link>
                </li>
<!--                <li v-if="metaData.isMenuOrderPromotionEnable" :class="{'active': isOrderPromotionScreen, 'nav-item': true}">-->
<!--                  <router-link-->
<!--                      :class="{'active': isOrderPromotionScreen, 'nav-link': true, 'nav-sub-menu': true, 'text-white': true}"-->
<!--                      to="/home/order?filter=promotion"-->
<!--                      style="padding-left: 10px!important"-->
<!--                  >-->
<!--                    <span class="sidenav-mini-icon ps-0 ms-0">-->
<!--                      <img style="width: 45px" src="../../public/sale-off.gif" alt="promotion" />-->
<!--                    </span>-->
<!--                    <span class="sidenav-normal  ms-2  ps-1">-->
<!--                      {{ $t('home.link.orderPromotion') }}-->
<!--                    </span>-->
<!--                  </router-link>-->
<!--                </li>-->
                <!-- Notifications -->
                <li v-if="metaData.isMenuOrderPromotionEnable" :class="{'active': isOrderPromotionScreen, 'nav-item': true}">
                  <router-link
                      :class="{'active': isOrderPromotionScreen, 'nav-link nav-sub-menu text-white': true}"
                      to="/home/order?filter=promotion"
                  >
                    <span class="sidenav-mini-icon"> <i class="fas fa-shopping-cart" /> </span>
                    <span class="position-absolute translate-middle">
                      <img style="width: 40px" src="../../public/sale-off.gif" alt="promotion" />
                     </span>
                    <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.orderPromotion') }}</span>
                  </router-link>
                </li>
                <li :class="{'active': ['Order Detail', 'Order History'].includes(this.$route.meta.name), 'nav-item': true}">
                  <router-link
                    :class="{'active': ['Order Detail', 'Order History'].includes(this.$route.meta.name), 'nav-link': true, 'nav-sub-menu': true, 'text-white': true}"
                    to="/home/order/history"
                  >
                    <span class="sidenav-mini-icon"> <i class="fas fa-file-invoice" /> </span>
                    <span class="sidenav-normal  ms-2  ps-1">{{ $t('home.link.orderHistory') }}</span>
                  </router-link>
                </li>
                <li
                  v-if="metaData.isMenuTopupEnable"
                  :class="{'active': this.$route.meta.name === 'Topup', 'nav-item': true}"
                >
                  <router-link
                    :class="{'active': this.$route.meta.name === 'Topup', 'nav-link': true, 'nav-sub-menu': true, 'text-white': true}"
                    to="/home/order/topup"
                  >
                    <span class="sidenav-mini-icon"> <i class="fas fa-wallet" /> </span>
                    <span class="sidenav-normal  ms-2  ps-1">{{ $t('home.link.topup') }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <hr class="horizontal light mt-0 mb-0">
          <li class="nav-item mt-3">
            <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">
              {{ $t('home.link.services') }}
            </h6>
          </li>
          <!-- Cloud VPS -->
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              href="#cloudvps"
              :class="{'active': this.$route.meta.breadcrumb === 'Cloud VPS', 'nav-link text-white': true}"
              aria-controls="dashboardsExamples"
              role="button"
              aria-expanded="false"
            >
              <i class="material-icons-round opacity-10">&#xe2bd;</i>
              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.vps') }}</span>
            </a>
            <div
              id="cloudvps"
              class="collapse"
            >
              <ul class="nav nav-sm flex-column">
                <li :class="{'active': this.$route.meta.name === 'VPS List', 'nav-item': true}">
                  <router-link
                    :class="{'active': this.$route.meta.name === 'VPS List', 'nav-link': true, 'nav-sub-menu': true, 'text-white': true}"
                    to="/home/cloud-vps"
                  >
                    <span class="sidenav-mini-icon"> <i class="fas fa-list" /> </span>
                    <span class="sidenav-normal  ms-2  ps-1">{{ $t('home.link.listVps') }}</span>
                  </router-link>
                </li>
                <li
                  v-if="metaData.isMenuSnapshotEnable"
                  :class="{'active': this.$route.meta.name === 'VPS Snapshot', 'nav-item': true}"
                >
                  <router-link
                    :class="{'active': this.$route.meta.name === 'VPS Snapshot', 'nav-link': true, 'nav-sub-menu': true, 'text-white': true}"
                    to="/home/cloud-vps/snapshot"
                  >
                    <span class="sidenav-mini-icon"> <i class="fas fa-camera" /> </span>
                    <span class="sidenav-normal  ms-2  ps-1">{{ $t('home.link.snapshot') }}</span>
                  </router-link>
                </li>
                <li
                  v-if="metaData.isMenuBackupEnable"
                  :class="{'active': this.$route.meta.name === 'VPS Backup', 'nav-item': true}"
                >
                  <router-link
                    :class="{'active': this.$route.meta.name === 'VPS Backup', 'nav-link': true, 'nav-sub-menu': true, 'text-white': true}"
                    to="/home/cloud-vps/backup"
                  >
                    <span class="sidenav-mini-icon"> <i class="fas fa-hdd" /> </span>
                    <span class="sidenav-normal  ms-2  ps-1">{{ $t('home.link.backup') }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <!-- Dedicated Server -->
          <li class="nav-item">
            <router-link
              :class="{'active': this.$route.meta.breadcrumb === 'Dedicated Server', 'nav-link': true, 'text-white': true}"
              to="/home/dedicated-server"
            >
              <i class="material-icons-round opacity-10">&#xe1b2;</i>
              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.dedicatedServer') }}</span>
            </router-link>
          </li>
          <!-- Proxy -->
          <li class="nav-item">
            <router-link
              :class="{'active': this.$route.meta.breadcrumb === 'Proxy', 'nav-link': true, 'text-white': true}"
              to="/home/proxy"
            >
              <i class="material-icons-round opacity-10">&#xeb2f;</i>
              <span class="nav-link-text ms-2 ps-1">Proxy</span>
            </router-link>
          </li>
          <!-- Hosting -->
          <li class="nav-item">
            <router-link
                :class="{'active': this.$route.meta.breadcrumb === 'Hosting', 'nav-link': true, 'text-white': true}"
                to="/home/hosting"
            >
              <i class="material-icons-round opacity-10">&#xeb9b;</i>
              <span class="nav-link-text ms-2 ps-1">Hosting</span>
            </router-link>
          </li>
          <!--        VPN-->
          <!--          <li class="nav-item">-->
          <!--            <a-->
          <!--              class="nav-link text-white"-->
          <!--              href="#"-->
          <!--            >-->
          <!--              <i class="material-icons-round opacity-10">privacy_tip</i>-->
          <!--              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.vpn') }}</span>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <hr class="horizontal light mt-0 mb-0">-->

          <!-- Extra -->
          <hr class="horizontal light mt-0 mb-0">
          <li class="nav-item mt-3">
            <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">
              {{ $t('home.link.extra') }}
            </h6>
          </li>

          <!-- Team Manage -->
          <li class="nav-item">
            <router-link
              :class="{'active': this.$route.meta.breadcrumb === 'Team Manager', 'nav-link': true, 'text-white': true}"
              to="/home/team-manager"
            >
              <i class="material-icons-round opacity-10">&#xf233;</i>
              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.teams') }}</span>
            </router-link>
          </li>

          <!-- Other -->
          <hr class="horizontal light mt-0 mb-0">
          <li class="nav-item mt-3">
            <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">
              {{ $t('home.link.others') }}
            </h6>
          </li>

          <!-- Referral -->
          <li class="nav-item">
            <router-link
              :class="{'active': this.$route.meta.breadcrumb === 'Referral', 'nav-link': true, 'text-white': true}"
              to="/home/referral"
            >
              <i class="material-icons-round opacity-10">&#xeaf0;</i>
              <span class="position-absolute translate-middle badge rounded-pill bg-success border border-white small py-1 px-2"/>
              <span class="nav-link-text ms-2 ps-1">Referral</span>
            </router-link>
          </li>

          <!-- Support -->
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              href="#support"
              :class="{'active': this.$route.meta.breadcrumb === 'Support', 'nav-link': true, 'text-white': true}"
              aria-controls="dashboardsExamples"
              role="button"
              aria-expanded="false"
            >
              <i class="material-icons-round opacity-10">&#xf1c0;</i>
              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.support') }}</span>
            </a>
            <div
              id="support"
              class="collapse"
            >
              <ul class="nav nav-sm flex-column">
                <!-- Ticket -->
                <li class="nav-item">
                  <router-link
                      :class="{'active': this.$route.meta.breadcrumb === 'Tickets', 'nav-link nav-sub-menu text-white': true}"
                      to="/home/ticket"
                  >
                    <i class="material-icons-round opacity-10">&#xe53f;</i>
                    <span class="position-absolute translate-middle badge rounded-pill bg-success border border-white small py-1 px-2"/>
                    <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.tickets') }}</span>
                  </router-link>
                </li>
                <!-- Activity Log -->
                <li class="nav-item">
                  <router-link
                      :class="{'active': this.$route.meta.breadcrumb === 'Log', 'nav-link nav-sub-menu text-white': true}"
                      to="/home/log"
                  >
                    <i class="material-icons-round opacity-10">&#xe616;</i>
                    <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.log') }}</span>
                  </router-link>
                </li>
                <!-- Support URL -->
                <li
                  v-for="(s, index) in metaData.support"
                  :key="index"
                  class="nav-item"
                >
                  <a
                    class="nav-link nav-sub-menu text-white"
                    :href="s.url"
                    target="_blank"
                  >
                    <span class="sidenav-mini-icon">
                      <i v-if="s.title.toLowerCase().includes('wiki')" class="fab fa-wikipedia-w" />
                      <i v-else-if="s.title.toLowerCase().includes('facebook')" class="fab fa-facebook-f" />
                      <i v-else class="far fa-dot-circle" />
                    </span>
                    <span class="sidenav-normal  ms-2  ps-1">{{ s.title }}</span>
                  </a>
                </li>
                <!-- API -->
                <li class="nav-item">
                  <a
                      class="nav-link nav-sub-menu text-white"
                      href="https://api2.levnode.com/"
                      target="_blank"
                  >
                    <i class="material-icons-round opacity-10">&#xf1b7;</i>
                    <span class="nav-link-text ms-2 ps-1">API</span>
                  </a>
                </li>
                <!--                <li class="nav-item ">-->
                <!--                  <a-->
                <!--                    class="nav-link text-white nav-sub-menu"-->
                <!--                    href="#"-->
                <!--                  >-->
                <!--                    <span class="sidenav-mini-icon"><i class="fas fa-ticket-alt" /></span>-->
                <!--                    <span class="sidenav-normal ms-2 ps-1">{{ $t('home.link.ticket') }}</span>-->
                <!--                  </a>-->
                <!--                </li>-->
              </ul>
            </div>
          </li>

          <!-- Security -->
          <li class="nav-item">
            <router-link
              :class="{'active': this.$route.meta.breadcrumb === 'Security', 'nav-link': true, 'text-white': true}"
              to="/home/security/2fa"
            >
              <i class="material-icons-round opacity-10">&#xe32a;</i>
              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.security') }}</span>
            </router-link>
          </li>
          <!-- Notifications -->
          <li
            v-if="metaData.isMenuNotiEnable"
            class="nav-item"
          >
            <router-link
              :class="{'active': this.$route.meta.breadcrumb === 'Notification', 'nav-link': true, 'text-white': true}"
              to="/home/notification"
            >
              <i class="material-icons-round opacity-10">&#xe7f4;</i>
              <span
                v-if="notificationObjectList.length > 0"
                class="position-absolute translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2"
              >
                <span class="small">
                  <span>{{ notificationObjectList.length }}&nbsp;</span>
                  <span class="fa fa-bullhorn" />
                </span>
              </span>
              <span class="nav-link-text ms-2 ps-1">{{ $t('home.link.notification') }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </aside>
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg" >
      <!-- Navbar -->
      <nav
        id="navbarBlur"
        class="navbar navbar-main navbar-expand-lg position-sticky mt-4 mb-4 top-1 px-0 mx-4 border-radius-xl z-index-sticky blur shadow-blur navbar-custom"
        data-scroll="true"
      >
        <div class="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
              <li class="breadcrumb-item text-sm">
                <a
                  class="opacity-3 text-dark"
                  href="javascript:;"
                >
                  <svg
                    width="12px"
                    height="12px"
                    class="mb-1"
                    viewBox="0 0 45 40"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>shop </title>
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-1716.000000, -439.000000)"
                        fill="#252f40"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(0.000000, 148.000000)">
                            <path d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z" />
                            <path d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li
                v-for="(item, index) in routeMeta"
                :key="index"
                class="breadcrumb-item text-sm"
              >
                <router-link
                  class="opacity-5 text-dark"
                  :to="item.path"
                >
                  {{ item.breadcrumb }}
                </router-link>
              </li>
              <li
                v-if="this.$route.meta.category != undefined"
                class="breadcrumb-item text-sm text-dark active"
              >
                {{ this.$route.meta.category }}
              </li>
            </ol>
            <h6 class="font-weight-bolder mb-0">
              <span v-html="this.$route.meta.title" />
            </h6>
          </nav>
          <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
            <a
              href="javascript:;"
              class="nav-link text-body p-0"
              @click="sideBarAction"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-black" />
                <i class="sidenav-toggler-line bg-black" />
                <i class="sidenav-toggler-line bg-black" />
              </div>
            </a>
          </div>
          <div
            id="navbar"
            class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
          >
            <div class="ms-auto pe-3 text-bold small">
              <div class="row gap-1 mb-1">
                <span class="col badge badge-pill bg-gradient-primary">
                  <i class="fas fa-wallet" />
                  <span class="px-1">{{ $t('home.link.main_balance') }}:</span>
                  ${{ currentUser['mainBalance']?.toFixed(2) }}
                </span>
                <span class="col badge badge-pill bg-gradient-info">
                  <i class="fas fa-dollar-sign" />
                  <span class="px-1">{{ $t('home.link.promotional_balance') }}:</span>
                  ${{ currentUser['promotionalBalance']?.toFixed(2) }}</span>
                <span class="col badge badge-pill bg-gradient-success">
                  <i class="fas fa-donate" />
                  <span class="px-1">LPOINT:</span>
                  {{ currentUser['lpoint'] }}</span>
              </div>
              <div class="row">
                <span
                  id="exchange-rate-popover"
                  ref="exchange-rate-popover"
                  class="badge badge-pill badge-success w-100 cursor-pointer"
                  data-container="body"
                  data-bs-toggle="popover"
                  data-color="secondary"
                  data-bs-placement="top"
                  :data-bs-html="true"
                  :title="$t('home.link.exchange_rate')"
                  :data-bs-content="$t('home.label.exchange_rate_popover', [startTimeToday, currencyFormat(metaData['exchangeRateBlackMarket'], metaData['defaultCurrency'])])"
                >
                  <i class="fas fa-exchange-alt" />
                  <span class="mx-1">{{ $t('home.link.exchange_rate') }}:</span>
                  $1 = {{ currencyFormat(metaData['exchangeRate'], metaData['defaultCurrency']) }}
                  <small>({{ convertISODate(new Date()) }})</small>
                </span>
              </div>
            </div>
            <div>
              <ul class="navbar-nav  justify-content-end">
                <li class="nav-item dropdown">
                  <a
                    id="dropdownMenuProfile"
                    href="javascript:;"
                    class="nav-link text-body p-0 position-relative"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div>
                      <div class="pb-1 text-center">
                        <span><i class="fas fa-user me-sm-2 fa-lg" /></span>
                        <span><i class="fas fa-chevron-down fa-lg" /></span>
                      </div>
                      <div
                        v-if="currentUser.customerType === 'vip' || currentUser.customerType === 'supervip'"
                        class="badge bg-gradient-violet"
                      >
                        {{ currentUser.customerType }}
                      </div>
                    </div>
                  </a>
                  <ul
                    class="dropdown-menu dropdown-menu-end p-2 me-sm-n4"
                    aria-labelledby="dropdownMenuProfile"
                    style="cursor: default"
                  >
                    <router-link
                      to="/home/profile"
                    >
                      <div class="text-center">
                        <img
                          class="rounded-circle border border-primary shadow-primary home-menu-avatar"
                          src="/assets/img/logo/LowendViet-2.png"
                          alt="avatar-logo"
                        >
                        <strong class="d-block mt-1 text-danger">{{ currentUser.fullName }}</strong>
                        <span
                          v-if="currentUser.email !== null && currentUser.email !== '' && currentUser.email !== undefined"
                          class="small font-italic d-block"
                        >{{ currentUser.email }}</span>
                        <span
                          v-if="currentUser.customerType === 'vip' || currentUser.customerType === 'supervip'"
                          class="badge bg-gradient-violet"
                        >
                          {{ currentUser.customerType }}
                        </span>
                      </div>
                    </router-link>
                    <hr>
                    <li class="mb-2">
                      <a
                        id="displaySettings"
                        class="dropdown-item border-radius-md cursor-pointer"
                        src="javascript:;"
                      >
                        <div class="d-flex align-items-center py-1">
                          <span class="material-icons">&#xe8b8;</span>
                          <div class="ms-2">
                            <h6 class="text-sm font-weight-normal my-auto">
                              {{ $t('home.link.displaySettings') }}
                            </h6>
                          </div>
                        </div>
                      </a>
                    </li>
                    <hr>
                    <li class="mb-2">
                      <router-link
                        class="dropdown-item border-radius-md"
                        to="/home/profile"
                      >
                        <div class="d-flex align-items-center py-1">
                          <span class="material-icons">&#xea67;</span>
                          <div class="ms-2">
                            <h6 class="text-sm font-weight-normal my-auto">
                              {{ $t('home.link.accountDetail') }}
                            </h6>
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li class="mb-2">
                      <router-link
                        class="dropdown-item border-radius-md"
                        to="/home/password"
                      >
                        <div class="d-flex align-items-center py-1">
                          <span class="material-icons">&#xe73c;</span>
                          <div class="ms-2">
                            <h6 class="text-sm font-weight-normal my-auto">
                              {{ $t('home.link.changePassword') }}
                            </h6>
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li class="mb-2">
                      <a
                        class="dropdown-item border-radius-md cursor-pointer"
                        src="javascript:;
"
                        @click="logout"
                      >
                        <div class="d-flex align-items-center py-1">
                          <span class="material-icons">&#xe9ba;</span>
                          <div class="ms-2">
                            <h6 class="text-sm font-weight-normal my-auto">
                              {{ $t('home.link.logout') }}
                            </h6>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                  <a
                    id="iconNavbarSidenav"
                    href="javascript:;"
                    class="nav-link text-body p-0"
                    @click="sideBarAction"
                  >
                    <div class="sidenav-toggler-inner">
                      <i class="sidenav-toggler-line bg-black" />
                      <i class="sidenav-toggler-line bg-black" />
                      <i class="sidenav-toggler-line bg-black" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
      <div
        class="container-fluid py-4 custom-body"
        style="position: relative"
      >
        <div>
        <div
          v-if="notificationHighlightObjectList.length !== 0"
          class="pb-3 text-center"
        >
          <div
            v-for="notification in notificationHighlightObjectList"
            :key="notification.id"
          >
            <a
              href="javascript:;"
              class="rainbow rainbow_text_animated cursor-pointer"
              @click="showNotificationModal(notification)"
            >
              <i class="fas fa-bullhorn rainbow fa-lg text-danger" />
              <span>
                {{ notification.title }}
              </span>
            </a>
          </div>
        </div>
        <div class="row content-body">
          <router-view
            :key="$route.fullPath"
            ref="test"
            :current-user="currentUser"
            :theme-setting="themeSetting"
            :notifications="notificationObjectList"
            :meta-data="metaData"
            :language-selected="languageSelected"
            @loginEvent="forwardLoginEvent"
          />
        </div>
        </div>
        <footer class="footer">
          <div
            class="container-fluid card"
          >
            <div
              class="row card-body align-items-center justify-content-lg-between"
            >
              <div class="col-lg-6 mb-lg-0 mb-4">
                <div class="copyright text-center text-sm text-muted text-lg-start">
                  <label v-html="metaData.footNote" />
                </div>
              </div>
              <div class="col-lg-6 justify-content-end">
                <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                  <li class="nav-item">
                    <a
                      href="https://fb.com/LowendVietServer"
                      class="nav-link text-muted"
                      target="_blank"
                    ><label class="cursor-pointer"><i class="fab fa-facebook-square" /> Facebook</label></a>
                  </li>
                  <li class="nav-item">
                    <div class="dropup">
                      <a
                        id="navbarDropdownMenuLink2"
                        href="javascript:;"
                        class="dropdown-toggle nav-link pe-0"
                        data-bs-toggle="dropdown"
                      >
                        <label class="fa fa-fw fa-globe cursor-pointer" /><label class="cursor-pointer">Language&nbsp;</label>
                        <flag
                          :iso="this.$i18n.locale.slice(-2)"
                          :squared="false"
                        />
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink2"
                      >
                        <li>
                          <div
                            class="dropdown-item"
                            aria-labelledby="language"
                          >
                            <span
                              v-for="(lang, i) in metaData.availableLang"
                              :key="i"
                            >
                              <span
                                class="dropdown-item"
                                @click="changeLocale(lang['locale'])"
                              >
                                <span class="">
                                  <flag
                                    :iso="lang['locale'].slice(-2)"
                                    :squared="false"
                                  />
                                </span> {{ lang['description'] }}
                              </span>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </main>
    <!-- Plugin Setting Modal -->
    <div
      id="fixed-plugin"
      class="fixed-plugin"
    >
      <a
        id="pluginSettings"
        class="fixed-plugin-button position-fixed px-3 py-2"
      >
        <i class="material-icons py-2">&#xe8b8;</i>
      </a>
      <div class="card shadow-lg">
        <div class="card-header pb-0 pt-3">
          <div class="float-start">
            <h5 class="mt-3 mb-0">
              {{ $t('home.link.settingTitle') }}
            </h5>
            <p>{{ $t('home.link.settingDescription') }}</p>
          </div>
          <div class="float-end mt-4">
            <button
              id="closePluginSettings"
              class="btn btn-link text-dark p-0 fixed-plugin-close-button"
            >
              <i class="material-icons">&#xe5cd;</i>
            </button>
          </div>
          <!-- End Toggle Button -->
        </div>
        <hr class="horizontal dark my-1">
        <div class="card-body pt-sm-3 pt-0 overflow-auto">
          <!-- Sidenav Type -->
          <div class="mt-3">
            <h6 class="mb-0">
              {{ $t('home.link.sidenavType') }}
            </h6>
            <p class="text-sm">
              {{ $t('home.link.sidenavTypeDescription') }}
            </p>
          </div>
          <div class="d-flex">
            <button
              id="sidebarTypeDark"
              class="btn bg-gradient-dark px-3 mb-2"
              data-class="bg-gradient-dark"
              @click="updateSidebarType($event.target.id)"
            >
              {{ $t('home.link.sidenavDark') }}
            </button>
            <button
              id="sidebarTypeTransparent"
              class="btn bg-gradient-dark px-3 mb-2 ms-2"
              data-class="bg-transparent"
              @click="updateSidebarType($event.target.id)"
            >
              {{ $t('home.link.sidenavTransparent') }}
            </button>
            <!--            <button-->
            <!--              id="sidebarTypeWhite"-->
            <!--              class="btn bg-gradient-dark px-3 mb-2 ms-2"-->
            <!--              data-class="bg-white"-->
            <!--              @click="updateSidebarType($event.target.id)"-->
            <!--            >-->
            <!--              {{ $t('home.link.sidenavWhite') }}-->
            <!--            </button>-->
          </div>
          <p class="text-sm d-xl-none d-block mt-2">
            {{ $t('home.link.sidenavNotice') }}
          </p>
          <!-- Navbar Fixed -->
          <div class="mt-3 d-flex">
            <h6 class="mb-0">
              {{ $t('home.link.navbarFixed') }}
            </h6>
            <div class="form-check form-switch ps-0 ms-auto my-auto">
              <input
                id="navbarFixed"
                class="form-check-input mt-1 ms-auto"
                type="checkbox"
                :checked="themeSetting.isNavbarFixed"
                @click="updateNavbarFixed($event.target.checked)"
              >
            </div>
          </div>
          <hr class="horizontal dark my-3">
          <div class="mt-2 d-flex">
            <h6 class="mb-0">
              {{ $t('home.link.lightdark') }}
            </h6>
            <div class="form-check form-switch ps-0 ms-auto my-auto">
              <input
                id="dark-version"
                class="form-check-input mt-1 ms-auto"
                type="checkbox"
                :checked="themeSetting.isDarkTheme"
                @click="updateDarkMode($event.target.checked)"
              >
            </div>
          </div>
          <hr class="horizontal dark my-sm-4">
          <!-- VPS Table -->
          <div
            v-if="this.$route.meta.name === 'VPS List'"
            name="vpsTableSetting"
          >
            <div class="mt-3 d-flex">
              <h6 class="mb-0">
                {{ $t('home.link.vpsTableSettings') }}
              </h6>
            </div>
            <div class="ms-3">
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-ethernet menu-icon" /> IP
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsIpCheckbox"
                    name="ip_port"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.ip_port.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-user menu-icon" /> {{ this.$t('vps.table.thUsername') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsUserCheckbox"
                    name="login_user"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.login_user.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-key menu-icon" /> {{ this.$t('vps.table.thPassword') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsPasswordCheckbox"
                    name="login_password"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.login_password.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-dollar-sign menu-icon" /> {{ this.$t('vps.table.thPrice') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsPriceCheckbox"
                    name="price"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.price.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fab fa-hive menu-icon" /> {{ this.$t('vps.table.thPlan') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsPlanCheckbox"
                    name="vm_plan_name"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.vm_plan_name.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-map-marker menu-icon" /> {{ this.$t('vps.table.thLocation') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsLocationCheckbox"
                    name="location"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.location.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="far fa-calendar-times menu-icon" /> {{ this.$t('vps.table.thStart') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsStartCheckbox"
                    name="start_date"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.start_date.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="far fa-calendar-times menu-icon" /> {{ this.$t('vps.table.thEnd') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsEndCheckbox"
                    name="end_date"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.end_date.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-hammer menu-icon" /> {{ this.$t('vps.table.thBackup') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsBackupCheckbox"
                    name="backup_period"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.backup_period.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-retweet menu-icon" /> {{ this.$t('vps.table.thAutoRenew') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsAutoRenewCheckbox"
                    name="auto_renew"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.auto_renew.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="far fa-sticky-note menu-icon" /> {{ this.$t('vps.table.thNote') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsRemarkCheckbox"
                    name="user_remark"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.user_remark.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-file-invoice-dollar menu-icon" /> {{ this.$t('vps.table.thPay') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsPaymentCheckbox"
                    name="payment_status"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.payment_status.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-sync menu-icon" /> {{ this.$t('vps.table.thStatus') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="vpsStatusCheckbox"
                    name="operation_status"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.vpsTableSetting.operation_status.isVisible"
                    @click="updateVPSTableSetting($event)"
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Dedicated Server Table -->
          <div
            v-if="this.$route.meta.name === 'Dedicated Server List'"
            name="DedicatedServerTableSetting"
          >
            <div class="mt-3 d-flex">
              <h6 class="mb-0">
                {{ $t('home.link.dedicatedServerTableSettings') }}
              </h6>
            </div>
            <div class="ms-3 small">
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-ethernet menu-icon" /> IP
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedIpCheckbox"
                    name="ip_port"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.ip_port.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-user menu-icon" /> {{ this.$t('dedicated.table.thUsername') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedUserCheckbox"
                    name="login_user"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.login_user.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-key menu-icon" /> {{ this.$t('dedicated.table.thPassword') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedPasswordCheckbox"
                    name="login_password"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.login_password.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-dollar-sign menu-icon" /> {{ this.$t('dedicated.table.thPrice') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedPriceCheckbox"
                    name="price"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.price.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fab fa-hive menu-icon" /> {{ this.$t('dedicated.table.thPlan') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedPlanCheckbox"
                    name="dedicated_plan_name"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.dedicated_plan_name.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-map-marker menu-icon" /> {{ this.$t('vps.table.thLocation') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedLocationCheckbox"
                    name="location"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.location.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="far fa-calendar-times menu-icon" /> {{ this.$t('vps.table.thStart') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedStartCheckbox"
                    name="start_date"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.start_date.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="far fa-calendar-times menu-icon" /> {{ this.$t('vps.table.thEnd') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedEndCheckbox"
                    name="end_date"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.end_date.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-retweet menu-icon" /> {{ this.$t('vps.table.thAutoRenew') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedAutoRenewCheckbox"
                    name="auto_renew"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.auto_renew.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="far fa-sticky-note menu-icon" /> {{ this.$t('vps.table.thNote') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedRemarkCheckbox"
                    name="user_remark"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.user_remark.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
              <div class="d-flex">
                <h6 class="mb-0 text-uppercase small">
                  <i class="fas fa-file-invoice-dollar menu-icon" /> {{ this.$t('vps.table.thPay') }}
                </h6>
                <div class="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    id="dedicatedPaymentCheckbox"
                    name="payment_status"
                    class="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    :checked="themeSetting.dedicatedTableSetting.payment_status.isVisible"
                    @click="updateDedicatedTableSetting($event)"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      :action="action"
      :active="active"
      :confirm-modal-display-object="confirmModalDisplayObject"
      @modalCancel="finishModal"
      @modalConfirm="onConfirmButton"
      @modalAdditionalButton="onModalAdditionalButton"
      @finishAction="finishAction"
    />
    <lev-modal
      v-for="(notification, index) in notificationPopupList"
      :id="`notification_${notification['id']}`"
      :key="index"
      :title="notification['title']"
      @modalConfirm="updateNotification(notification)"
    >
      <template #title>
        <div class="d-flex d-inline align-items-center">
          <i class="decorate-icon fa fa-bullhorn fa-3x text-info me-3" />
          <div>
            <h4 class="m-0 text-primary">
              {{ notification['title'] }}
            </h4>
            <span>{{ convertISOTime(notification['updated_at']) }}</span>
          </div>
        </div>
      </template>
      <template #left-footer>
        <div class="form-check p-0">
          <input
            v-model="notificationPopupHide"
            type="checkbox"
            class="form-check-input text-bolder text-danger"
          >
          <label class="custom-control-label text-bolder text-danger">{{ $t('general.label.not_show_again') }}</label>
        </div>
      </template>
      <div
        class="overflow-auto"
        style="height: 550px;"
        v-html="notification['content']"
      />
    </lev-modal>
    <lev-modal
      id="notification-highlight-modal"
      :title="notificationHighLightObjectSelected['title']"
      @modalConfirm="updateNotification(notificationHighLightObjectSelected)"
    >
      <template #title>
        <div class="d-flex d-inline align-items-center">
          <i class="decorate-icon fa fa-bullhorn fa-3x text-info me-3" />
          <div>
            <h4 class="m-0 text-primary">
              {{ notificationHighLightObjectSelected['title'] }}
            </h4>
            <span>{{ convertISOTime(notificationHighLightObjectSelected['updated_at']) }}</span>
          </div>
        </div>
      </template>
      <div
        class="overflow-auto"
        v-html="notificationHighLightObjectSelected['content']"
      />
    </lev-modal>
  </div>
</template>

<script>

import dataRequester from '../mixins/utilities/DataRequester'
import {activedRoutes} from "@/main"
import { FixBugTheme } from '@/mixins/MaterialTheme';
import async from "async";
import confirmModal from '@/components/children/ConfirmModal'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'
import inputHelper from "@/mixins/utilities/InputHelper";
import moment from 'moment'
import LevModal from "@/components/children/lev_components/LevModal.vue";

export default {
  name: "Home",
  components: {
    LevModal,
    confirmModal
  },
  mixins: [dataRequester, confirmModalBuilder, inputHelper],
  props: {
    currentUser: {
      default: function () { return {} },
      type: Object
    }
  },
  data: function () {
    return {
      metaData: {
        logoPath: '',
        siteName: '',
        siteTicker: '',
        contactWebsite: '',
        contactEmail: '',
        contactPhone: '',
        footNote: '',
        support: [],
        tosURL: '',
        slaURL: '',
        privacyURL: '',
        isMenuTopupEnable: false,
        isMenuNotiEnable: false,
        isMenuSnapshotEnable: false,
        isMenuBackupEnable: false,
        isMenuAPIEnable: false
      },
      notificationObjectList: [],
      notificationHighLightObjectSelected: {},
      routeMeta: {},
      updateUserInfoInterval: {},
      themeSetting: {},
      languageSelected: 'en',
      startTimeToday: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      notificationPopupHide: false
    }
  },
  computed: {
    notificationHighlightObjectList() {
      return this.notificationObjectList.filter(x => x['is_highlight'] === 1)
    },
    notificationPopupList() {
      let self = this
      let localStorageKey = `noti_${this.currentUser['userID']}`
      let notiIdString = localStorage.getItem(localStorageKey)
      if ([null, undefined].includes(notiIdString)) {
        return this.notificationObjectList.filter(x => x['is_popup'] === 1)
      } else {
        let notiIds = JSON.parse(notiIdString)
        return this.notificationObjectList.filter(x => x['is_popup'] === 1 && !notiIds.includes(x['id']))
      }
    },
    isOrderScreen() {
      return this.$route.meta['name'] === 'New Order' && this.$route.query['filter'] !== 'promotion'
    },
    isOrderPromotionScreen() {
      return this.$route.meta['name'] === 'New Order' && this.$route.query['filter'] === 'promotion'
    },
  },
  mounted: function () {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    window.addEventListener('scroll', this.handleScroll);

    this.themeSetting = JSON.parse(this.getLocalStorageData())

    if (window.document.cookie.indexOf('username') > -1 && window.document.cookie.indexOf('sessionId') > -1) {
      self.updateCurrentUserInfo()
      self.updateUserInfoInterval = setInterval(async function () {
        try {
          await self.updateCurrentUserInfo()
        } catch (error) {
          console.error("Error occurred while updating user info:", error)
          clearInterval(self.updateUserInfoInterval)
          console.log("Interval has been stopped due to an error.")
          self.logout()
        }
      }, 1000 * 60)
    }

    window.addEventListener('resize', function () {
      let sidebar = document.querySelectorAll('#sidenav-main')[0]
      let mainLogo = document.getElementById('main_logo')
      if(window.innerWidth < 1500) {
        if(!sidebar.parentElement.classList.contains('g-sidenav-hidden')) {
          sidebar.parentElement.classList.remove('g-sidenav-pinned')
          sidebar.parentElement.classList.add('g-sidenav-hidden')
          mainLogo.src = "../../assets/img/logo/LowendViet-small.png"
        }
      } else {
        if(sidebar.parentElement.classList.contains('g-sidenav-hidden')) {
          sidebar.parentElement.classList.add('g-sidenav-pinned')
          sidebar.parentElement.classList.remove('g-sidenav-hidden')
          mainLogo.src = self.metaData.logoPath
        }
      }
    })

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
      this.languageSelected = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
        this.languageSelected = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }
    this.getDataFromAPI(self.$config.apiPath.notiList, function (result) {
      self.notificationObjectList = result.data
      setTimeout(function () {
        self.popupNoification()
      }, 3000)
    })
    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.siteTicker = metaData.domainObjectList['site_ticker']
      self.metaData.contactWebsite = metaData.domainObjectList['contact_website']
      self.metaData.contactEmail = metaData.domainObjectList['contact_email']
      self.metaData.contactPhone = metaData.domainObjectList['contact_phone']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
      self.metaData.support = JSON.parse(metaData.domainObjectList['support'])
      self.metaData.tosURL = metaData.domainObjectList['tos_url']
      self.metaData.slaURL = metaData.domainObjectList['sla_url']
      self.metaData.privacyURL = metaData.domainObjectList['privacy_url']
      self.metaData.isMenuTopupEnable = metaData.domainObjectList['is_menu_topup_enable']
      self.metaData.isMenuNotiEnable = metaData.domainObjectList['is_menu_noti_enable']
      self.metaData.isMenuSnapshotEnable = metaData.domainObjectList['is_menu_snapshot_enable']
      self.metaData.isMenuBackupEnable = metaData.domainObjectList['is_menu_backup_enable']
      self.metaData.isMenuAPIEnable = metaData.domainObjectList['is_menu_api_enable']
      self.metaData.isMenuOrderPromotionEnable = metaData.domainObjectList['is_menu_order_promotion_enable']
      self.metaData.defaultCurrency = metaData.domainObjectList['default_currency']
      self.metaData.exchangeRate = metaData.domainObjectList['exchange_rate']
      self.metaData['exchangeRateBlackMarket'] = metaData.domainObjectList['exchange_rate_black_market']

      const popoverElement = document.getElementById('exchange-rate-popover'); // Thay đổi bằng selector đúng của bạn
      const popoverInstance = bootstrap.Popover.getInstance(popoverElement);
      // Cập nhật nội dung mới
      popoverElement.setAttribute('data-bs-content', self.$t('home.label.exchange_rate_popover', [self.startTimeToday, self.currencyFormat(self.metaData['exchangeRateBlackMarket'], self.metaData['defaultCurrency'])]));
      // Hủy Popover hiện tại
      if (popoverInstance) {
        popoverInstance.dispose();
      }
      // Khởi tạo lại Popover với nội dung mới
      const newPopoverInstance = new bootstrap.Popover(popoverElement);
      let popover = new bootstrap.Popover(popoverElement, {
        trigger: 'manual'
      });
      popoverElement.addEventListener('mouseenter', () => {
        popover.show();
      });
      popoverElement.addEventListener('mouseleave', () => {
        popover.hide();
      });

      // $('.dropdown').hover(function () {
      //   $('.dropdown-toggle', this).trigger('click')
      }, function () {
        self.$toasted.error(self.$t('domain.toast.errMetaData'))
      })
  },
  beforeUpdate: function() {
    this.routeMeta = activedRoutes.map(e => e.meta).filter(e => e)

    document.getElementById("pluginSettings").addEventListener('click', function () {
      this.parentElement.classList.add('show')
    })
    document.getElementById("displaySettings").addEventListener('click', function () {
      document.getElementById("pluginSettings").parentElement.classList.add('show')
    })
    document.getElementById("closePluginSettings").addEventListener('click', function () {
      document.getElementById("pluginSettings").parentElement.classList.remove('show')
    })

    this.themeSetting = JSON.parse(this.getLocalStorageData())
    this.sidebarTypeOnload()
    this.updateNavbarFixed(this.themeSetting.isNavbarFixed)
    this.updateDarkMode(this.themeSetting.isDarkTheme)
  },
  updated() {
    document.getElementById('breadcrumb').textContent = this.$t(this.$route.meta['i18n'])
    document.title = this.$t(this.$route.meta['i18n']) + ' - Cloud Service Manager';
    this.updateDarkMode(this.themeSetting.isDarkTheme)

    let sidebar = document.querySelectorAll('#sidenav-main')[0]
    let mainLogo = document.getElementById('main_logo')
    if(window.innerWidth < 1500) {
      if(!sidebar.parentElement.classList.contains('g-sidenav-hidden')) {
        sidebar.parentElement.classList.remove('g-sidenav-pinned')
        sidebar.parentElement.classList.add('g-sidenav-hidden')
        mainLogo.src = "../../assets/img/logo/LowendViet-small.png"
      }
    }
  },
  methods: {
    FixBugTheme,
    handleScroll: function() {
      //set top bar with style top = 0 when scroll
      let topBar = document.getElementById('navbarBlur')
      if(window.scrollY > 0) {
        topBar.setAttribute('style', 'transform: translateY(-15px);')
      } else {
        topBar.setAttribute('style', 'transform: translateY(0);')
      }
    },
    logout: function () {
      clearInterval(this.updateUserInfoInterval)
      this.$cookies.remove('username')
      this.$cookies.remove('sessionId')
      this.$cookies.remove('email')
      this.$router.push('/login')
    },
    changeLocale: function (locale) {
      this.languageSelected = locale
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    },
    forwardLoginEvent: function (userObject) {
      this.$emit('loginEvent', userObject)
    },
    sideBarAction: function () {
      let sidebar = document.querySelectorAll('#sidenav-main')[0]
      // let navbar = document.getElementById('navbarMinimize')
      let mainLogo = document.getElementById('main_logo')
      if(sidebar.parentElement.classList.contains('g-sidenav-hidden')) {
        sidebar.parentElement.classList.remove('g-sidenav-hidden')
        sidebar.parentElement.classList.add('g-sidenav-pinned')
        mainLogo.src = this.metaData.logoPath
        // navbar.removeAttribute("checked");
      }
      else {
        sidebar.parentElement.classList.remove('g-sidenav-pinned')
        sidebar.parentElement.classList.add('g-sidenav-hidden')
        mainLogo.src = "../../assets/img/logo/LowendViet-small.png"
        // navbar.setAttribute("checked", "true");
      }
    },
    getLocalStorageData: function () {
      if(localStorage.getItem(this.currentUser.userID) === null) {
        let objectData = {
          sidebarType: 0,
          isDarkTheme: false,
          isNavbarFixed: false,
          vpsTableSetting: {
            start_date: {
              isVisible: false,
              isSortable: true
            },
            end_date: {
              isVisible: true,
              isSortable: true
            },
            ip_port: {
              isVisible: true,
              isSortable: true
            },
            login_user: {
              isVisible: true,
              isSortable: true
            },
            login_password: {
              isVisible: true,
              isSortable: true
            },
            price: {
              isVisible: true,
              isSortable: true
            },
            vm_plan_name: {
              isVisible: true,
              isSortable: true
            },
            location: {
              isVisible: true,
              isSortable: true
            },
            backup_period: {
              isVisible: false,
              isSortable: true
            },
            auto_renew: {
              isVisible: false,
              isSortable: true
            },
            user_remark: {
              isVisible: true,
              isSortable: true
            },
            payment_status: {
              isVisible: true,
              isSortable: true
            },
            operation_status: {
              isVisible: true,
              isSortable: true
            }
          },
          dedicatedTableSetting: {
            start_date: {
              isVisible: false,
              isSortable: true
            },
            end_date: {
              isVisible: true,
              isSortable: true
            },
            ip_port: {
              isVisible: true,
              isSortable: true
            },
            login_user: {
              isVisible: true,
              isSortable: true
            },
            login_password: {
              isVisible: true,
              isSortable: true
            },
            price: {
              isVisible: true,
              isSortable: true
            },
            dedicated_plan_name: {
              isVisible: true,
              isSortable: true
            },
            location: {
              isVisible: false,
              isSortable: true
            },
            auto_renew: {
              isVisible: false,
              isSortable: true
            },
            user_remark: {
              isVisible: true,
              isSortable: true
            },
            payment_status: {
              isVisible: true,
              isSortable: true
            }
          }
        }
        localStorage.setItem(this.currentUser.userID, JSON.stringify(objectData))
      }
      return localStorage.getItem(this.currentUser.userID)
    },
    updateVPSTableSetting: function (event) {
      this.themeSetting.vpsTableSetting[event.target.name].isVisible = event.target.checked
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    updateDedicatedTableSetting: function (event) {
      this.themeSetting.dedicatedTableSetting[event.target.name].isVisible = event.target.checked
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    updateDarkMode: function (isDarkMode) {

      const body = document.getElementsByTagName('body')[0];
      const modal_content = document.getElementById('modal-content');
      const hr = document.querySelectorAll('div:not(.sidenav) > hr');
      const hr_card = document.querySelectorAll('div:not(.bg-gradient-dark) hr');
      const text_btn = document.querySelectorAll('button:not(.btn) > .text-dark');
      const text_span = document.querySelectorAll('span.text-dark, .breadcrumb .text-dark');
      const text_span_white = document.querySelectorAll('span.text-white, .breadcrumb .text-white');
      const text_strong = document.querySelectorAll('strong.text-dark');
      const text_strong_white = document.querySelectorAll('strong.text-white');
      const text_nav_link = document.querySelectorAll('a.nav-link.text-dark');
      const text_nav_link_white = document.querySelectorAll('a.nav-link.text-white');
      const secondary = document.querySelectorAll('.text-secondary');
      const bg_gray_100 = document.querySelectorAll('.bg-gray-100');
      const bg_gray_600 = document.querySelectorAll('.bg-gray-600');
      const btn_text_dark = document.querySelectorAll('.btn.btn-link.text-dark, .material-icons.text-dark');
      const btn_text_white = document.querySelectorAll('.btn.btn-link.text-white, .material-icons.text-white');
      const card_border = document.querySelectorAll('.card.border');
      const card_border_dark = document.querySelectorAll('.card.border.border-dark');
      const mainContent_blur = document.querySelectorAll('.main-content .container-fluid .card');
      const text_dark = document.querySelectorAll('.text-dark');
      const text_light = document.querySelectorAll('.text-light');
      const bg_black = document.querySelectorAll('.bg-black');
      const bg_white = document.querySelectorAll('.bg-white');
      const svg = document.querySelectorAll('g');

      if (isDarkMode) {
        body.classList.add('dark-version');
        if (modal_content !== undefined && modal_content !== null) {
          modal_content.classList.add('dark-version');
        }
        for (var i = 0; i < text_dark.length; i++) {
          if (text_dark[i].classList.contains('text-dark')) {
            text_dark[i].classList.remove('text-dark');
            text_dark[i].classList.add('text-light');
          }
        }
        for (var i = 0; i < bg_black.length; i++) {
          if (bg_black[i].classList.contains('bg-black')) {
            bg_black[i].classList.remove('bg-black');
            bg_black[i].classList.add('bg-white');
          }
        }
        for (var i = 0; i < hr.length; i++) {
          if (hr[i].classList.contains('dark')) {
            hr[i].classList.remove('dark');
            hr[i].classList.add('light');
          }
        }
        for (var i = 0; i < mainContent_blur.length; i++) {
          if (mainContent_blur[i].classList.contains('blur')) {
            mainContent_blur[i].classList.remove('blur', 'shadow-blur');
          }
        }
        for (var i = 0; i < hr_card.length; i++) {
          if (hr_card[i].classList.contains('dark')) {
            hr_card[i].classList.remove('dark');
            hr_card[i].classList.add('light');
          }
        }
        for (var i = 0; i < text_btn.length; i++) {
          if (text_btn[i].classList.contains('text-dark')) {
            text_btn[i].classList.remove('text-dark');
            text_btn[i].classList.add('text-white');
          }
        }
        for (var i = 0; i < text_span.length; i++) {
          if (text_span[i].classList.contains('text-dark')) {
            text_span[i].classList.remove('text-dark');
            text_span[i].classList.add('text-white');
          }
        }
        for (var i = 0; i < text_strong.length; i++) {
          if (text_strong[i].classList.contains('text-dark')) {
            text_strong[i].classList.remove('text-dark');
            text_strong[i].classList.add('text-white');
          }
        }
        for (var i = 0; i < text_nav_link.length; i++) {
          if (text_nav_link[i].classList.contains('text-dark')) {
            text_nav_link[i].classList.remove('text-dark');
            text_nav_link[i].classList.add('text-white');
          }
        }
        for (var i = 0; i < secondary.length; i++) {
          if (secondary[i].classList.contains('text-secondary')) {
            secondary[i].classList.remove('text-secondary');
            secondary[i].classList.add('text-white');
            secondary[i].classList.add('opacity-8');
          }
        }
        for (var i = 0; i < bg_gray_100.length; i++) {
          if (bg_gray_100[i].classList.contains('bg-gray-100')) {
            bg_gray_100[i].classList.remove('bg-gray-100');
            bg_gray_100[i].classList.add('bg-gray-600');
          }
        }
        for (var i = 0; i < btn_text_dark.length; i++) {
          btn_text_dark[i].classList.remove('text-dark');
          btn_text_dark[i].classList.add('text-white');
        }
        for (var i = 0; i < svg.length; i++) {
          if (svg[i].hasAttribute('fill')) {
            svg[i].setAttribute('fill', '#fff');
          }
        }
        for (var i = 0; i < card_border.length; i++) {
          card_border[i].classList.add('border-dark');
        }
      } else {
        body.classList.remove('dark-version');
        if (modal_content !== undefined && modal_content !== null) {
          modal_content.classList.remove('dark-version');
        }
        for (var i = 0; i < text_light.length; i++) {
          if (text_light[i].classList.contains('text-light')) {
            text_light[i].classList.remove('text-light');
            text_light[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < bg_white.length; i++) {
          if (bg_white[i].classList.contains('bg-white')) {
            bg_white[i].classList.remove('bg-white');
            bg_white[i].classList.add('bg-black');
          }
        }
        for (var i = 0; i < hr.length; i++) {
          // if (hr[i].classList.contains('light')) {
          //   hr[i].classList.add('dark');
          //   hr[i].classList.remove('light');
          // }
        }
        for (var i = 0; i < hr_card.length; i++) {
          // if (hr_card[i].classList.contains('light')) {
          //   hr_card[i].classList.add('dark');
          //   hr_card[i].classList.remove('light');
          // }
        }
        // for (var i = 0; i < mainContent_blur.length; i++) {
        //   mainContent_blur[i].classList.add('blur', 'shadow-blur');
        // }
        for (var i = 0; i < text_btn.length; i++) {
          if (text_btn[i].classList.contains('text-white')) {
            text_btn[i].classList.remove('text-white');
            text_btn[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < text_span_white.length; i++) {
          if (text_span_white[i].classList.contains('text-white') && !text_span_white[i].closest('.sidenav') && !text_span_white[i].closest('.card.bg-gradient-dark')) {
            text_span_white[i].classList.remove('text-white');
            text_span_white[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < text_strong_white.length; i++) {
          if (text_strong_white[i].classList.contains('text-white')) {
            text_strong_white[i].classList.remove('text-white');
            text_strong_white[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < text_nav_link_white.length; i++) {
          if (text_nav_link_white[i].classList.contains('text-white') && !text_nav_link_white[i].closest('.sidenav')) {
            text_nav_link_white[i].classList.remove('text-white');
            text_nav_link_white[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < secondary.length; i++) {
          if (secondary[i].classList.contains('text-white')) {
            secondary[i].classList.remove('text-white');
            secondary[i].classList.remove('opacity-8');
            secondary[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < bg_gray_600.length; i++) {
          if (bg_gray_600[i].classList.contains('bg-gray-600')) {
            bg_gray_600[i].classList.remove('bg-gray-600');
            bg_gray_600[i].classList.add('bg-gray-100');
          }
        }
        for (var i = 0; i < svg.length; i++) {
          if (svg[i].hasAttribute('fill')) {
            svg[i].setAttribute('fill', '#252f40');
          }
        }
        for (var i = 0; i < btn_text_white.length; i++) {
          if (!btn_text_white[i].closest('.card.bg-gradient-dark')) {
            btn_text_white[i].classList.remove('text-white');
            btn_text_white[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < card_border_dark.length; i++) {
          card_border_dark[i].classList.remove('border-dark');
        }
      }

      this.themeSetting.isDarkTheme = isDarkMode
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    updateNavbarFixed: function (isFixed) {
      let classes = ['position-sticky', 'blur', 'shadow-blur', 'mt-4', 'left-auto', 'top-1', 'z-index-sticky'];
      const navbar = document.getElementById('navbarBlur');

      if (isFixed) {
        navbar.classList.add(...classes);
        navbar.setAttribute('data-scroll', 'true');
        navbarBlurOnScroll('navbarBlur');
      } else {
        navbar.classList.remove(...classes);
        navbar.setAttribute('data-scroll', 'false');
        navbarBlurOnScroll('navbarBlur');
      }

      this.themeSetting.isNavbarFixed = isFixed
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    updateSidebarType: function (typeId) {
      let a = document.getElementById(typeId)
      var parent = a.parentElement.children;
      var color = a.getAttribute("data-class");
      var body = document.querySelector("body");
      var bodyWhite = document.querySelector("body:not(.dark-version)");
      var bodyDark = body.classList.contains('dark-version');

      var colors = [];

      for (var i = 0; i < parent.length; i++) {
        parent[i].classList.remove('active');
        colors.push(parent[i].getAttribute('data-class'));
      }

      if (!a.classList.contains('active')) {
        a.classList.add('active');
      } else {
        a.classList.remove('active');
      }

      var sidebar = document.querySelector('.sidenav');

      for (var i = 0; i < colors.length; i++) {
        sidebar.classList.remove(colors[i]);
      }

      sidebar.classList.add(color);


      // Remove text-white/text-dark classes
      if (color == 'bg-transparent' || color == 'bg-white') {
        var textWhites = document.querySelectorAll('.sidenav .text-white');
        for (let i = 0; i < textWhites.length; i++) {
          textWhites[i].classList.remove('text-white');
          textWhites[i].classList.add('text-dark');
        }
      } else {
        var textDarks = document.querySelectorAll('.sidenav .text-dark');
        for (let i = 0; i < textDarks.length; i++) {
          textDarks[i].classList.add('text-white');
          textDarks[i].classList.remove('text-dark');
        }
      }

      if (color == 'bg-transparent' && bodyDark) {
        var textDarks = document.querySelectorAll('.navbar-brand .text-dark');
        for (let i = 0; i < textDarks.length; i++) {
          textDarks[i].classList.add('text-light');
          textDarks[i].classList.remove('text-dark');
        }
      }

      // Remove logo-white/logo-dark

      if ((color == 'bg-transparent' || color == 'bg-white') && bodyWhite) {
        var navbarBrand = document.querySelector('.navbar-brand-img');
        var navbarBrandImg = navbarBrand.src;

        if (navbarBrandImg.includes('logo-ct.png')) {
          var navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
          navbarBrand.src = navbarBrandImgNew;
        }
      } else {
        var navbarBrand = document.querySelector('.navbar-brand-img');
        var navbarBrandImg = navbarBrand.src;
        if (navbarBrandImg.includes('logo-ct-dark.png')) {
          var navbarBrandImgNew = navbarBrandImg.replace("logo-ct-dark", "logo-ct");
          navbarBrand.src = navbarBrandImgNew;
        }
      }

      if (color == 'bg-white' && bodyDark) {
        var navbarBrand = document.querySelector('.navbar-brand-img');
        var navbarBrandImg = navbarBrand.src;

        if (navbarBrandImg.includes('logo-ct.png')) {
          var navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
          navbarBrand.src = navbarBrandImgNew;
        }
      }

      switch (typeId) {
        case 'sidebarTypeDark':
          this.themeSetting.sidebarType = 0
          break
        case 'sidebarTypeTransparent':
          this.themeSetting.sidebarType = 1
          break
        case 'sidebarTypeWhite':
          this.themeSetting.sidebarType = 2
          break
        default:
          break
      }
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    sidebarTypeOnload: function () {
      switch (this.themeSetting.sidebarType) {
        case 0:
          document.getElementById('sidebarTypeDark').classList.add('active')
          this.updateSidebarType('sidebarTypeDark')
          break
        case 1:
          document.getElementById('sidebarTypeTransparent').classList.add('active')
          this.updateSidebarType('sidebarTypeTransparent')
          break
        case 2:
          document.getElementById('sidebarTypeWhite').classList.add('active')
          this.updateSidebarType('sidebarTypeWhite')
          break
        default:
          break
      }
    },
    popupNoification: function () {
      this.notificationPopupList.forEach((notification) => {
        $(`#notification_${notification['id']}`).modal('show')
      })
    },
    updateNotification: function (notification) {
      if (this.notificationPopupHide === true) {
        let localStorageKey = `noti_${this.currentUser['userID']}`
        let notiIdString = localStorage.getItem(localStorageKey)
        if ([null, undefined].includes(notiIdString)) {
          localStorage.setItem(localStorageKey, JSON.stringify([notification['id']]))
        } else {
          let notiIds = JSON.parse(notiIdString)
          notiIds.push(notification['id'])
          localStorage.setItem(localStorageKey, JSON.stringify(notiIds))
        }
      }
    },
    showNotificationModal: function (notification) {
      this.notificationHighLightObjectSelected = notification
      $('#notification-highlight-modal').modal('show')
    },
    onConfirmButton: function (inputObjectList) {
      let self = this
      self.finishModal()
    },
    onModalAdditionalButton: function () {
      let self = this
      self.finishModal()
    },
    finishAction: function () {
      this.action = 'none'
      this.confirmModalDisplayObject.additionalButton.isShow = false
    },
  }
}
/* eslint-disable vue/no-v-html */
</script>

<style lang="css">

.custom-body {
  min-height: calc(100vh - 67px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navbar-custom {
  position: sticky;
  top: 15px;
  background-color: white;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.rainbow {
  text-align: left;
  font-size: 22px;
  font-weight: bold;
}
.rainbow_text_animated {
  background: linear-gradient(to right, #ff0000, #ff4d00, #7300ff, #ffd600, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0%,100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}
</style>
