<template>
  <div class="mt-1">
    <div class="card mb-3">
      <div class="card-body mt-1">
        <div class="lev-table">
          <lev-table
            :table-data="dataObjectList"
            :fields="tableSnapshotColumns"
            :searchalbe-fields="searchableFields"
            :extended-fields="VPSExtendtedFields"
            :on-cell-click-processing="onCellClickProcessing"
            @selected-changed="onSelectedChanged"
          />
        </div>
      </div>
      <confirm-modal
        :action="action"
        :active="active"
        :confirm-modal-display-object="confirmModalDisplayObject"
        @modalConfirm="confirmedCallback"
        @modalCancel="finishModal"
        @finishAction="finishAction"
      />
    </div>
  </div>
</template>

<script>
import LevTable from "@/components/children/LevTable";

import dataObjectList from '@/mixins/DataObjectList'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'
import dataRequester from '../../../mixins/utilities/DataRequester'
import inputHelper from '../../../mixins/utilities/InputHelper'

import confirmModal from '../../children/ConfirmModal'
import { FixBugTheme } from '@/mixins/MaterialTheme';

import moment from 'moment'

export default {
  name: "BackupVPS",
  components: {
    LevTable,
    confirmModal
  },
  mixins: [dataObjectList, dataRequester, confirmModalBuilder, inputHelper],
  props: {
    currentUser: {}
  },
  data: function () {
    let self = this
    return {
      action: 'none',
      selectedObjectIndex: null,
      tableSnapshotColumns: [
        {
          name: 'id',
          title: 'ID',
          width: '5%'
        },
        {
          name: 'vm_ip_port',
          title: '<i class="fas fa-cloud"></i><span class="text-bolder"> ' + this.$t('backup.table.thVPS') + '</span>',
          width: '15%'
        },
        {
          name: 'plan_disk',
          title: '<i class="fas fa-hdd"></i><span class="text-bolder"> ' + this.$t('backup.table.thSize') + '</span>',
          width: '10%',
          formatter: function (cellData) {
            return cellData + ' GB'
          }
        },
        {
          name: 'status',
          title: '<i class="fas fa-info"></i><span class="text-bolder"> ' + this.$t('backup.table.thStatus') + '</span>',
          width: '10%'
        },
        {
          name: 'backup_period',
          title: '<i class="fa fa-clock"></i><span class="text-bolder"> ' + this.$t('backup.table.thPeriod') +  '</span>',
          formatter: function (cellData) {
            return cellData + ' d'
          },
          width: '10%'
        },
        {
          name: 'last_backup',
          title: '<i class="fas fa-calendar-alt"></i><span class="text-bolder"> ' + this.$t('backup.table.thLastBackup') + '</span>',
          width: '15%',
          formatter: function (cellData) {
            return self.convertISODate(cellData)
          }
        },
        {
          name: 'next_backup',
          title: '<i class="fas fa-calendar-alt"></i><span class="text-bolder"> ' + this.$t('backup.table.thNextBackup') + '</span>',
          width: '15%',
          formatter: function (cellData) {
            return self.convertISODate(cellData)
          }
        }
      ],
      searchableFields:['image_name', 'snapshot_name'],
      VPSExtendtedFields: [
        {
          name: 'manage',
          title: '<i class="fas fa-cog"></i><span class="text-bolder"> ' + this.$t('snapshot.table.thManage') + '</span>',
          width: '20%',
          formatter: function () {
            return '<span class="badge badge-pill bg-gradient-success cursor-pointer" name="recovery">Recovery</span>'
            + '<span class="badge badge-pill bg-gradient-primary cursor-pointer" name="delete">Delete</span>'
          }
        }
      ]
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    self.getDataFromAPI(self.$config.apiPath.backupList, function (result) {
      self.dataObjectList = result.data
    })
  },
  methods: {
    FixBugTheme,
    onSelectedChanged(selectedRowID) {
      // console.log(this.dataObjectList.filter(d => selectedRowID.includes(d['id'])))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      switch (cellField.name) {
        case 'manage':
          if (event.target.getAttribute('name') === 'delete') {
            this.selectedObjectIndex = this.dataObjectList.findIndex( item => item.id === rowData.id)
            this.delete(rowData)
            break
          }
          if (event.target.getAttribute('name') === 'recovery') {
            this.selectedObjectIndex = this.dataObjectList.findIndex( item => item.id === rowData.id)
            this.recover(rowData)
            break
          }
        default:
      }
      updatePostTable()
    },
    recover: function (rowData) {
      if (rowData.status !== 'Active') {
        this.$toasted.error(this.$t('backup.toast.errBackupNotActive'))
      } else {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('backup.modal.recoverTitle') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('backup.modal.bodyWarningRecover')
        this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><strong>'
        this.confirmModalDisplayObject.bodyMsg += rowData.vm_ip_port
        this.confirmModalDisplayObject.bodyMsg += ' (' + this.convertISODate(rowData.last_backup) + ')'
        this.confirmModalDisplayObject.bodyMsg += '</strong></div>'
        this.confirmModalDisplayObject.bodyMsg += '<div class="text-danger">' + this.$t('backup.modal.bodyWarningRecoverDataLoss') + '</div>'
        this.confirmModalDisplayObject.confirmButtonTitle = this.$t('backup.modal.buttonRecover')
        this.action = 'recoverBackup'
        this.showModal(true)
      }
    },
    delete: function (rowData) {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('backup.modal.deleteTitle') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('backup.modal.bodyWarningDelete')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><strong>'
      this.confirmModalDisplayObject.bodyMsg += rowData.vm_ip_port
      this.confirmModalDisplayObject.bodyMsg += ' (' + rowData.plan_disk + ' GB)'
      this.confirmModalDisplayObject.bodyMsg += '</strong></div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="text-danger">' + this.$t('backup.modal.bodyWarningDisable') + '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('backup.modal.buttonDelete')
      this.action = 'confirmDeleteBackup'
      this.showModal(true)
    },
    confirmedCallback: function () {
      let self = this
      if (this.action === 'confirmDeleteBackup') {
        this.submitObjectList(this.$config.apiPath.backupDelete, {id: this.dataObjectList[this.selectedObjectIndex].id},
            'backup', undefined, function (deleteResult) {
              self.$toasted.success(deleteResult.msg)
              self.dataObjectList.splice(self.selectedObjectIndex, 1)
              $('#dataTable').DataTable().rows(self.selectedObjectIndex).remove().draw(false)
            })
      } else if (this.action === 'recoverBackup') {
        this.submitObjectList(this.$config.apiPath.backupRecover, {id: this.dataObjectList[this.selectedObjectIndex].id},
            'backup', undefined, function (recoverResult) {
              self.$toasted.success(recoverResult.msg)
            })
      }
      self.finishModal()
    },
    finishAction: function () {
      this.action = 'none'
    }
  }
}
</script>
