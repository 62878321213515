<template>
  <main class="main-content  mt-0">
    <div
      class="page-header align-items-start min-vh-100"
      style="background-image: url('../../assets/img/bg/forgot-pw.jpeg');"
    >
      <span class="mask bg-gradient-dark opacity-6" />
      <div class="container my-auto">
        <div class="row">
          <div class="col-lg-4 col-md-7 mx-auto">
            <div class="card z-index-0 my-auto">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div class="bg-gradient-light shadow-primary border-radius-lg py-3 text-center">
                  <img
                    :src="metaData.logoPath"
                    class="img-logo-login"
                    :alt="metaData.siteName + ' logo'"
                  >
                </div>
              </div>
              <div class="card-body">
                <p class="font-weight-bolder">
                  {{ $t('recoverPassword.label.top') }}
                </p>
                <form role="form">
                  <div class="input-group input-group-static mb-3">
                    <label class="form-label">{{ $t('recoverPassword.label.username') }} <i class="fa fa-user" /></label>
                    <input
                      id="username"
                      v-model="user"
                      type="email"
                      class="form-control"
                      required
                    >
                  </div>
                  <div
                    v-if="requestPWEmailResult.status !== ''"
                    class="text-center pb-2"
                  >
                    <strong><span :class="requestPWEmailResult.cssTextStatus">{{ requestPWEmailResult.msg }}</span></strong>
                  </div>
                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn bg-gradient-success btn-lg w-100 my-4 mb-2"
                      @click.prevent="submit"
                    >
                      {{ $t('recoverPassword.button.submit') }}
                    </button>
                    <button
                      type="button"
                      class="btn bg-gradient-primary btn-lg w-100 my-1 mb-2"
                      @click.prevent="login"
                    >
                      <span class="fa fa-arrow-left" /> {{ $t('recoverPassword.button.login') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer position-absolute bottom-2 py-2 w-100">
        <div class="container">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-12 col-md-6 my-auto">
              <div class="copyright text-center text-sm text-white text-lg-start">
                <div v-html="metaData.footNote" />
              </div>
            </div>
            <div class="col-lg-6 justify-content-end">
              <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                <li class="nav-item">
                  <a
                      href="https://fb.com/LowendVietServer"
                      class="nav-link pe-0 text-white"
                      target="_blank"
                  ><i class="fab fa-facebook-square"></i> Facebook</a>
                </li>
                <li class="nav-item">
                  <div class="dropup">
                    <a
                        id="navbarDropdownMenuLink2"
                        href="javascript:;"
                        class="dropdown-toggle nav-link pe-0 text-white"
                        data-bs-toggle="dropdown"
                    >
                      <i class="fa fa-fw fa-globe" /> Language&nbsp;
                      <flag
                          :iso="this.$i18n.locale.slice(-2)"
                          :squared="false"
                      />
                    </a>
                    <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink2"
                    >
                      <li>
                        <div
                            class="dropdown-item"
                            aria-labelledby="language"
                        >
                            <span
                                v-for="(lang, i) in metaData.availableLang"
                                :key="i"
                            >
                              <span
                                  class="dropdown-item"
                                  @click="changeLocale(lang['locale'])"
                              >
                                <span class="">
                                  <flag
                                      :iso="lang['locale'].slice(-2)"
                                      :squared="false"
                                  />
                                </span> {{ lang['description'] }}
                              </span>
                            </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </main>
</template>

<script>

import dataRequester from '../../mixins/utilities/DataRequester'
import inputHelper from '../../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "Register",
  mixins: [ inputHelper, dataRequester ],
  props: {
    currentUser: {}
  },
  data: function () {
    return {
      user: '',
      requestPWEmailResult: {
        status: '',
        cssTextStatus: '',
        msg: ''
      },
      metaData: {
        logoPath: '',
        siteName: '',
        footNote: '',
        shortFootNote: '',
        availableLang: ''
      }
    }
  },
  mounted: function () {
    this.FixBugTheme()
  },
  created: function () {
    let self = this

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }

    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.shortFootNote = metaData.domainObjectList['shortfootnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
    }, function () {
      this.$router.push('https://levcs.com')
    })
  },
  methods: {
    FixBugTheme,
    login: function () {
      this.$router.push('/login')
    },
    submit: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.requestPWEmail, this.user, 'user', undefined, function (result) {
        self.requestPWEmailResult.cssTextStatus = 'text-success'
        self.requestPWEmailResult.status = result.status
        self.requestPWEmailResult.msg = result.msg
        self.$toasted.success(result.msg)
      }, function (result) {
        self.requestPWEmailResult.cssTextStatus = 'text-danger'
        self.requestPWEmailResult.status = result.status
        self.requestPWEmailResult.msg = result.msg
      })
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    }
  }
}
</script>

<style scoped>

</style>