<template>
  <div class="card mt-3">
    <div class="card-header pb-0">
      <h5 class="card-title">{{ $t('hosting.order.hostingInfo') }}</h5>
      <hr/>
    </div>
    <div class="card-body pt-3">
      <lev-input class="mb-3" :label="$t('hosting.order.domain')" v-model="value['domain']"/>
      <lev-input class="mb-3" :label="$t('hosting.order.email')" v-model="value['email']"/>
      <lev-input class="mb-3" :label="$t('hosting.order.username')" v-model="value['username']"/>
      <lev-input class="mb-3" :label="$t('hosting.order.password')" v-model="value['password']"/>
    </div>
  </div>
</template>

<script>
import LevInput from "@/components/children/lev_components/LevInput.vue";

export default {
  name: "HostingInfo",
  components: {LevInput},
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    username () {
      this.updateValue()
    },
    domain () {
      this.updateValue()
    },
    email () {
      this.updateValue()
    },
    password () {
      this.updateValue()
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', {
        username: value['username'],
        domain: value['domain'],
        email: value['email'],
        password: value['password']
      })
    }
  }
}
</script>
