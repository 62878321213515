export default {
  // This lang file is for both "Profile" and "Change password" screens since I am quite lazy -_-
  en: {
    breadcrumb: 'Profile Detail',
    pw_breadcrumb: 'Change Password',
    cardHeader: {
      title: 'Update your profile',
      pwTitle: 'Change your password',
      titleTeamManager: 'Team management',
      session: 'Login sessions',
    },
    label: {
      username: 'Username',
      profileName: 'Name on profile (Facebook, Twitter...)',
      email: 'Email',
      phoneNo: 'Phone number',
      facebookURL: 'Facebook/Twitter URL / QQ Number',
      balance: 'Credit balance',
      promotional_balance: 'Promo. balance',
      api: 'API Token',
      pwCurrent: 'Current password',
      pwNewPassword: 'Enter the new password',
      pwNewPassword2: 'Retype your password',
      session: 'You are currently logged in on <span class="text-danger font-weight-bold font-size-28">{0}</span> device(s)!'
    },
    modal: {
      clearSessionTitle: 'Confirm clear all session?'
    },
    placeholder: {
      username: 'Username',
      password: 'Password'
    },
    button: {
      updateProfile: 'Update your profile',
      generateAPI: 'Generate new API token',
      showApiKey: 'Show API token',
      updateApiKey: 'Update API token',
      pwChangePassword: 'Change your password',
      clearAllSessions: 'Clear all sessions',
    },
    toast: {
      pwNotSame: 'The 2 passwords you entered are not the same!'
    },
    warning: {
      clearAllSessions: 'When you confirm, all login sessions on all devices will be cleared. ' +
            'You will have to sign in again on all devices. <br/>' +
            'Please make sure you want to perform this action.'
    }
  },
  vi: {
    breadcrumb: 'Thông Tin Cá Nhân',
    pw_breadcrumb: 'Đổi Mật Khẩu',
    cardHeader: {
      title: 'Cập nhật thông tin tài khoản',
      pwTitle: 'Đổi mật khẩu',
      titleTeamManager: 'Quản lý nhóm',
      session: 'Phiên đăng nhập',
    },
    label: {
      username: 'Tên đăng nhập',
      profileName: 'Họ và tên (trên Facebook, Twitter...)',
      email: 'Email',
      phoneNo: 'Số điện thoại',
      facebookURL: 'Facebook URL',
      balance: 'Số dư',
      promotional_balance: 'Khuyến mại',
      api: 'API Token',
      pwCurrent: 'Mật khẩu hiện tại',
      pwNewPassword: 'Điền mật khẩu mới',
      pwNewPassword2: 'Điền lại mật khẩu mới',
      session: 'Tài khoản hiện đang đang nhập trên <span class="text-danger font-weight-bold font-size-28">{0}</span> thiết bị!'
    },
    modal: {
      clearSessionTitle: 'Xác nhận xóa tất cả phiên đăng nhập?'
    },
    placeholder: {
      username: 'Tên đăng nhập',
      password: 'Mật khẩu'
    },
    button: {
      updateProfile: 'Cập nhật tài khoản',
      generateAPI: 'Tạo mới API token',
      showApiKey: 'Hiển thị API token',
      updateApiKey: 'Cập nhật API token',
      pwChangePassword: 'Đổi mật khẩu',
      clearAllSessions: 'Xóa tất cả phiên đăng nhập',
    },
    toast: {
      pwNotSame: 'Mật khẩu lần 2 không giống, vui lòng điền lại!'
    },
    warning: {
        clearAllSessions: 'Khi quý khách xác nhận, tất cả các phiên đăng nhập trên tất cả các thiết bị sẽ bị xoá. ' +
            'Quý khách sẽ phải đăng nhập lại trên tất cả các thiết bị. <br/>' +
            'Hãy chắc chắn rằng quý khách muốn thực hiện hành động này.'
    }
  },
  zh: {
    cardHeader: {
      title: '更新账户信息',
      pwTitle: '更改密码'
    },
    label: {
      username: '登录名称',
      profileName: '姓与名 (在 Facebook, Twitter, QQ...)',
      email: '邮箱',
      phoneNo: '电话号码',
      facebookURL: '联系QQ',
      balance: '钱包余额',
      api: 'API令牌',
      pwCurrent: '旧密码',
      pwNewPassword: '请填入新密码',
      pwNewPassword2: '请再次填入新密码'
    },
    placeholder: {
      username: '登录名称',
      password: '密码'
    },
    button: {
      updateProfile: '更新',
      generateAPI: '生成新的API令牌',
      pwChangePassword: '更改密码'
    },
    toast: {
      pwNotSame: '两个密码不一致, 请重新输入!'
    }
  }
}
