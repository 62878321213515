<template>
  <div>
    <VueEditor :value="value" :editorToolbar="customizeToolbar" @input="updateContent"/>
  </div>
</template>

<script>
import { FixBugTheme } from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'
import { VueEditor } from "vue2-editor";
export default {
  name: "LevEditor",
  components: {
    VueEditor
  },
  mixins: [dataRequester],
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      customizeToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "video", "formula"],
        ["clean"]
      ]
    }
  },
  mounted() {
    FixBugTheme()
  },
  updated() {
    FixBugTheme()
  },
  methods: {
    updateContent (value) {
      this.$emit('input', value)
    }
  }
}
</script>


