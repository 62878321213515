<template>
  <div class="m-0 p-0">
    <div>
      <label class="width-full p-1">
        <div class="card">
          <div class="card-body p-2">
            <div class="row text-center text-bold text-uppercase">
              <div v-for="(column, index) in columns" :key="index" class="col align-self-center">
                <span>{{ column['label'] }}</span>
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div
        v-for="(value, index) in items"
        :key="index"
    >
      <input
          :id="`planId_${value['id']}`"
          type="radio"
          class="btn-check"
          name="btnradioPlan"
          :value="value['service_plan_id']"
          autocomplete="off"
          @change="$emit('change', value['id'])"
      >
      <label
          class="btn btn-outline-primary width-full p-1"
          :for="`planId_${value['id']}`"
      >
        <div class="card">
          <div class="card-body row p-2 align-items-center text-dark">
            <div v-for="(column, index) in columns" :key="index" class="col align-self-center align-items-center">
              <div v-if="column['formatter'] !== undefined">
                <span v-html="column.formatter(value)"/>
              </div>
              <div v-else class="text-md">
                {{ value[column['key']] }}
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "HostingPlans",
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      columns: [
        {
          key: 'name',
          label: this.$t('hosting.order.hostingPlanName'),
          formatter: function (item) {
            return `<span class="text-md text-uppercase-none">${item['name']}</span>`
          }
        },
        {
          key: 'disk',
          label: this.$t('hosting.order.disk'),
          formatter: function (item) {
            return `<span class="text-md">${item['disk']}</span><span class="text-sm">GB</span> <span class="text-md">${item['disk_type']}</span>`
          }
        },
        {
          key: 'number_addon_domain',
          label: this.$t('hosting.order.numberOfWebsites'),
        },
        {
          key: 'number_email',
          label: this.$t('hosting.order.numberOfemails'),
        },
        {
          key: 'price',
          label: this.$t('hosting.order.prices'),
          formatter: function (item) {
            return `<span class="text-md text-success">$</span><span class="text-2xl text-success">${item['price']}</span>`
          }
        },
        {
          key: 'description',
          label: this.$t('hosting.order.description'),
          formatter: function (item) {
            return `<span class="text-xs text-justify font-weight-normal text-uppercase-none text-success">${item['description']}</span>`
          }
        }
      ]
    }
  }
}
</script>
