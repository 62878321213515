<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="form-group row align-content-center pb-0 mb-0">
          <div class="col-sm-2 offset-2 mt-2">
            <label>{{ $t('log.cardHeader.description') }}</label>
          </div>
          <div class="col-sm-1 mt-2 justify-content-end">
            <label>{{ $t('log.cardHeader.from') }}</label>
          </div>
          <div class="col-sm-1">
            <input
                id="dateFrom"
                v-model="dateFrom"
                type="date"
                class="form-control"
            >
          </div>
          <div class="col-sm-1 mt-2">
            <label>{{ $t('log.cardHeader.to') }}</label>
          </div>
          <div class="col-sm-1">
            <input
                id="dateTo"
                v-model="dateTo"
                type="date"
                class="form-control"
            >
          </div>
          <button  class="btn btn-success btn-sm col-sm-2" @click="getOrderHistoryList(dateFrom, dateTo)"
          >
            <i class="fa fa-filter" /> {{ $t('log.cardHeader.buttonFilter') }}
          </button>
        </div>
        <hr>
        <div class="lev-table">
          <lev-table
            :table-data="dataObjectList"
            :fields="tableHistoryColumns"
            :searchalbe-fields="searchableFields"
            :extended-fields="tableExtendtedFields"
            :on-cell-click-processing="onCellClickProcessing"
            @selected-changed="onSelectedChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dataObjectList from '@/mixins/DataObjectList'
import LevTable from "@/components/children/LevTable"
import { FixBugTheme } from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import moment from 'moment'

export default {
  name: "History",
  components: {LevTable},
  mixins: [dataObjectList, dataRequester, inputHelper],
  props: {
    metaData: {},
  },
  data() {
    let self = this
    return {
      tableHistoryColumns: [
        {
          name: 'type',
          title: '<i class="fab fa-hive"></i> ' + this.$t('order.table.thType'),
          sortField: 'type',
          formatter: function (cellData) {
            switch (cellData){
              case 'topup':
                return '<span class="badge badge-primary"><span>' + self.$t('order.table.tdTypeTopup') + '</span></span>'
              case 'new':
                return '<span class="badge badge-success"><span>' + self.$t('order.table.tdTypeNew') + '</span></span>'
              case 'renew':
                return '<span class="badge badge-success"><span>' + self.$t('order.table.tdTypeRenew') + '</span></span>'
              case 'promotion':
                return '<span class="badge badge-danger"><span>' + self.$t('order.table.tdTypePromotion') + '</span></span>'
              case 'other':
                return '<span class="badge badge-secondary"><span>' + self.$t('order.table.tdTypeOther') + '</span></span>'
              case 'change':
                return '<span class="badge badge-warning"><span>' + self.$t('order.table.tdTypeChange') + '</span></span>'
              case 'refwith':
                return '<span class="badge badge-info"><span>' + self.$t('order.table.tdTypeRefwith') + '</span></span>'
              case 'upgrade':
                return '<span class="badge badge-warning"><span>' + self.$t('order.table.tdTypeUpgrade') + '</span></span>'
              case 'testing':
                return '<span class="badge badge-info"><span>' + self.$t('order.table.tdTypeTesting') + '</span></span>'
              case 'sla':
                return '<span class="badge badge-info"><span>SLA</span></span>'
              case 'sync':
                return '<span class="badge badge-success">Sync</span>'
              default:
                return cellData
            }
          }
        },
        {
          name: 'status',
          title: '<i class="fas fa-info"></i> ' + this.$t('order.table.thStatus'),
          sortField: 'status',
          formatter: function (cellData) {
            switch (cellData){
              case 'Credited':
                return '<span class="badge badge-success"><span>' + self.$t('order.table.tdStatusCredited') + '</span></span>'
              case 'Renewed':
                return '<span class="badge badge-success"><span>' + self.$t('order.table.tdStatusRenewed') + '</span></button>'
              case 'Provisioned':
                return '<span class="badge badge-success"><span>' + self.$t('order.table.tdStatusProvisioned') + '</span></button>'
              case 'Installing':
                return '<span class="badge badge-primary"><span>' + self.$t('order.table.tdStatusInstalling') + '</span></button>'
              case 'Pending':
                return '<span class="badge badge-info"><span>' + self.$t('order.table.tdStatusPending') + '</span></button>'
              case 'Partially':
                return '<span class="badge badge-info"><span>' + self.$t('order.table.tdStatusPartially') + '</span></button>'
              case 'Cancelled':
                return '<span class="badge badge-secondary"><span>' + self.$t('order.table.tdStatusCancelled') + '</span></button>'
              case 'Unpaid':
                return '<span class="badge badge-danger"><span>' + self.$t('order.table.tdStatusUnpaid') + '</span></button>'
              case 'Finished':
                return '<span class="badge badge-success"><span>' + self.$t('order.table.tdStatusFinished') + '</span></button>'
              default:
                return '<span class="badge badge-dark"><span>' + self.$t('order.table.tdStatusFraud') + '</span></button>'
            }
          }
        },
        {
          name: 'payment_name',
          title: '<i class="fas fa-dollar-sign"></i> ' + this.$t('order.table.thMethod'),
          sortField: 'payment_name'
        },
        {
          name: 'remark',
          sortField: 'remark',
          title: '<i class="fas fa-edit"></i> ' + this.$t('order.table.thNote')
        },
        {
          name: 'created_at',
          sortField: 'created_at',
          title: '<i class="far fa-calendar-plus"></i> ' + this.$t('order.table.thCreated'),
          formatter: function (cellData){
            return self.convertISODate(cellData)
          }
        },
        {
          name: 'updated_at',
          title: '<i class="far fa-calendar-check"></i> ' + this.$t('order.table.thUpdated'),
          sortField: 'updated_at',
          formatter: function (cellData){
            return self.convertISODate(cellData)
          }
        }
      ],
      tableExtendtedFields: [
        {
          name: 'order_id',
          title: 'ID',
          sortField: 'order_id',
          order: 1,
          formatter: function (rowData) {
            return '<a href="#/home/order/detail/' + rowData['id'] + '" class="text-bold" target="_parent">' + self.metaData.siteTicker + rowData['id'] + '</a>'
          },
          extended_data: function (rowData) {
            // To allow search on the table
            return self.metaData.siteTicker + rowData['id']
          }
        },
        {
          name: 'total_amount',
          title: '<i class="fas fa-plus-circle"></i> ' + this.$t('order.table.thTotal'),
          sortField: 'total_amount',
          order: 3,
          formatter: function (rowData) {
            return '<span class="text-bold text-info">' + self.USDCurrencyFormat(rowData['total_amount']) + ' </span><small><small>(' + self.currencyFormat(rowData['total_amount'] * self.exchangeRateValue(rowData['created_at']), self.metaData.defaultCurrency) + ')</small></small>'
          }
        },
        {
          name: 'lpoint_earn',
          title: '<i class="fas fa-hand-holding-heart"></i> LPOINT',
          sortField: 'lpoint_earn',
          order: 4,
          formatter: function (rowData) {
            if (rowData['lpoint_earn'] > 0) {
              if (['Renewed', 'Provisioned', 'Finished'].includes(rowData['status'])) {
                return `<strong class="text-success">+${rowData['lpoint_earn']}</strong>`
              } else {
                return `<label class="text-secondary"><i class="fas fa-hourglass-start"></i> +${rowData['lpoint_earn']}</label>`
              }
            } else if (rowData['lpoint_earn'] < 0) {
              if (['Renewed', 'Provisioned', 'Finished'].includes(rowData['status'])) {
                return `<strong class="text-danger">${rowData['lpoint_earn']}</strong>`
              } else {
                return `<label class="text-secondary"><i class="fas fa-hourglass-start"></i> ${rowData['lpoint_earn']}</label>`
              }
            } else {
              return rowData['lpoint_earn']
            }
          }
        },
      ],
      searchableFields: ['order_id', 'type', 'total_amount', 'status', 'payment_name', 'remark', 'created_at', 'updated_at'],
      exchangeRateHistoryList: [],
      dateTo: null,
      dateFrom: null
    }
  },
  computed: {

  },
  mounted() {
    this.FixBugTheme()
  },
  created() {
    let self = this
    this.dateTo = this.getDateFromCurrent(),
    this.dateFrom = this.getDateFromCurrent(-365)

    this.getOrderHistoryList()
    this.submitObjectList(this.$config.apiPath.exchangeRateHistory, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.exchangeRateHistoryList = result.data['exchangeRateHistoryList']
      } else {
        self.$toasted.error(result.msg)
      }
    })
  },
  methods: {
    FixBugTheme,
    onSelectedChanged(selectedRowID) {
      // console.log(selectedRowID)
      // console.log(this.dataObjectList.filter(d => selectedRowID.includes(d['id'])))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      // if (cellField.name === 'status') {
      //   if (rowData['status'] === 'success') {
      //     rowData['status'] = 'Totally success'
      //   }
      //   updatePostTable()
      // }
    },
    exchangeRateValue (orderCreatedAt) {
      let exchangeRateValue
      if (orderCreatedAt === undefined) {
        exchangeRateValue = null
      } else {
        let exchangeRate = this.exchangeRateHistoryList.find(e => moment(e['applied_at']).isSameOrBefore(orderCreatedAt))
        if (exchangeRate === undefined) {
          exchangeRateValue = null
        } else {
          exchangeRateValue = exchangeRate['exchange_rate']
        }
      }
      return exchangeRateValue
    },
    getOrderHistoryList(dateFrom, dateTo) {
      let self = this
      this.submitObjectList(this.$config.apiPath.orderList,
          undefined,
          undefined,
          {
            dateFrom: self.convertISODate(dateFrom),
            dateTo: self.convertISODate(dateTo)
          }, function(result) {
        if(result.status === 'success'){
          self.dataObjectList = result.data.reverse()
          self.$toasted.success(result.msg)
        } else {
          self.$toasted.error(result.msg)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
