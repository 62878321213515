<template>
  <div>
    <div class="card mb-4 ">
      <div class="d-flex">
        <div class="icon icon-shape icon-lg bg-gradient-info shadow text-center border-radius-xl mt-n3 ms-4">
          <i class="fas fa-users"></i>
        </div>
        <h6 class="mt-3 mb-2 ms-3 ">{{ $t('team.label.title') }}</h6>
      </div>
      <div class="card-body p-3">
        <div v-if="currentUser['team_id'] === undefined || currentUser['team_id'] === null" class="text-center">
          <div v-html="$t('team.label.create_new_team')"></div>
          <button class="btn bg-gradient-info btn-sm mt-3 mb-0 mx-1"
                  onclick="$('#modal-confirm-join-team').modal('show')">
            {{ $t('team.button.join_a_team') }}
          </button>
          <button class="btn bg-gradient-primary btn-sm mt-3 mb-0 mx-1"
                  @click="showModalConfirm('create')">
            {{ $t('team.button.create_new_team') }}
          </button>
        </div>
        <div v-else class="text-center">
          <div v-if="currentUser['team_private_key'] !== null" class="text-start mb-5">
            <div>
              <span v-if="currentUser['is_group_opened']" v-html="$t('team.label.team_is_opened', currentUser)"></span>
              <span v-else v-html="$t('team.label.team_is_closed')"></span>
              <br/>
              <div v-if="currentUser['is_group_opened']">
                <strong>{{ $t('team.label.link_join_team') }}</strong>
                <a href="javascript:;"><i>{{ basePath }}</i></a>
                <i class="fas fa-copy text-primary ms-2 cursor-pointer"
                   data-bs-toggle="tooltip"
                   data-bs-placement="bottom"
                   :title="$t('team.tooltip.copy')"
                   @click="copyContent(basePath)"></i>
                <div class="d-flex align-items-center mb-1">
                  {{ $t('team.label.click') }}
                  <span
                      class="badge badge-pill bg-gradient-info cursor-pointer mx-1"
                      @click="showModalConfirm('update-private-key')"
                  >{{ $t('team.button.change_invite_code') }}</span>
                  {{ $t('team.label.change_invite_code') }}
                </div>
              </div>
              <div v-if="currentUser['is_group_opened']" class="d-flex align-items-center">
                  {{ $t('team.label.click') }}
                  <span
                      class="badge badge-pill bg-gradient-danger cursor-pointer mx-1"
                      @click="showModalConfirm('close')"
                  >{{ $t('team.button.closed_team') }}</span>
                  {{ $t('team.label.close_team_msg') }}
              </div>
              <div v-else class="d-flex align-items-center">
                {{ $t('team.label.click') }}
                <span
                      class="badge badge-pill bg-gradient-success cursor-pointer mx-1"
                      @click="showModalConfirm('open')"
                >{{ $t('team.button.opened_team') }}</span>
                {{ $t('team.label.open_team_msg') }}
              </div>
            </div>
          </div>
          <div v-if="teamMembers.length === 0">
            <span>{{ $t('team.label.no_member') }}</span>
          </div>
          <div v-else class="d-flex flex-wrap overflow-x-auto">
            <div v-for="(member, index) in teamMembers" :key="index" class="card mb-3 me-3 card-team-member">
              <div class="card-header text-start">
                <h5 v-if="member['is_team_leader']" class="d-flex align-items-center">
                  <i class="fas fa-user-tie me-2 text-primary fa-3x"></i>
                  <div>
                    <span>{{ member['full_name'] }}</span>
                    <br/>
                    <span class="badge badge-sm badge-primary"><span class="smaller"><i class="fas fa-medal"></i> {{$t('team.label.leader')}}</span></span>
                  </div>
                </h5>
                <h5 v-else class="d-flex align-items-center">
                  <i class="fas fa-user me-2 text-info fa-3x"></i>
                  <div>
                    <span>{{ member['full_name'] }}</span>
                    <br/>
                    <span class="badge badge-sm badge-info"><span class="smaller"><i class="fas fa-medal"></i> {{$t('team.label.member')}}</span></span>
                  </div>
                </h5>
              </div>
              <hr class="dark horizontal my-0">
              <div class="card-body">
                <div class="text-start small">
                  <span>
                    <i class="fab fa-facebook-f card-icon"></i>
                    <a :href="`https://fb.com/${member['facebook_uid']}`" target="_blank">https://fb.com/{{member['facebook_uid']}}</a>
                  </span>
                  <br/>
                  <span>
                    <i class="fas fa-envelope card-icon"></i>
                    <span>{{member['email']}}</span>
                  </span>
                  <br/>
                  <span v-if="!member['is_team_leader']">
                    <div>
                      <i class="fas fa-cloud card-icon"></i>
                      <span>{{ $t('team.label.total_services') }}: {{member['total_services'] || 0}}</span>
                    </div>
<!--                    <div class="d-flex align-items-center">-->
<!--                      <div class="form-check card-icon">-->
<!--                        <input class="form-check-input"-->
<!--                               type="checkbox"-->
<!--                               v-model="member['received_team_notification']"-->
<!--                               @click="updateTeamNotification(member)"-->
<!--                        >-->
<!--                      </div>-->
<!--                      <span>{{ $t('team.label.allow_notification') }}</span>-->
<!--                    </div>-->
                  </span>
                </div>
                <button v-if="currentUser['team_private_key'] !== null && !member['is_team_leader']"
                        class="btn bg-gradient-primary btn-sm mt-3 mb-0"
                        @click="showModalConfirm('remove', member)"
                >{{ $t('team.button.remove_team') }}</button>
              </div>
            </div>
          </div>
          <button v-if="currentUser['team_private_key'] !== null"
                  class="btn bg-gradient-danger btn-sm mt-3 mb-0"
                  @click="showModalConfirm('delete')"
          >{{ $t('team.button.delete_team') }}</button>
          <button v-else
                  class="btn bg-gradient-danger btn-sm mt-3 mb-0"
                  @click="showModalConfirm('exit')"
          >{{ $t('team.button.exit_team') }}</button>
        </div>
      </div>
    </div>

    <!--modal-->
    <div class="col-md-4">
      <div class="modal fade" id="modal-confirm" tabindex="-1" role="dialog" aria-labelledby="modal-default" aria-hidden="true">
        <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title font-weight-normal" id="modal-title-default">
                <h4 v-html="modal_title"></h4>
              </h6>
              <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-html="modal_content"></div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm bg-gradient-secondary mr-auto" data-bs-dismiss="modal">{{ $t('team.modal.button.close') }}</button>
              <button type="button" class="btn btn-sm bg-gradient-primary" @click="modalConfirm">{{ $t('team.modal.button.confirm') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modal join team-->
    <div class="col-md-4">
      <div class="modal fade" id="modal-confirm-join-team" tabindex="-1" role="dialog" aria-labelledby="modal-default" aria-hidden="true">
        <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title font-weight-normal" id="modal-title-default">
                <h4>{{$t('team.modal.title.join_team')}}</h4>
              </h6>
              <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <span v-html="$t('team.modal.content.join_team')"></span>
              <div class="input-group input-group-outline my-3">
                <label class="form-label">{{$t('team.modal.label.invite_code')}}</label>
                <input type="text" class="form-control" v-model="invite_code">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm bg-gradient-secondary mr-auto" data-bs-dismiss="modal">{{ $t('team.modal.button.close') }}</button>
              <button type="button" class="btn btn-sm bg-gradient-primary" @click="joinTeam">{{ $t('team.modal.button.join') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "TeamManager",
  mixins: [dataRequester, inputHelper],
  props: {
    currentUser: {},
    metaData: {}
  },
  data: function () {
    return {
      actionType: 'none',
      teamMembers: [],
      modal_title: '',
      modal_content: '',
      currentTeamMember: {},
      invite_code: ''
    }
  },
  computed: {
    basePath: function() {
      return this.$config.basePath + '/#/home/team/join?invite_code=' + this.currentUser['team_private_key']
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    // this.basePath = this.$config.basePath + '/#/home/team/join?private_key=' + this.currentUser['team_private_key']
    this.getDataFromAPI(this.$config.apiPath.listMemberOfTeam, function (result) {
      self.teamMembers = result.data['users']
    })
  },
  methods: {
    FixBugTheme,
    showModalConfirm (actionType, teamMember) {
      this.actionType = actionType
      this.currentTeamMember = teamMember
      switch(this.actionType) {
        case 'create':
          this.modal_title = this.$t('team.modal.title.create_team')
          this.modal_content = this.$t('team.modal.content.create_team')
          break
        case 'open':
          this.modal_title = this.$t('team.modal.title.open_team')
          this.modal_content = this.$t('team.modal.content.open_team')
          break
        case 'close':
          this.modal_title = this.$t('team.modal.title.close_team')
          this.modal_content = this.$t('team.modal.content.close_team')
          break
        case 'remove':
          this.modal_title = this.$t('team.modal.title.remove_member')
          this.modal_content = this.$t('team.modal.content.remove_member', teamMember)
          break
        case 'exit':
          this.modal_title = this.$t('team.modal.title.exit_team')
          this.modal_content = this.$t('team.modal.content.exit_team')
          break
        case 'delete':
          this.modal_title = this.$t('team.modal.title.delete_team')
          this.modal_content = this.$t('team.modal.content.delete_team')
          break
        case 'update-private-key':
          this.modal_title = this.$t('team.modal.title.update_private_key')
          this.modal_content = this.$t('team.modal.content.update_private_key')
          break
        default:
          break
      }
      $('#modal-confirm').modal('show')
    },
    modalConfirm () {
      switch(this.actionType) {
        case 'create':
          this.createNewTeam()
          break
        case 'open':
          this.openTeam()
          break
        case 'close':
          this.closeTeam()
          break
        case 'remove':
          this.removeTeamMember(this.currentTeamMember['id'])
          break
        case 'exit':
          this.exitTeam()
          break
        case 'delete':
          this.deleteTeam()
          break
        case 'update-private-key':
          this.updatePrivateKey()
          break
        default:
          break
      }
      $('#modal-confirm').modal('hide')
    },
    createNewTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.createNewTeam, function (result) {
        self.$toasted.success(result.msg)
        window.location.reload()
      })
    },
    removeTeamMember (memberId) {
      let self = this
      this.submitObjectList(this.$config.apiPath.removeTeamMember, {
        member_id: memberId
      }, 'team', null, function (result) {
        self.$toasted.success(result.msg)
        self.teamMembers = self.teamMembers.filter(x => x['id'] !== memberId)
      })
    },
    exitTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.exitTeam, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['team_id'] = null
      })
    },
    deleteTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.deleteTeam, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['team_id'] = null
        self.currentUser['team_private_key'] = null
      })
    },
    openTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.openTeam, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['is_group_opened'] = true
      })
    },
    closeTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.closeTeam, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['is_group_opened'] = false
      })
    },
    joinTeam () {
      let self = this
      this.submitObjectList(this.$config.apiPath.joinTeam, {
        private_key: self.invite_code
      }, 'team', null, function (result) {
        $('#modal-confirm-join-team').modal('show')
        self.$toasted.success(result.msg)
        window.location.reload()
      })
    },
    updatePrivateKey () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.teamUpdatePrivateKey, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['team_private_key'] = result.data.invite_code
      })
    },
    updateTeamNotification (member) {
      let self = this
      this.submitObjectList(this.$config.apiPath.teamNotification, {
        member_id: member.id,
        received_team_notification: !member.received_team_notification
      }, 'team', null, function (result) {
        self.$toasted.success(result.msg)
      })
    },

    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('dedicatedDetail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    }
  }
}
</script>

<style scoped>

</style>