<template>
  <div id="app">
    <router-view
      :current-user="currentUser"
      @loginEvent="login"
    />
  </div>
</template>

<script>

import inputHelper from '@/mixins/utilities/InputHelper'

export default {
  name: 'App',
  mixins: [inputHelper],
  data: function () {
    return {
      currentUser: {
        promotionalBalance: 0,
        mainBalance: 0,
        balance: 0
      },

      notificationPushed: false
    }
  },
  created: function () {

  },
  methods: {
    login: function (user) {
      let self = this
      this.currentUser = user
    }
  }
}
</script>
