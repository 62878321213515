<template>
  <form role="form" action="">
    <div class="text-center text-muted mb-4">
      <h3>{{ $t('login.label.otp_title') }}</h3>
    </div>
    <div class="row gx-2 gx-sm-3 mb-3">
      <div class="col">
        <div class="input-group input-group-outline">
          <input v-model="otp['number1']" id="number1" type="text" class="otp_code form-control form-control-lg text-center" maxlength="1" autocomplete="off" autocapitalize="off" @input="digitValidate" @keyup="tabChange(1)">
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-outline">
          <input v-model="otp['number2']" id="number2" type="text" class="otp_code form-control form-control-lg text-center" maxlength="1" autocomplete="off" autocapitalize="off" @input="digitValidate" @keyup="tabChange(2)">
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-outline">
          <input v-model="otp['number3']" id="number3" type="text" class="otp_code form-control form-control-lg text-center" maxlength="1" autocomplete="off" autocapitalize="off" @input="digitValidate" @keyup="tabChange(3)">
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-outline">
          <input v-model="otp['number4']" id="number4" type="text" class="otp_code form-control form-control-lg text-center" maxlength="1" autocomplete="off" autocapitalize="off" @input="digitValidate" @keyup="tabChange(4)">
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-outline">
          <input v-model="otp['number5']" id="number5" type="text" class="otp_code form-control form-control-lg text-center" maxlength="1" autocomplete="off" autocapitalize="off" @input="digitValidate" @keyup="tabChange(5)">
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-outline">
          <input v-model="otp['number6']" id="number6" type="text" class="otp_code form-control form-control-lg text-center" maxlength="1" autocomplete="off" autocapitalize="off" @input="digitValidate" @keyup="tabChange(6)">
        </div>
      </div>
    </div>
    <div class="text-center">
      <button type="submit" class="btn bg-gradient-warning w-100" @click="confirmButtonClick">{{ $t('login.button.verify') }}</button>
    </div>
  </form>
</template>

<script>
export default {
  name: "Confirm2Fa",
  data: function () {
    return {
      otp: {
        number1: '',
        number2: '',
        number3: '',
        number4: '',
        number5: '',
        number6: ''
      }
    }
  },
  methods: {
    digitValidate: function(ele){
     this.otp[ele.target.id] = ele.target.value.replace(/[^0-9]/g,'');
    },
    tabChange: function(val){
      try{
        let ele = document.querySelectorAll('.otp_code');
        if(ele[val-1].value != ''){
          ele[val].focus()
        }else if(ele[val-1].value == ''){
          ele[val-2].focus()
        }
      } catch (e) {}
    },
    confirmButtonClick: function () {
      let otp_code = `${this.otp['number1']}${this.otp['number2']}${this.otp['number3']}${this.otp['number4']}${this.otp['number5']}${this.otp['number6']}`
      if (otp_code === '') {
        otp_code = '000000'
      }
      this.otp = {
        number1: '',
        number2: '',
        number3: '',
        number4: '',
        number5: '',
        number6: ''
      }
      this.$emit('clicked-confirm-button', otp_code)
    }
  }
}
</script>

<style scoped>

</style>
