<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5>{{ $t('profile.cardHeader.pwTitle') }}</h5>
      </div>
      <div class="card-body pt-0">
        <div class="input-group input-group-outline">
          <label class="form-label">{{ $t('profile.label.pwCurrent') }}</label>
          <input
            id="password"
            v-model="password"
            type="password"
            class="form-control"
            required
          >
        </div>
        <div class="input-group input-group-outline my-4">
          <label class="form-label">{{ $t('profile.label.pwNewPassword') }}</label>
          <input
            id="password1"
            v-model="password1"
            type="password"
            class="form-control"
            required
          >
        </div>
        <div class="input-group input-group-outline">
          <label class="form-label">{{ $t('profile.label.pwNewPassword2') }}</label>
          <input
            id="password2"
            v-model="password2"
            type="password"
            class="form-control"
            required
          >
        </div>
        <h5 class="mt-5">
          Password requirements
        </h5>
        <p class="text-muted mb-2">
          Please follow this guide for a strong password:
        </p>
        <ul class="text-muted ps-4 mb-0 float-start">
          <li>
            <span class="text-sm">One special characters</span>
          </li>
          <li>
            <span class="text-sm">Min 6 characters</span>
          </li>
          <li>
            <span class="text-sm">One number (2 are recommended)</span>
          </li>
          <li>
            <span class="text-sm">Change it often</span>
          </li>
        </ul>
        <button
          class="btn bg-gradient-primary btn-sm float-end mt-6 mb-0"
          @click.prevent="changePassword('')"
        >
          {{ $t('profile.button.pwChangePassword') }}
        </button>
      </div>
    </div>
    <!-- otp modal -->
    <div
      id="otp_modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otp_modal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <confirm2-fa
              @clicked-confirm-button="changePassword"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'
import { FixBugTheme } from '@/mixins/MaterialTheme';
import confirm2Fa from "@/components/children/Confirm2Fa";

import axios from 'axios'
export default {
  name: "Password",
  components: {confirm2Fa},
  mixins: [dataRequester, inputHelper],
  props: {
    currentUser: {}
  },
  data: function () {
    return {
      password: '',
      password1: '',
      password2: ''
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  created: function () {
  },
  methods: {
    FixBugTheme,
    changePassword: function (otp_code) {
      if (this.password1 !== this.password2) {
        this.$toasted.error(this.$t('profile.toast.pwNotSame'))
      } else {
        let self = this
        let submitData = {
          username: this.$cookies.get('username'),
          sessionId: this.$cookies.get('sessionId'),
          oldPassword: this.password,
          newPassword: this.password1,
          otp_code: otp_code
        }
        axios.post(this.$config.apiPath.updatePassword, submitData)
            .then(function (response) {
              if (response.data.status === 'success') {
                if (response.data.data.is_enable_2fa) {
                  $('#otp_modal').modal('show')
                } else {
                  self.$toasted.success(response.data.msg)
                  $('#otp_modal').modal('hide')
                }
              } else {
                self.$toasted.error(response.data.msg)
                $('#otp_modal').modal('hide')
              }
            })
            .catch(function (err) {
              self.$toasted.error('Error when requesting API!')
              $('#otp_modal').modal('hide')
              // console.log(err)
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
