<template>
  <div class="mt-1">
    <div class="card mb-3">
      <div
        v-if="this.rawDataFromDB.length > 0"
        class="card-header pb-1"
      >
        <div
          class="btn-group d-flex justify-content-between"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <div>
            <input
              id="btnradioAllVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              checked
              @change="onVPSFilterChange($event)"
            >
            <label
              class="btn btn-outline-success"
              for="btnradioAllVPS"
            >{{ $t('vps.button.all', [this.rawDataFromDB.length]) }}</label>

            <input
              id="btnradioVPSShare"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              @change="onVPSFilterChange($event)"
            >
            <label
              class="btn btn-outline-info"
              for="btnradioVPSShare"
            >{{ $t('vps.button.team', [this.getListVPSTeam().length]) }}</label>

            <input
              v-if="this.getListVPSOverDue().length > 0"
              id="btnradioOverDueVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              @change="onVPSFilterChange($event)"
            >
            <label
              v-if="this.getListVPSOverDue().length > 0"
              class="btn btn-outline-warning"
              for="btnradioOverDueVPS"
            >{{ $t('vps.button.overdue', [this.getListVPSOverDue().length]) }}</label>

            <input
              v-if="this.getListVPSSuspend().length > 0"
              id="btnradioSuspendVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              @change="onVPSFilterChange($event)"
            >
            <label
              v-if="this.getListVPSSuspend().length > 0"
              class="btn btn-outline-danger"
              for="btnradioSuspendVPS"
            >{{ $t('vps.button.suspended', [this.getListVPSSuspend().length]) }}</label>

            <input
              v-if="this.getListVPSCancel().length > 0"
              id="btnradioCancelVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              @change="onVPSFilterChange($event)"
            >
            <label
              v-if="this.getListVPSCancel().length > 0"
              class="btn btn-outline-dark"
              for="btnradioCancelVPS"
            >{{ $t('vps.button.cancel', [this.getListVPSCancel().length]) }}</label>

            <input
              v-if="this.getListVPSUnknown().length > 0"
              id="btnradioUnknownVPS"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              @change="onVPSFilterChange($event)"
            >
            <label
              v-if="this.getListVPSUnknown().length > 0"
              class="btn btn-outline-info"
              for="btnradioUnknownVPS"
            >{{ $t('vps.button.unknown', [this.getListVPSUnknown().length]) }}</label>
          </div>
          <div class="">
            <div class="dropdown d-inline m-1">
              <button
                id="dropdownMenuButtonControl"
                class="btn bg-gradient-primary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fas fa-cog" /><span> {{ $t('vps.button.actionMenu') }}</span>
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButtonControl"
              >
                <!--          check status-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="checkStatus"
                  >
                    <i class="fas fa-info-circle menu-icon text-info" /><a> {{ $t('vps.button.status') }}</a>
                  </span>
                </li>
                <!--          edit note-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="editNote"
                  >
                    <i class="fas fa-edit menu-icon" /><a> {{ $t('vps.button.editNote') }}</a>
                  </span>
                </li>
                <!--          start vps-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="confirmVM('start')"
                  >
                    <i class="fas fa-play menu-icon text-success" /><a> {{ $t('vps.button.start') }}</a>
                  </span>
                </li>
                <!--          stop vps-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="confirmVM('stop')"
                  >
                    <i class="fas fa-stop menu-icon text-danger" /><a> {{ $t('vps.button.stop') }}</a>
                  </span>
                </li>
                <!--          restart vps-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="confirmVM('restart')"
                  >
                    <i class="fas fa-sync-alt menu-icon text-danger" /><a> {{ $t('vps.button.restart') }}</a>
                  </span>
                </li>
                <!--          btn Web Remote-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="consoleVM"
                  >
                    <i class="fas fa-tv menu-icon text-info" /><a> {{ $t('vps.button.console') }}</a>
                  </span>
                </li>
                <!--          btn Reinstall VPS-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="rebuildVM"
                  >
                    <i class="fas fa-hdd menu-icon text-danger" /><a> {{ $t('vps.button.rebuild') }}</a>
                  </span>
                </li>
                <!--          btn Take Snap-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="takeSnap"
                  >
                    <i class="fas fa-cloud-upload-alt menu-icon text-success" /><a> {{ $t('vps.button.snapshot') }}</a>
                  </span>
                </li>
                <!--          btn Restore-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="restoreVM"
                  >
                    <i class="fas fa-cloud-download-alt menu-icon text-danger" /><a> {{ $t('vps.button.restore') }}</a>
                  </span>
                </li>
                <!--          btn Transfer-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="transfer"
                  >
                    <i class="fas fa-exchange-alt menu-icon text-danger" /><a> {{ $t('vps.button.transfer') }}</a>
                  </span>
                </li>
                <!--          btn team share-->
                <li v-if="currentUser['team_private_key'] !== null">
                  <span
                    class="dropdown-item"
                    @click="teamMemberShare"
                  >
                    <i class="fas fa-users menu-icon text-info" /><a> {{ $t('vps.button.share') }}</a>
                  </span>
                </li>
                <!--          btn Change-->
                <li>
                  <span
                      class="dropdown-item"
                      @click="change"
                  >
                    <i class="fas fa-sync menu-icon text-info"></i><a> {{ $t('vps.button.change') }}</a>
                  </span>
                </li>
                <!--          btn Renew-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="renew"
                  >
                    <i class="fas fa-calendar menu-icon text-info" /><a> {{ $t('vps.button.renew') }}</a>
                  </span>
                </li>
                <!--          btn Auto Renew-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="setAutoRenew"
                  >
                    <i class="fas fa-thumbs-up menu-icon text-info" /><a> {{ $t('vps.button.autorenew') }}</a>
                  </span>
                </li>
              </ul>
            </div>
            <div class="dropdown d-inline m-1">
              <button
                id="dropdownMenuButtonExportFile"
                class="btn bg-gradient-primary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fas fa-file-download" /><span> {{ $t('vps.button.exportFile') }}</span>
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButtonExportFile"
              >
                <!--          btn Generate RDP-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="generateRDP"
                  >
                    <i class="fab fa-windows menu-icon text-info" /><a> {{ $t('vps.button.rdp') }}</a>
                  </span>
                </li>
                <!--          btn Export Text-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="confirmExportTextFile"
                  >
                    <i class="fas fa-file-alt menu-icon text-secondary" /><a> {{ $t('vps.button.exportText') }}</a>
                  </span>
                </li>
                <!--          btn Export Excel-->
                <li>
                  <span
                    class="dropdown-item"
                    @click="confirmExportExcelFile"
                  >
                    <i class="fas fa-file-excel menu-icon text-success" /><a> {{ $t('vps.button.exportExcel') }}</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="pt-0 pb-0">
      </div>
      <div class="card-body pt-0">
        <div class="lev-table">
          <lev-table
            :table-data="dataObjectList"
            :fields="tableVPSColumns"
            :searchalbe-fields="searchableFields"
            :extended-fields="VPSExtendtedFields"
            :visible-fields="visibleFields"
            :on-cell-click-processing="onCellClickProcessing"
            @selected-changed="onSelectedChanged"
          />
        </div>
      </div>
    </div>
    <confirm-modal
      :action="action"
      :active="active"
      :confirm-modal-display-object="confirmModalDisplayObject"
      @modalCancel="finishModal"
      @modalConfirm="onConfirmButton"
      @modalAdditionalButton="onModalAdditionalButton"
      @finishAction="finishAction"
    />
    <!-- otp modal -->
    <div
      id="otp_modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otp_modal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <confirm2-fa
              @clicked-confirm-button="confirmOTP"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- rebuild images vm -->
    <lev-modal
      id="modalRebuild"
      :title="`<h4>${this.$t('vps.modal.titleConfirmRebuild', [this.selectedObjectList.length])}</h4>`"
      :confirm-button="this.$t('vps.modal.buttonRebuild')"
      @modalConfirm="confirmRebuild"
    >
      <div class="form-group">
        <label for="selectImageRebuild">{{ this.$t('vps.modal.bodySelectOS') }}</label>
        <select
          id="selectImageRebuild"
          class="form-select ps-3 mb-3"
          @change="imageSelectChange"
        >
          <option
            v-for="(image, index) in this.imageValues.imageListCache"
            :key="index"
            :value="image.id"
          >
            {{ image.imageName }}
          </option>
        </select>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
              v-for="(value, index) in this.selectedObjectList"
              :key="index"
            >
              <strong>{{ getIPPortFormat(value['ip'], value['port']) }}</strong>
            </li>
          </ol>
        </div>
        <div>
          <p
            v-if="isValidJSON(this.imageValues.currentImageSelection['description'])"
            class="text-success"
          >
            <strong>{{ this.$t('vps.label.note') }}: </strong>
            <span v-html="JSON.parse(this.imageValues.currentImageSelection['description'])['description'][languageSelected.substring(0,2)]" />
          </p>
          <p
            class="text-danger"
            v-html="this.$t('vps.modal.bodyRebuildWarning')"
          />
        </div>
      </div>
    </lev-modal>
    <!-- restore snapshot modal -->
    <lev-modal
      id="restore-snapshot-modal"
      :title="`<h4>${this.$t('vps.modal.titleConfirmRestore', [this.selectedObjectList.length])}</h4>`"
      :confirm-button="this.$t('vps.modal.buttonRestore')"
      @modalConfirm="confirmRestore"
    >
      <div class="form-group">
        <label for="selectSnapshotRestore">
          {{ this.$t('detail.modal.bodySelectSnapshot') }}
        </label>
        <select
          id="selectSnapshotRestore"
          class="form-select ps-3 mb-3"
          @change="snapshotSelectChange"
        >
          <option
            v-for="(snapshot, index) in this.imageValues.snapshotListCache"
            :key="index"
            :value="snapshot.id"
          >
            {{ snapshot.snapshotName }} ({{ snapshot.note }}) (OS - {{ snapshot.imageName }})
          </option>
        </select>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
              v-for="(value, index) in this.selectedObjectList"
              :key="index"
            >
              <strong>{{ getIPPortFormat(value['ip'], value['port']) }}</strong>
            </li>
          </ol>
        </div>
        <div class="text-danger">
          <p
            v-if="this.imageValues.currentSnapshotSelection['note']"
            class="text-success"
          >
            <strong>{{ this.$t('vps.label.note') }}: </strong>
            <span> {{ this.imageValues.currentSnapshotSelection['note'] }} </span>
          </p>
          <span v-html="this.$t('vps.modal.bodyRestoreWarning')" />
        </div>
      </div>
    </lev-modal>
  </div>
</template>

<script>
import LevTable from "@/components/children/LevTable";
import confirmModal from '@/components/children/ConfirmModal'

import dataObjectListMixin from '@/mixins/DataObjectList'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'
import dataRequester from '../../../mixins/utilities/DataRequester'
import inputHelper from '../../../mixins/utilities/InputHelper'
import async from "async";
import RDPGenerator from '@/mixins/utilities/RDPGenerator'
import FileExport from '@/mixins/utilities/FileExport'
import { FixBugTheme } from '@/mixins/MaterialTheme'
import confirm2Fa from "@/components/children/Confirm2Fa";

import levModal from "@/components/children/lev_components/LevModal"

export default {
  name: "VPSList",
  components: {
    LevTable,
    confirmModal,
    confirm2Fa,
    levModal
  },
  mixins: [dataObjectListMixin, dataRequester, confirmModalBuilder, inputHelper, RDPGenerator, FileExport],
  props: {
    // notifications: {},
    // metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    },
    themeSetting: {},
    languageSelected: String
  },
  data: function () {
    let self = this
    return {
      inputObjectList: [],
      otp_code: '',
      otpConfirmAction: 'none',
      action: 'none',
      filter: {
        dateFrom: {
          currentSelection: null
        },
        dateTo: {
          currentSelection: null
        }
      },
      teamMembers: [],
      rawDataFromDB: [],
      imageValues: {
        imageListCache: [],
        selectedImageId: 0,
        snapshotListCache: [],
        selectedSnapshotId: 0,
        currentImageSelection: {},
        currentSnapshotSelection: {}
      },
      rgcObject: {
        serverList: null,
        vmObject: null
      },
      selectedObjectIndex: null,
      tableVPSColumns: [
        {
          name: 'start_date',
          title: '<i class="far fa-calendar-plus"></i><span class="text-bolder text-uppercase"> ' + this.$t('vps.table.thStart') + '</span>',
          width: '8%',
          formatter: function (cellData) {
            return '<div class="small">' + self.convertISODate(cellData) + '</div>'
          },
          sortField: 'start_date'
        },
        {
          name: 'end_date',
          title: '<i class="far fa-calendar-times"></i><span class="text-bolder text-uppercase"> ' + this.$t('vps.table.thEnd') + '</span>',
          width: '8%',
          sortField: 'end_date',
          formatter: function (cellData) {
            return '<div class="small">' + self.convertISODate(cellData) + '</div>'
          }
        },
      ],
      searchableFields: ['ip_port', 'login_user', 'price', 'vm_plan_name', 'start_date', 'end_date', 'user_remark', 'payment_status'],
      VPSExtendtedFields: [
        {
          name: 'ip_port',
          title: '<i class="fas fa-ethernet"></i> IP',
          formatter: function (rowData) {
            let iconDisplay
            if (rowData['rgc_server_name'] === null && rowData['rgc_hidden']) {
              iconDisplay = '<i class="cursor-pointer fas fa-plus text-success btn-tooltip" data-bs-toggle="tooltip"' +
                            'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.rgc_create') + '"' +
                            'name="createRGC"></i>'
            } else {
              if (!rowData['rgc_hidden']) {
                iconDisplay = '<i class="cursor-pointer fas fa-eye-slash text-info btn-tooltip me-1" data-bs-toggle="tooltip"' +
                              'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.rgc_hidden') + '"' +
                              'name="hiddenRGC"></i>' +
                              '<i class="cursor-pointer fas fa-trash text-danger btn-tooltip" data-bs-toggle="tooltip"' +
                              'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.rgc_delete') + '"' +
                              'name="deleteRGC"></i>'
              } else {
                iconDisplay = '<i class="cursor-pointer fas fa-eye text-info btn-tooltip" data-bs-toggle="tooltip"' +
                              'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.rgc_show') + '"' +
                              'name="showRGC"></i>'
              }
            }

            let iconTeamMember = ''
            if (self.currentUser['team_private_key'] === null) {
              if (rowData['co_owner_id'] !== null) {
                iconTeamMember = `<i class="fas fa-users text-info me-1 cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${self.$t('vps.tooltip.owner_vps', rowData)}"></i>`
              }
            } else {
              if (rowData['co_owner_id'] !== null) {
                iconTeamMember = `<i class="fas fa-user-friends text-info me-1 cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${self.$t('vps.tooltip.co_owner_vps', rowData)}"></i>`
              }
            }

            let iconCopy = '<i class="cursor-pointer fas fa-copy text-primary btn-tooltip me-1" data-bs-toggle="tooltip"' +
                'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.ip_copy') + '"' +
                'name="ipCopy"></i>'

            if(rowData['rgc_hidden']) {
              return  '<div class="d-flex justify-content-between align-items-center small">' +
                      '<a href="/#/home/cloud-vps/detail/' + rowData.id + '" class="font-weight-bolder cursor-pointer">' +
                      self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['os_type']) + '</a>' +
                      '<div class="d-flex justify-content-between align-items-center">' +
                      iconTeamMember +
                      iconCopy +
                      iconDisplay +
                      '</div>' +
                      '</div>'
            } else {
              return  '<div class="d-flex justify-content-between align-items-center small">' +
                      '<a href="/#/home/cloud-vps/detail/' + rowData.id + '" class="text-success font-weight-bolder cursor-pointer ip-hover">' +
                      rowData['rgc_server_name'] + ':' + rowData['rgc_forwarded_port'] + '</a>' +
                      '<div class="d-flex justify-content-between align-items-center">' +
                      iconTeamMember +
                      iconCopy +
                      iconDisplay +
                      '</div>' +
                      '</div>'
            }

          },
          extended_data: function (rowData) {
            // To allow search on the table
            return self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['os_type'])
          },
          order: 1,
          // width: '90%',
          sortField: 'ip_port'
        },
        {
          name: 'login_user',
          title: '<span class="text-uppercase"><i class="fas fa-user"></i> ' + this.$t('vps.table.thUsername') + '</span>',
          formatter: function (rowData) {

            let iconCopy = '<i class="cursor-pointer fas fa-copy text-primary btn-tooltip me-1" data-bs-toggle="tooltip"' +
                'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.user_copy') + '"' +
                'name="userCopy"></i>'

            return '<div class="small d-flex justify-content-between">' +
                    rowData['login_user'] + ' ' + iconCopy +
                    '</div>'
          },
          extended_data: function (rowData) {
          },
          order: 2,
          sortField: 'login_user'
          // width: '15%'
        },
        {
          name: 'login_password',
          title: '<span class="text-uppercase"><i class="fas fa-key"></i> ' + this.$t('vps.table.thPassword') + '</span>',
          formatter: function (rowData) {

            let iconCopy = '<i class="cursor-pointer fas fa-copy text-primary btn-tooltip me-1" data-bs-toggle="tooltip"' +
                'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.pw_copy') + '"' +
                'name="pwCopy"></i>'

            if (rowData['pw_hidden']) {
              return '<div class="small">' +
                  '****** ' +
                  iconCopy +
                  '<span class="fas fa-eye text-info font-weight-bolder cursor-pointer" data-bs-toggle="tooltip"' +
                  'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.pw_show') + '"' +
                  'name="showPassword">' +
                  '</span>' +
                  '</div>'
            } else {
              return  '<div class="small d-flex justify-content-between">' +
                  '<span class="me-1">' +
                  rowData['login_password'] +
                  '</span>' +
                  '<div>' +
                  iconCopy +
                  '<span class="fas fa-eye-slash cursor-pointer text-info text-end" data-bs-toggle="tooltip"' +
                  'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.pw_hide') + '"' +
                  'name="hidePassword">' +
                  '</span>' +
                  '</div>' +
                  '</div>'
            }
          },
          extended_data: function (rowData) {
            return rowData['login_password']
          },
          order: 3,
          sortField: 'login_password'
          // width: '15%'
        },
        {
          name: 'price',
          title: '<i class="fas fa-dollar-sign"></i><span class="text-bolder text-uppercase"> ' + this.$t('vps.table.thPrice') + '</span>',
          width: '5%',
          sortField: 'price',
          formatter: function (rowData) {
            let totalPrice = parseFloat(rowData['price'])
            if (rowData['total_addon'] > 0) {
              return '<div class="small d-flex d-inline">' +
                  '<span class="text-info text-bold">$' + totalPrice.toFixed(2) + '&nbsp;</span>' +
                  '<span class="badge badge-success text-success"><span class="fa fa-puzzle-piece"></span></span>'  +
                  '</div>'
            } else {
              return '<span class="text-info text-bold small">$' + totalPrice.toFixed(2) + ' </span>'
            }
          },
          order: 4
        },
        {
          name: 'vm_plan_name',
          title: '<i class="fab fa-hive"></i><span class="text-bolder text-uppercase"> ' + this.$t('vps.table.thPlan') + '</span>',
          width: '5%',
          sortField: 'vm_plan_name',
          formatter: function (rowData) {
            return '<span class="text-bold small">' + rowData['vm_plan_name'] + '</span>'
          },
          order: 5
        },
        {
          name: 'location',
          title: '<i class="fas fa-map-marker"></i><span class="text-bolder text-uppercase"> ' + this.$t('vps.table.thLocation') + '</span>',
          width: '8%',
          sortField: 'location',
          formatter: function (rowData) {
            return '<span class="text-info btn-tooltip cursor-pointer small" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + rowData.country + ' (' + rowData.state + ')">' + rowData.country_code + ' (' + rowData.state_code +')</span>'
          },
          order: 6
        },
        {
          name: 'backup_period',
          title:'<span class="text-uppercase"><i class="fas fa-save"></i> ' + this.$t('vps.table.thBackup') + '</span>',
          formatter: function (rowData) {
            if (rowData['backup_period'] === 7) {
              return '<span class="small">Weekly</div>'
            } else if (rowData['backup_period'] === 7) {
              return '<span class="small">Daily</div>'
            } else return '<span class="small">No</div>'
          },
          sortField: 'backup_period'
        },
        {
          name: 'auto_renew',
          title: '<span class="text-uppercase"><i class="fas fa-retweet"></i> ' + this.$t('vps.table.thAutoRenew') + '</span>',
          formatter: function(rowData){
            if (rowData['auto_renew'].toString() === '1' || rowData['auto_renew'].toString() === 'true') {
              return '<span class="text-success small text-bold">✓</span>'
            } else {
              return '<span class="text-danger small text-bold">✗</span>'
            }
          },
          sortField: 'auto_renew',
        },
        {
          name: 'user_remark',
          title: '<span class="text-uppercase"><i class="fas fa-edit"></i> ' + this.$t('vps.table.thNote') + '</span>',
          formatter: function (rowData) {
            if (rowData['is_editing_note']) {
              return '<div class="input-group input-group-dynamic align-items-center small">' +
                  '<input type="text" class="form-control text-primary text-sm" value="' + rowData['user_remark'] + '">' +
                  '<span class="cursor-pointer fas fa-save text-success mx-1 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.save_note') + '" name="saveNote"></span>' +
                  '<span class="cursor-pointer fas fa-window-close text-secondary mx-1 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.cancel_note') + '" name="cancelNote"></span>' +
                  '</div>'
            } else {
              return '<div class="small d-flex justify-content-between">' + rowData['user_remark'] +
                  '<div>' +
                  ' <i class="cursor-pointer fas fa-edit text-info btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.edit_note') + '" name="editNote"></i>' +
                  ' <i class="cursor-pointer fas fa-copy text-primary btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('vps.tooltip.copy_note') + '" name="copyNote"></i>' +
                  '</div>' +
                  '</div>'
            }
          },
          extended_data: function (rowData) {
            // To allow search on the table
            return rowData['user_remark']
          },
          sortField: 'user_remark',
        },
        {
          name: 'payment_status',
          title: '<span class="text-uppercase"><i class="fas fa-file-invoice-dollar"></i> ' + this.$t('vps.table.thPay') + '</span>',
          formatter: function(rowData) {
            if (rowData['payment_status'] === 'ok') {
              if (rowData['end_date'] <= self.getCurrentTime()) {
                return '<div class="small"><span class="badge badge-warning"><span>' + self.$t('vps.table.tdOverdue') + '</span></span></div>'
              } else {
                return '<div class="small"><span class="badge badge-success"><span>' + self.$t('vps.table.tdOK') + '</span></span></div>'
              }
            } else if (rowData['payment_status'] === 'suspend') {
              return '<div class="small"><span class="badge badge-danger"><span>' + self.$t('vps.table.tdSuspend') + '</span></span></div>'
            } else if (rowData['payment_status'] === 'cancel') {
              return '<div class="small"><span class="badge badge-dark"><span>' + self.$t('vps.table.tdCancel') + '</span></span></div>'
            } else {
              return '<div class="small"><span class="badge badge-info"><span>' + self.$t('vps.table.tdUnknown') + '</span></span></div>'
            }
          },
          sortField: 'payment_status',
        },
        {
          name: 'operation_status',
          title: '<span class="text-uppercase"><i class="fas fa-info"></i> ' + this.$t('vps.table.thStatus') + '</span>',
          formatter: function (rowData) {
            if (rowData['operation_status'] === 'Checking') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-dark" name="checkStatus">' + self.$t('vps.table.tdChecking') + '</span></div>'
            } else if (rowData['operation_status'] === 'Press') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-primary" name="checkStatus">' + self.$t('vps.table.tdPressToCheck') + '</span></div>'
            } else if (rowData['operation_status'] === 'Running') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-success" name="checkStatus">' + self.$t('vps.table.tdRunning') + '</span></div>'
            } else if (rowData['operation_status'] === 'Success') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-success" name="checkStatus">' + self.$t('vps.table.tdSuccess') + '</span></div>'
            } else if (rowData['operation_status'] === 'Stopped') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-danger" name="checkStatus">' + self.$t('vps.table.tdStopped') + '</span></div>'
            } else if (rowData['operation_status'] === 'Error') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-danger" name="checkStatus">' + self.$t('vps.table.tdError') + '</span></div>'
            } else if (rowData['operation_status'] === 'Suspended') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-warning" name="checkStatus">' + self.$t('vps.table.tdSuspend') + '</span></div>'
            } else if (rowData['operation_status'] === 'Rebuilding') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-warning" name="checkStatus">' + self.$t('vps.table.tdRebuilding') + '</span></div>'
            } else if (rowData['operation_status'] === 'Taking snapshot') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-warning" name="checkStatus">' + self.$t('vps.table.tdSnapshoting') + '</span></div>'
            } else if (rowData['operation_status'] === 'Restoring') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-warning" name="checkStatus">' + self.$t('vps.table.tdRestoring') + '</span></div>'
            } else if (rowData['operation_status'] === 'Backing up') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-warning" name="checkStatus">' + self.$t('vps.table.tdBackingUp') + '</span></div>'
            } else if (rowData['operation_status'] === 'Recovering') {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-warning" name="checkStatus">' + self.$t('vps.table.tdRecovering') + '</span></div>'
            } else {
              return '<div class="small"><span class="cursor-pointer badge badge-pill bg-gradient-info" name="checkStatus">' + rowData['operation_status'] + '</span></div>'
            }
          },
          sortField: 'operation_status'
        },
        {
          name: 'pw_hidden',
          extended_data: true,
          visible: false
        },
        {
          name: 'rgc_hidden',
          extended_data: true,
          visible: false
        },
        {
          name: 'is_editing_note',
          extended_data: false,
          visible: false
        }
      ],
      visibleFields: []
    }
  },
  watch: {
    themeSetting: {
      handler: function () {
        this.updateVisiableFields()
      },
      deep: true
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    this.updateVisiableFields()
    self.submitObjectList(self.$config.apiPath.vpsList, undefined, 'vps',
        {
          filter: {
            dateFrom: self.convertISODate(self.filter.dateFrom.currentSelection),
            dateTo: self.convertISODate(self.filter.dateTo.currentSelection)
          }
        }, function (result) {
          self.$set(self, 'dataObjectList', _.sortBy(result.data, 'end_date'))
          let initialOperationStatus = 'Checking...'
          if (self.dataObjectList.length > 50) {
            initialOperationStatus = 'Press'
          }

          async.forEachOf(self.dataObjectList, function (value, key, callback) {
            value['operation_status'] = initialOperationStatus
            value['action_menu'] = ''
          })

          if (self.dataObjectList.length <= 50) {
            setTimeout(function () {
              async.forEachOf(self.dataObjectList, function (value, key, callback) {
                self.submitObjectList(self.$config.apiPath.vpsStatus, {id: value.id}, 'vm', undefined, function (result) {
                  self.updateStatusRowById(value.id, result.data.VMStatus)
                }, function () {
                  self.updateStatusRowById(value.id, 'Unknown') // It will be rendered to Unknown by datatable configuration
                }, false)
              })
            }, 800)
          }
          self.rawDataFromDB = self.dataObjectList
          for (let i = 0; i < self.rawDataFromDB.length ; i++) {
            self.rawDataFromDB.operation_status = 'Press'
          }
        })

    this.getDataFromAPI(this.$config.apiPath.listMemberOfTeam, function (result) {
      self.teamMembers = result.data['users'].filter(x => x['id'] !== self.currentUser['userID'])
    }, null, false)
  },
  methods: {
    FixBugTheme,
    onSelectedChanged(selectedRowID) {
      this.selectedObjectList = this.dataObjectList.filter(d => selectedRowID.includes(d['id']))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      let self = this
      switch (cellField.name) {
        case 'user_remark':
          if (event.target.getAttribute('name') === 'editNote') {
            rowData['is_editing_note'] = true
          } else if (event.target.getAttribute('name') === 'copyNote') {
            this.copyContent(rowData['user_remark'])
          }
          else if (event.target.getAttribute('name') === 'cancelNote') {
            rowData['is_editing_note'] = false
          }
          else if (event.target.getAttribute('name') === 'saveNote') {
            let currentObjectList = [rowData]
            let newNote = event.target.parentElement.getElementsByTagName('input')[0].value
            this.submitObjectList(this.$config.apiPath.vpsNote, currentObjectList.map(function (a) { return {id: a.id} }), 'vm', {note: newNote}, function (result) {
              self.$toasted.success(result.msg)
              self.setItemProperty(rowData.id, 'user_remark', newNote)
            }, function () {
            })
            rowData['is_editing_note'] = false
          }
          break
        case 'operation_status':
          if (event.target.getAttribute('name') === 'checkStatus') {
            rowData['operation_status'] = 'Checking'
            this.submitObjectList(this.$config.apiPath.vpsStatus, {id: rowData.id}, 'vm', undefined, function (result) {
              rowData['operation_status'] = result.data.VMStatus
            }, function () {
              rowData['operation_status'] = 'Unknown' // It will be rendered to Unknown by datatable configuration
            }, false)
          }
          break
        case 'ip_port':
          switch (event.target.getAttribute('name')) {
            case 'showRGC':
              rowData['rgc_hidden'] = false
              break
            case 'hiddenRGC':
              rowData['rgc_hidden'] = true
              break
            case 'createRGC':
              self.createRGC(rowData)
              break
            case 'deleteRGC':
              self.deleteRGC(rowData)
              break
            default:
              break
            case 'ipCopy':
              if (rowData['rgc_hidden']) {
                self.copyContent(rowData['ip_port'])
              } else {
                self.copyContent(rowData['rgc_server_name'] + ':' + rowData['rgc_forwarded_port'])
              }
              break
          }
          break
        case 'login_password':
          switch (event.target.getAttribute('name')) {
            case 'showPassword':
              rowData['pw_hidden'] = false
              break
            case 'hidePassword':
              rowData['pw_hidden'] = true
              break
            case 'pwCopy':
              self.copyContent(rowData['login_password'])
              break
            default:
                break
          }
          break
        case 'login_user':
          switch (event.target.getAttribute('name')) {
            case 'userCopy':
              self.copyContent(rowData['login_user'])
              break
            default:
              break
          }
          break
        default:
          break
      }
      updatePostTable()
    },
    checkStatus: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
        if (self.action === 'none') {
          self.action = 'getStatus'
          // $('#get-status i').removeClass('fa-info-circle').addClass('fa-spin fa-circle-o-notch')

          self.updateStatusSelectedRow(self.$t('vps.table.tdChecking'))

          // Update status display text by jQuery for better performance than the dataTable
          self.submitPVEAction(self.$config.apiPath.vpsStatus, 'vm', self.selectedObjectList, function (rowDataObject, status) {
                self.updateStatusRowById(rowDataObject.id, status)
              },
              function () {
                // $('#get-status i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-info-circle')
                self.action = 'none'
              })
        } else {
          self.$toasted.error(this.$t('vps.toast.errPendingTask'))
        }
    },
    confirmVM: function (actionToConfirm) {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }

      if (this.action === 'none') {
        this.action = actionToConfirm
        this.initConfirmVMAction(self.selectedObjectList, actionToConfirm, function () {
          if (self.action === 'stop' || self.action === 'restart') { // means stop or restart
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('vps.modal.bodyDatalossWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          } else if (self.action === 'renew') {
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('vps.modal.bodyRenewWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          }
        })
        self.showModal(true)
      } else {
        self.$toasted.error(this.$t('vps.toast.errPendingTask'))
      }
    },
    editNote: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      let currentNote = self.selectedObjectList[0].user_remark
      if (self.selectedObjectList.length > 1) {
        currentNote = ''
      }
      self.confirmModalDisplayObject.title = '<h4>' + self.$t('vps.modal.titleEditRemark', ['<b style="color: red;">' + this.selectedObjectList.length + '</b>']) + '</h4>'
      self.confirmModalDisplayObject.bodyMsg = self.$t('vps.modal.bodyEditRemark')
      self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      for (let i = 0; i < self.selectedObjectList.length; i ++) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>' + self.getIPPortFormat(self.selectedObjectList[i]['ip'], self.selectedObjectList[i]['port']) + '</strong></li>'
      }
      self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      self.confirmModalDisplayObject.bodyMsg += self.$t('vps.label.yourNote')
      self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      self.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="editNote" aria-describedby="detail" value="' + currentNote + '">'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonEdit')

      self.action = 'editRemark'
      self.showModal(true)
    },
    consoleVM: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
        if (this.selectedObjectList.length === 1) {
          window.open('./#/vm/console/' + this.selectedObjectList[0].id, '_blank')
        } else {
          self.$toasted.error(self.$t('vps.toast.errSingleConsole'))
        }
    },
    generateRDP: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleRDP', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirmRDP')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('vps.modal.bodyRDPWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonRDP')

        self.action = 'confirmRDP'
        self.showModal(true)
      })
    },
    confirmExportTextFile: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleExportTextFile', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirmGenerateTextFile')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonGenerateTextFile')

        self.action = 'confirmExportTextFile'
        self.showModal(true)
      })
    },
    confirmExportExcelFile: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleExportExcelFile', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirmGenerateExcelFile')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonGenerateExcelFile')

        self.action = 'confirmExportExcelFile'
        self.showModal(true)
      })
    },
    rebuildVM: function () {
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        let self = this

        // Get the image list
        if (self.imageValues.imageListCache.length === 0) {
          this.getDataFromAPI(this.$config.apiPath.imageList, function (images) {
            self.imageValues.imageListCache = images.data.map(x => {
              return {id: x['id'], imageName: x['name'], description: x['description']}
            })
            if (images.data.length > 0) {
              self.imageValues.currentImageSelection = self.imageValues.imageListCache[0]
            }
            $('#modalRebuild').modal('show')
          })
        } else {
          $('#modalRebuild').modal('show')
        }
      }
    },
    takeSnap: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        if (this.selectedObjectList.length === 1) {
          let rowDataObject = this.selectedObjectList[0]
          this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirmSnap') + '</h4>'
          this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirmSnapshot')
          this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol><li><strong>'
          this.confirmModalDisplayObject.bodyMsg += this.getIPPortFormat(rowDataObject['ip'], rowDataObject['port'], rowDataObject['os_type']) + '</strong>'
          this.confirmModalDisplayObject.bodyMsg += '</li></ol></div>'
          this.confirmModalDisplayObject.bodyMsg += `
            <div class="input-group input-group-outline my-3">
              <label class="form-label">${this.$t('vps.modal.labelTypeNote')}</label>
              <input id="snapshotNote" type="text" class="form-control">
            </div>
          `
          this.confirmModalDisplayObject.bodyMsg += '<div class="text-danger">' + this.$t('vps.modal.bodySnapshotWarning') + '</div>'
          this.confirmModalDisplayObject.confirmButtonTitle = this.$t('vps.modal.buttonSnapshot')

          this.action = 'confirmSnapshot'
          self.showModal(true)
        } else {
          this.$toasted.error(this.$t('vps.toast.errSingleSnap'))
        }
      }
    },
    restoreVM: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        // Get the image list
        if (self.imageValues.snapshotListCache.length === 0) {
          this.getDataFromAPI(this.$config.apiPath.snapList, function (snapshots) {
            self.imageValues.snapshotListCache = snapshots.data.map(x => {
              return {
                id: x['id'],
                snapshotName: x['snapshot_name'],
                imageName: x['image_name'],
                note: x['note']
              }
            })
            $('#restore-snapshot-modal').modal('show')
          })
        } else {
          $('#restore-snapshot-modal').modal('show')
        }
      }
    },
    transfer: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirmTransfer', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirmTransfer')
      self.confirmModalDisplayObject.bodyMsg += '<div class="form-row mt-3 mb-3">'
      self.confirmModalDisplayObject.bodyMsg += '<label class="col-form-label col-3">Email</label>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      self.confirmModalDisplayObject.bodyMsg += '<input class="form-control col-9" id="transferEmailTarget">'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('vps.modal.bodyTransferWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonTransfer')

        self.action = 'confirmTransfer'
        self.showModal(true)
      })
    },
    teamMemberShare: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirmShare', ['<b style="color: red;">' + this.selectedObjectList.length + '</b>']) + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = '<div class="form-group"><label for="selectTeamMembers">' + self.$t('vps.modal.bodySelectTeamMember') + '</label><select class="form-select ps-3 mb-3" id="selectTeamMembers">'


        async.forEachOf(self.teamMembers, function (member, key, cb) {
          self.confirmModalDisplayObject.bodyMsg += '<option value="' + member.id + '">' + member.full_name + '</option>'
          cb()
        }, function () {
          self.confirmModalDisplayObject.bodyMsg += '</select></div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
          async.forEachOf(self.selectedObjectList, function (value, key, callback) {
            self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
            callback()
          }, function () {
            self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
            self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('vps.modal.bodyTeamMember') + '</div>'
          })
          self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonShare')
          self.confirmModalDisplayObject.additionalButton = {
            isShow: true,
            title: self.$t('vps.modal.buttonDeleteShare'),
            class: 'btn btn-danger'
          }

          self.action = 'teamMemberShare'
          self.showModal(true)
        })

      }
    },
    renew: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirmRenew', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="form-label">' + `${this.$t('vps.modal.period')} (${this.$t('vps.modal.month')})` + '</label>'
      this.confirmModalDisplayObject.bodyMsg += '<input id="periodRenew" class="form-control" type="number" min="1" max="24" value="1" onKeyDown="return false">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += this.$t('vps.modal.bodyConfirmRenew')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('vps.modal.bodyRenewWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonRenew')

        self.action = 'confirmRenew'
        self.showModal(true)
      })
    },
    change: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirmChange', [this.selectedObjectList.length]) + '</h4>'
      // this.confirmModalDisplayObject.bodyMsg = '<div class="input-group input-group-outline my-3">'
      // this.confirmModalDisplayObject.bodyMsg += '<label class="form-label">' + `${this.$t('vps.modal.period')} (${this.$t('vps.modal.month')})` + '</label>'
      // this.confirmModalDisplayObject.bodyMsg += '<input id="periodRenew" class="form-control" type="number" min="1" max="24" value="1" onKeyDown="return false">'
      // this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirmChange')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('vps.modal.bodyChangeWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonChange')

        self.action = 'confirmChangeVPS'
        self.showModal(true)
      })
    },
    setAutoRenew: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('vps.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirmAutoRenew', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirmAutoRenew')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('vps.modal.bodyAutoRenewWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonSetAutoRenew')
        self.confirmModalDisplayObject.additionalButton = {
          isShow: true,
          title: self.$t('vps.modal.buttonCancelAutoRenew'),
          class: 'btn btn-danger'
        }

        self.action = 'confirmAutoRenew'
        self.showModal(true)
      })
    },
    createRGC: function (rowData) {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        let self = this
        self.rgcObject.vmObject = rowData
        let buildImageSelection = function () {
          // Build the modal

          self.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>IP. ' + rowData['ip_port'] + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<strong class="d-block">Remote Port</strong>'
          self.confirmModalDisplayObject.bodyMsg += '<span class="smaller d-block">' + self.$t('vps.modal.bodyRGCCreateWarning') + '</span>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline mb-3">'
          self.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="rgcPortCreating" aria-describedby="detail" value="' + rowData['port'] + '">'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<div class="form-group"><label for="selectImageRebuild">' + self.$t('vps.modal.bodySelectRGC') + '</label><select class="form-select ps-3 mb-3" id="selectRGCServer">'
          async.forEachOf(self.rgcObject.serverList, function (item, key, cb) {
            self.confirmModalDisplayObject.bodyMsg += '<option value="' + item['id'] + '">' + item['rgc_server_name'] + ' (' + item['location'] + ')</option>'
            cb()
          }, function () {
            self.confirmModalDisplayObject.bodyMsg += '</select></div>'
            self.confirmModalDisplayObject.confirmButtonTitle = self.$t('vps.modal.buttonCreate')

            self.action = 'confirmCreateRGC'
            self.showModal(true)
          })
        }
        // // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirmCreateRGC') + '</h4>'

        // Get the image list
        if (self.rgcObject.serverList === null) {
          this.getDataFromAPI(this.$config.apiPath.rgcServerAvailable, function (result) {
            self.rgcObject.serverList = result.data['RGCServerObjectList']
            buildImageSelection()
          })
        } else {
          buildImageSelection()
        }
      }
    },
    deleteRGC: function (rowData) {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
        return
      }
      self.rgcObject.vmObject = rowData
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirmDeleteRGC') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirmDeleteRGC')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + rowData['ip_port'] + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('vps.modal.buttonDelete')

      this.action = 'confirmDeleteRGC'
      this.showModal(true)
    },
    onConfirmButton: function (inputObjectList) {
      let self = this
      if (this.action === 'start') {
        // $('#vm-start i').removeClass('fa-play').addClass('fa-spin fa-circle-o-notch')
        // Update status display text by jQuery for better performance than the dataTable
        self.updateStatusSelectedRow(self.$t('vps.table.tdStarting'))
        this.submitPVEAction(this.$config.apiPath.vpsStart, 'vm', self.selectedObjectList, function (rowDataObject, status) {
          self.updateStatusRowById(rowDataObject.id, status)
        }, function () {
          // $('#vm-start i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-play')
          // self.getSelectedRows().invalidate().draw(false)
          self.action = 'none'
        })
      } else if (this.action === 'stop') {
        // $('#vm-stop i').removeClass('fa-stop').addClass('fa-spin fa-circle-o-notch')
        self.updateStatusSelectedRow(self.$t('vps.table.tdStopping'))
        this.submitPVEAction(this.$config.apiPath.vpsStop, 'vm', self.selectedObjectList, function (rowDataObject, status) {
          self.updateStatusRowById(rowDataObject.id, status)
        }, function () {
          // $('#vm-stop i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-stop')
          // self.getSelectedRows().invalidate().draw(false)
          self.action = 'none'
        })
      } else if (this.action === 'restart') {
        // $('#vm-restart i').removeClass('fa-refresh').addClass('fa-spin fa-circle-o-notch')
        self.updateStatusSelectedRow(self.$t('vps.table.tdRestarting'))
        this.submitPVEAction(this.$config.apiPath.vpsRestart, 'vm', self.selectedObjectList, function (rowDataObject, status) {
          self.updateStatusRowById(rowDataObject.id, status)
        }, function () {
          // $('#vm-restart i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-refresh')
          // self.getSelectedRows().invalidate().draw(false)
          self.action = 'none'
        })
      } else if (this.action === 'confirmSnapshot') {
        self.updateStatusSelectedRow(self.$t('vps.table.tdSnapshoting'))
        this.submitObjectList(this.$config.apiPath.vpsSnapshot, {
          id: this.selectedObjectList[0]['id'],
          note: $('#snapshotNote').val()
            }, 'vm', undefined,
            function (snapshotResult) {
              self.$toasted.success(snapshotResult.msg)
              self.updateStatusSelectedRow(self.$t('vps.table.tdSuccess'))
              self.action = 'none'
            },
            function (result) {
              self.updateStatusSelectedRow(self.$t('vps.table.tdError'))
              self.action = 'none'
            })
      } else if (this.action === 'confirmRenew') {
        this.submitObjectList(this.$config.apiPath.vpsRenew,
            this.selectedObjectList.map(function (a) {
              return {
                id: a.id
              }
            }),
            'vm',
            {
              period: inputObjectList[0]['periodRenew']
            },
            function (renewResult) {
          self.$toasted.success(renewResult.msg)
          // $('#vm-renew i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-calendar')
          self.action = 'none'
          window.open('/#/home/order/detail/' + renewResult.data.id, '_blank')
        })
      } else if (this.action === 'confirmChangeVPS') {
        this.submitObjectList(this.$config.apiPath.vpsChange,
            this.selectedObjectList.map(function (a) {
              return {
                id: a.id
              }
            }),
            'vm',
            undefined,
            function (result) {
              self.$toasted.success(result.msg)
              // $('#vm-renew i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-calendar')
              self.action = 'none'
              window.open('/#/home/order/detail/' + result.data.id, '_blank')
            })
      } else if (this.action === 'confirmAutoRenew') {
        // $('#vm-autorenew i').removeClass('fa-thumbs-o-up').addClass('fa-spin fa-circle-o-notch')
        this.submitObjectList(this.$config.apiPath.vpsAutoRenew, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'vm', {autoRenew: 1}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          // $('#vm-autorenew i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-thumbs-o-up')
          for (let i = 0; i < self.selectedObjectList.length; i++) {
            let index = self.dataObjectList.findIndex(x => x.id == self.selectedObjectList[i].id)
            self.dataObjectList[index]['auto_renew'] = 1
          }
          self.action = 'none'
          self.confirmModalDisplayObject.additionalButton.isShow = false
        })
      } else if (this.action === 'confirmTransfer') {
        // $('#vm-transfer i').removeClass('fa-exchange').addClass('fa-spin fa-circle-o-notch')
        this.submitObjectList(this.$config.apiPath.vpsTransfer,
            this.selectedObjectList.map(function (a) { return {id: a.id} }),
            'vm',
            {
              targetEmail: inputObjectList[0]['transferEmailTarget'],
              otp_code: self.otp_code
            }, function (transferResult) {
          if (transferResult.data.is_enable_2fa) {
            self.otpConfirmAction = 'confirmTransfer'
            self.inputObjectList = inputObjectList
            $('#otp_modal').modal('show')
          } else {
            self.$toasted.success(transferResult.msg)
            // $('#vm-transfer i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-exchange')
            self.dataObjectList = self.dataObjectList.filter(ar => !self.selectedObjectList.find(rm => ar.id === rm.id))
            // $('#dataTable').DataTable().rows('.selected').remove().draw(false)
          }
        }, null, true, function () {
          self.action = 'none'
          self.otp_code = ''
          $('#otp_modal').modal('hide')
        })
      } else if (this.action === 'teamMemberShare') {
        let memberId = $('#selectTeamMembers').children('option:selected').val()
        let memberName = $('#selectTeamMembers').children('option:selected').text()
        this.submitObjectList(this.$config.apiPath.shareTeam,
            self.selectedObjectList.map(function (a) { return {id: a.id} }),
            'vm',
            {
              member_id: memberId
            }, function (transferResult) {
              self.$toasted.success(transferResult.msg)
              for (let i = 0; i < self.selectedObjectList.length; i++) {
                self.setItemProperty(self.selectedObjectList[i].id, 'co_owner_id', memberId)
                self.setItemProperty(self.selectedObjectList[i].id, 'co_owner_full_name', memberName)
              }
            }, null, true, function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmRDP') {
        // $('#vm-rdp i').removeClass('fa-windows').addClass('fa-spin fa-circle-o-notch')
        self.generateRDGFile(self.selectedObjectList, function (xml) {
          // $('#vm-rdp i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-windows')
          self.action = 'none'
        })
      } else if (this.action === 'confirmCreateRGC') {
        self.updateStatusRowById(self.rgcObject.vmObject.id, self.$t('vps.table.tdRGCCreating'))
        let rgcServerId = $('#selectRGCServer').children('option:selected').val()
        let portRGC = $('#rgcPortCreating')[0].value
        self.$toasted.info(self.$t('vps.toast.rgcCreating', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['os_type'])]))
        this.submitObjectList(this.$config.apiPath.rgcCreate, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id'],
          RGCServerID: rgcServerId,
          remotePort: portRGC
        },function (rowDataObject, status) {
          self.updateFieldRowById(self.rgcObject.vmObject['id'], 'rgc_server_name', rowDataObject.data['rgc_server_name'])
          self.updateFieldRowById(self.rgcObject.vmObject['id'], 'rgc_forwarded_port',rowDataObject.data['rgc_forwarded_port'])
          self.updateStatusRowById(self.rgcObject.vmObject.id, self.$t('vps.table.tdOK'))
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.updateStatusRowById(self.rgcObject.vmObject.id, self.$t('vps.table.tdError'))
          self.action = 'none'
        })
      } else if (this.action === 'confirmDeleteRGC') {
        self.updateStatusRowById(self.rgcObject.vmObject.id, self.$t('vps.table.tdRGCDeleting'))
        self.$toasted.info(self.$t('vps.toast.rgcDelete', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['os_type'])]))
        this.submitObjectList(this.$config.apiPath.rgcDelete, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id']
        },function (rowDataObject, status) {
          self.updateFieldRowById(self.rgcObject.vmObject['id'], 'rgc_server_name',null)
          self.updateFieldRowById(self.rgcObject.vmObject['id'], 'rgc_forwarded_port',null)
          self.$toasted.success(rowDataObject.msg)
          self.updateStatusRowById(self.rgcObject.vmObject.id, self.$t('vps.table.tdOK'))
        }, function () {
          self.updateStatusRowById(self.rgcObject.vmObject.id, self.$t('vps.table.tdError'))
          self.action = 'none'
        })
      } else if (this.action === 'confirmExportTextFile') {
        self.exportTextFile(self.selectedObjectList.map(function(element) {
          return element.id + '|' + element.ip + '|' + element.port + '|'
              + element.login_user + '|' + element.login_password + '|'
              + element.vm_plan_name + '|'
              + element.country + ' (' + element.state + ')' + '|'
              + self.convertISODate(element.end_date) + '|'
              + element.user_remark
        }), 'LowEndViet_VPS_Text_File_Export', function () {
          self.action = 'none'
        })
      } else if (this.action === 'confirmExportExcelFile') {
        let dataObject = self.selectedObjectList.map(function(element) {
          let result = {
            id: element.id,
            host: self.getIPPortFormat(element.ip, element.port),
            user: element.login_user,
            password: element.login_password,
            plan: element.vm_plan_name,
            location: element.country + ' (' + element.state + ')',
            end_date: self.convertISODate(element.end_date),
            note: element.user_remark
          }
          return result
        })

        self.exportExcelFile(dataObject, 'LowEndViet_VPS_Excel_File_Export', function () {
          self.action = 'none'
        })
      } else if (self.action === 'notification') {
        self.action = 'none'
      } else if (this.action === 'editRemark') {
        let self = this
        //let note = $('#editNote').val()
        this.submitObjectList(this.$config.apiPath.vpsNote, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'vm', {note: inputObjectList[0]['editNote']}, function (result) {
          self.$toasted.success(result.msg)
          for (let i = 0; i < self.selectedObjectList.length; i++) {
            self.setItemProperty(self.selectedObjectList[i].id, 'user_remark', inputObjectList[0]['editNote'])
          }

          // self.getSelectedRows().invalidate().draw(false)
          // self.finishModal()
        }, function () {
        })
      }
      self.finishModal()
    },
    onModalAdditionalButton: function () {
      let self = this
      if (this.action === 'confirmAutoRenew') {
        // $('#vm-autorenew i').removeClass('fa-thumbs-o-up').addClass('fa-spin fa-circle-o-notch')
        this.submitObjectList(this.$config.apiPath.vpsAutoRenew, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'vm', {autoRenew: 0}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          // $('#vm-autorenew i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-thumbs-o-up')
          for (let i = 0; i < self.selectedObjectList.length; i++) {
            let index = self.dataObjectList.findIndex(x => x.id == self.selectedObjectList[i].id)
            self.dataObjectList[index]['auto_renew'] = 0
          }
          self.action = 'none'
          self.confirmModalDisplayObject.additionalButton.isShow = false
        })
      } else if (this.action === 'teamMemberShare') {
        this.submitObjectList(this.$config.apiPath.cancelShare,
            this.selectedObjectList.map(function (a) { return {id: a.id} }),
            'vm',
            null, function (transferResult) {
              self.$toasted.success(transferResult.msg)
              for (let i = 0; i < self.selectedObjectList.length; i++) {
                self.setItemProperty(self.selectedObjectList[i].id, 'co_owner_id', null)
                self.setItemProperty(self.selectedObjectList[i].id, 'co_owner_full_name', null)
              }
            }, null, true, function () {
              self.action = 'none'
            })
      }
      self.finishModal()
    },
    finishAction: function () {
      this.action = 'none'
      this.confirmModalDisplayObject.additionalButton.isShow = false
    },
    onVPSFilterChange: function(event) {
      let self = this
      switch(event.target.id) {
        case 'btnradioAllVPS':
          self.dataObjectList = self.rawDataFromDB
          break
        case 'btnradioOverDueVPS':
          self.dataObjectList = self.getListVPSOverDue()
          break
        case 'btnradioSuspendVPS':
          self.dataObjectList = self.getListVPSSuspend()
          break
        case 'btnradioCancelVPS':
          self.dataObjectList = self.getListVPSCancel()
          break
        case 'btnradioUnknownVPS':
          self.dataObjectList = self.getListVPSUnknown()
          break
        case 'btnradioVPSShare':
          self.dataObjectList = self.getListVPSTeam()
        default:
          break
      }
    },
    updateStatusSelectedRow: function(status) {
      let self = this
      for (let i = 0; i < self.selectedObjectList.length; i ++) {
        let objectIndex = self.dataObjectList.findIndex(x => x.id === self.selectedObjectList[i].id)
        self.setItemProperty(self.dataObjectList[objectIndex].id, 'operation_status', status)
      }
    },
    updateStatusRowById: function(vmId, status) {
      this.setItemProperty(vmId, 'operation_status', status)
    },
    updateFieldRowById: function(vmId, field, value) {
      this.setItemProperty(vmId, field, value)
    },
    getListVPSOverDue: function () {
      let currentDate = new Date(this.getCurrentTime())
      return this.rawDataFromDB.filter(item => item.end_date <= this.convertISODatePlusDays(currentDate, 3))
    },
    getListVPSCancel: function () {
      return this.rawDataFromDB.filter(item => item.payment_status === 'cancel')
    },
    getListVPSSuspend: function () {
      return this.rawDataFromDB.filter(item => item.payment_status === 'suspend')
    },
    getListVPSUnknown: function () {
      return this.rawDataFromDB.filter(
          item => item.payment_status !== 'cancel'
              && item.payment_status !== 'ok'
              && item.payment_status !== 'suspend'
      )
    },
    getListVPSTeam: function () {
      return this.rawDataFromDB.filter(
          item => item['co_owner_id'] !== null
      )
    },
    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('detail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    },
    updateVisiableFields: function () {
      this.visibleFields = []
      if(this.themeSetting.vpsTableSetting.ip_port.isVisible) {
        this.visibleFields.push('ip_port')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'ip_port')
      }

      if(this.themeSetting.vpsTableSetting.login_user.isVisible) {
        this.visibleFields.push('login_user')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'login_user')
      }

      if(this.themeSetting.vpsTableSetting.login_password.isVisible) {
        this.visibleFields.push('login_password')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'login_password')
      }

      if(this.themeSetting.vpsTableSetting.price.isVisible) {
        this.visibleFields.push('price')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'price')
      }

      if(this.themeSetting.vpsTableSetting.vm_plan_name.isVisible) {
        this.visibleFields.push('vm_plan_name')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'vm_plan_name')
      }

      if(this.themeSetting.vpsTableSetting.location.isVisible) {
        this.visibleFields.push('location')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'location')
      }

      if(this.themeSetting.vpsTableSetting.start_date.isVisible) {
        this.visibleFields.push('start_date')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'start_date')
      }

      if(this.themeSetting.vpsTableSetting.end_date.isVisible) {
        this.visibleFields.push('end_date')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'end_date')
      }

      if(this.themeSetting.vpsTableSetting.backup_period.isVisible) {
        this.visibleFields.push('backup_period')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'backup_period')
      }

      if(this.themeSetting.vpsTableSetting.auto_renew.isVisible) {
        this.visibleFields.push('auto_renew')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'auto_renew')
      }

      if(this.themeSetting.vpsTableSetting.user_remark.isVisible) {
        this.visibleFields.push('user_remark')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'user_remark')
      }

      if(this.themeSetting.vpsTableSetting.payment_status.isVisible) {
        this.visibleFields.push('payment_status')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'payment_status')
      }

      if(this.themeSetting.vpsTableSetting.operation_status.isVisible) {
        this.visibleFields.push('operation_status')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'operation_status')
      }
    },
    confirmOTP: function (otp_code) {
      this.action = this.otpConfirmAction
      this.otp_code = otp_code
      this.onConfirmButton(this.inputObjectList)
      this.otpConfirmAction = 'none'
      $('#otp_modal').modal('hide')
    },
    imageSelectChange() {
      let imageId = document.getElementById("selectImageRebuild").selectedOptions[0].value
      this.imageValues.currentImageSelection = this.imageValues.imageListCache.find(x => x.id == imageId)
    },
    snapshotSelectChange() {
      let snapshotId = document.getElementById("selectSnapshotRestore").selectedOptions[0].value
      this.imageValues.currentSnapshotSelection = this.imageValues.snapshotListCache.find(x => x.id == snapshotId)
    },
    confirmRebuild() {
      let self = this
      self.updateStatusSelectedRow(self.$t('vps.table.tdRebuilding'))
      let reinstallImageID = {reinstallImageID: document.getElementById("selectImageRebuild").selectedOptions[0].value}
      this.submitObjectList(this.$config.apiPath.vpsRebuild, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'vm', reinstallImageID, function (rebuildResult) {
            self.$toasted.success(self.$t('vps.toast.okRebuildRequestSent', [self.selectedObjectList.length]))
            self.updateStatusSelectedRow(self.$t('vps.table.tdSuccess'))
            self.action = 'none'
          },
          function (rebuildResult) {
            async.forEachOf(self.selectedObjectList, function (rowDataObject, key, cb) {
              async.forEachOf(rebuildResult.data, function (result, key2, cb2) {
                if (result['id'] === rowDataObject['id']) {
                  self.updateStatusRowById(rowDataObject['id'], result['status'])
                  if (result['detail'] !== undefined) {
                    self.$toasted.error(self.getIPPortFormat(rowDataObject['ip'], rowDataObject['port']) + ' : ' + result['detail'])
                  }
                }
                cb2()
              }, function () {
                cb()
              })
            }, function () {
              self.action = 'none'
            })
          })
    },
    confirmRestore() {
      let self = this
      self.updateStatusSelectedRow(self.$t('vps.table.tdRestoring'))
      let snapshotID = {snapshotID: $('#selectSnapshotRestore').children('option:selected').val()}
      self.$toasted.success(self.$t('vps.toast.okRestoreRequestSent', [self.selectedObjectList.length]))
      this.submitObjectList(this.$config.apiPath.vpsRestore, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'vm', snapshotID, function (restoreResult) {
            self.$toasted.success(restoreResult.msg)
            self.updateStatusSelectedRow(self.$t('vps.table.tdSuccess'))
            self.action = 'none'
          },
          function (restoreResult) {
            async.forEachOf(self.selectedObjectList, function (rowDataObject, key, cb) {
              async.forEachOf(restoreResult.data, function (result, key2, cb2) {
                if (result['id'] === rowDataObject['id']) {
                  // Performance optimized: When the row is found, exit the loop
                  // rowDataObject['operation_status'] = result['status']
                  self.updateStatusRowById(rowDataObject['id'], result['status'])
                  if (result['detail'] !== undefined) {
                    self.$toasted.error(self.getIPPortFormat(rowDataObject['ip'], rowDataObject['port']) + ' : ' + result['detail'])
                  }
                }
                cb2()
              }, function () {
                cb()
              })
            }, function () {
              // self.getSelectedRows().invalidate().draw(false)
              self.action = 'none'
            })
          })
    }
  }
}
</script>
