<template>
  <div/>
</template>

<script>

import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'

export default {
  name: "JoinTeam.vue",
  mixins: [dataRequester, inputHelper],
  data: function () {
    return {

    }
  },
  created () {
    let self = this
    let privateKey = this.$route.query['invite_code']
    this.submitObjectList(this.$config.apiPath.joinTeam, {
      private_key: privateKey
    }, 'team', null, function (result) {
      self.$toasted.success(result.msg)
      window.location.href = '/#/home/team-manager'
      window.location.reload()
    })
  }
}
</script>

<style scoped>

</style>