<template>
  <div>
    <div class="row">
      <div class="col col-12 col-md-8">
        <div class="card mb-4 ">
          <div class="d-flex">
            <div class="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-xl mt-n3 ms-4">
              <i class="fas fa-user" />
            </div>
            <h6 class="mt-3 mb-2 ms-3 ">
              {{ $t('profile.cardHeader.title') }}
            </h6>
          </div>
          <div class="card-body pt-5">
            <div class="input-group input-group-static">
              <label class="form-label">{{ $t('profile.label.email') }}</label>
              <input
                id="email"
                v-model="currentUser['email']"
                type="email"
                class="form-control"
                required
              >
            </div>
            <div class="input-group input-group-static my-4">
              <label class="form-label">{{ $t('profile.label.phoneNo') }}</label>
              <input
                id="phoneNo"
                v-model="currentUser['phoneNo']"
                type="number"
                class="form-control"
                required
              >
            </div>
            <div class="input-group input-group-static my-4">
              <label class="form-label">{{ $t('profile.label.profileName') }}</label>
              <input
                id="full_name"
                v-model="currentUser['fullName']"
                type="text"
                class="form-control"
                required
              >
            </div>
            <div class="input-group input-group-static my-4">
              <label class="form-label">{{ $t('profile.label.facebookURL') }}</label>
              <input
                id="facebook"
                v-model="currentUser['facebook']"
                type="url"
                class="form-control"
                required
              >
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group input-group-static">
                <label class="form-label">{{ $t('profile.label.api') }}</label>
                <input
                  id="APIKey"
                  v-model="apiKey"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
              <button
                v-if="apiKey === '**************************'"
                class="btn bg-gradient-info btn-sm float-end mb-0 col-sm-2"
                @click.prevent="showApiKey('')"
              >
                <span class="fa fa-eye" /> {{ $t('profile.button.showApiKey') }}
              </button>
              <button
                v-if="apiKey !== '**************************'"
                class="btn bg-gradient-success btn-sm float-end mb-0 col-sm-2 ms-3"
                @click.prevent="generateNewAPI"
              >
                <span class="fa fa-edit" /> {{ $t('profile.button.generateAPI') }}
              </button>
              <button
                v-if="apiKey !== '**************************'"
                class="btn bg-gradient-primary btn-sm float-end mb-0 col-sm-2 ms-3"
                @click.prevent="updateApiKey('')"
              >
                <span class="fa fa-edit" /> {{ $t('profile.button.updateApiKey') }}
              </button>
            </div>

            <div class="text-center">
              <lev-button
                variant="primary"
                class="mt-3"
                @click="updateProfile('')"
              >
                <i class="fas fa-save me-1" />
                {{ $t('profile.button.updateProfile') }}
              </lev-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-4">
        <div class="card mb-4 ">
          <div class="d-flex">
            <div class="icon icon-shape icon-lg bg-gradient-info shadow text-center border-radius-xl mt-n3 ms-4">
              <i class="fas fa-laptop" />
            </div>
            <h6 class="mt-3 mb-2 ms-3 ">
              {{ $t('profile.cardHeader.session') }}
            </h6>
          </div>
          <div class="card-body pt-5">
            <div v-html="$t('profile.label.session', [currentUser['sessionId']?.split('|').length])" />
            <div class="text-center">
              <lev-button
                variant="danger"
                class="mt-5"
                @click="openModal('clear-session-modal')"
              >
                <i class="fas fa-sign-out-alt me-1" />
                {{ $t('profile.button.clearAllSessions') }}
              </lev-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- otp modal -->
    <div
      id="otp_modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otp_modal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <confirm2-fa
              @clicked-confirm-button="otpConfirm"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- confirm modal -->
    <new-confirm-modal
      id="clear-session-modal"
      :title="$t('profile.modal.clearSessionTitle')"
      @modalConfirm="clearSession"
    >
      <div v-html="$t('profile.warning.clearAllSessions')" />
    </new-confirm-modal>
  </div>
</template>

<script>
import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'
import { FixBugTheme } from '@/mixins/MaterialTheme';
import md5 from 'md5'
import confirm2Fa from "@/components/children/Confirm2Fa";
import LevButton from "@/components/children/lev_components/LevButton.vue";
import NewConfirmModal from "@/components/children/NewConfirmModal.vue";

export default {
  name: "Profile",
  components: {
    NewConfirmModal,
    confirm2Fa,
    LevButton
  },
  mixins: [dataRequester, inputHelper],
  props: {
    currentUser: {},
    metaData: {}
  },
  data: function () {
    return {
      apiKey: '**************************',
      actionType: 'none'
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    openModal (modalId) {
      $(`#${modalId}`).modal('show')
    },
    closeModal (modalId) {
      $(`#${modalId}`).modal('hide')
    },
    otpConfirm: function (otp_code) {
      switch (this.actionType) {
        case 'updateProfile':
          this.updateProfile(otp_code)
          break
        case 'showApiKey':
          this.showApiKey(otp_code)
          break
        case 'updateApiKey':
          this.updateApiKey(otp_code)
        default:
          break
      }
      $('#otp_modal').modal('hide')
    },
    generateNewAPI: function () {
      this.apiKey = md5(Math.random())
    },
    updateProfile: function (otp_code) {
      let self = this

      this.submitObjectList(this.$config.apiPath.updateProfile,
          {
            full_name: this.currentUser.fullName,
            email: this.currentUser.email,
            phone_no: this.currentUser.phoneNo,
            facebook: this.currentUser.facebook,
            // api_key: this.currentUser.APIKey
          },
          'user', {
            otp_code: otp_code
          }, function (result) {
            if (result.data['is_enable_2fa']) {
              self.actionType = 'updateProfile'
              $('#otp_modal').modal('show')
            } else {
              self.$toasted.success(result.msg)
              self.$cookies.set('email', self.currentUser.email, '90D')
            }
          }, undefined, true, function () {
            $('#otp_modal').modal('hide')
          })
    },
    updateApiKey: function (otp_code) {
      let self = this
      this.submitObjectList(this.$config.apiPath.updateApiKey,
          {
            api_key: self.apiKey
          },
          'user', {
            otp_code: otp_code
          }, function (result) {
            if (result.data['is_enable_2fa']) {
              self.actionType = 'updateApiKey'
              $('#otp_modal').modal('show')
            } else {
              self.$toasted.success(result.msg)
            }
          }, undefined, true, function () {
            $('#otp_modal').modal('hide')
          })
    },
    showApiKey: function (otp_code) {
      let self = this
      this.submitObjectList(this.$config.apiPath.showApiKey, null,
          null, {
            otp_code: otp_code
          }, function (result) {
            if (result.data['is_enable_2fa']) {
              self.actionType = 'showApiKey'
              $('#otp_modal').modal('show')
            } else {
              self.apiKey = result.data.APIKey
            }
          }, undefined, true, function () {
            $('#otp_modal').modal('hide')
          })
    },
    clearSession: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.clearSessions, null,
          'user', null, function (result) {
            self.$toasted.success(result.msg)
            self.closeModal('clear-session-modal')
            setTimeout(() => {
              window.location.reload()
            }, 800)
          })
    }
  }
}
</script>

<style scoped>

</style>
