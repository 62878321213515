<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex justify-content-start">
          <router-link to="/home/dedicated-server">
            <i class="fa fa-arrow-circle-left align-bottom">
              <span class="align-bottom"> Back</span>
            </i>
          </router-link>
          <span class="ml-2"><h4 class="text-info">&ensp;{{ getIPPortFormat(VMObject['ip'], VMObject['port']) }}</h4></span>
        </div>
      </div>
    </div>
    <div class="row margin-right-0">
      <div class="col-sm-8">
        <!--          card General Information-->
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">
              <span class="text-primary"><i class="fa fa-globe" /> {{ $t('dedicatedDetail.cardBody.titleGeneral') }}</span>
            </h6>
            <div class="table-responsive">
              <table class="table table-sm table-hover table-striped">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitleMainIP') }}
                    </td>
                    <td>
                      <span class="text-danger">
                        <strong>{{ getIPPortFormat(VMObject['ip'], VMObject['port'], VMObject['os_type']) }}</strong>
                        &ensp;
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="$t('dedicatedDetail.tooltip.tdClickToCopy')"
                          class="badge bg-gradient-primary cursor-pointer"
                          @click="copyContent(getIPPortFormat(VMObject['ip'], VMObject['port'], VMObject['os_type']))"
                        >
                          <span class="fa fa-copy" /> {{ $t('dedicatedDetail.table.tdClickToCopy') }}
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="VMObject['rgc_server_name'] !== null && VMObject['rgc_server_name'] !== undefined">
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitleRGCName') }}
                    </td>
                    <td>
                      <span class="text-success">
                        {{ VMObject['rgc_server_name'] }}:{{ VMObject['rgc_forwarded_port'] }}
                        &ensp;
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="$t('dedicatedDetail.tooltip.tdClickToCopy')"
                          class="badge bg-gradient-primary cursor-pointer"
                          @click="copyContent(VMObject['rgc_server_name'] + ':' + VMObject['rgc_forwarded_port'])"
                        >
                          <span class="fa fa-copy" /> {{ $t('dedicatedDetail.table.tdClickToCopy') }}
                        </span>
                      </span>
                    </td>
                  </tr>
                  <!--                      <tr>-->
                  <!--                        <td class="font-weight-bold">-->
                  <!--                          {{ $t('dedicatedDetail.table.tdTitleStatus') }}-->
                  <!--                        </td>-->
                  <!--                        <td><span v-html="VMOperStatus" /></td>-->
                  <!--                      </tr>-->
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitlePay') }}
                    </td>
                    <td><span v-html="VMPaymentStatus" /></td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitleLocation') }}
                    </td>
                    <td>{{ VMObject.country }} ({{ VMObject.state }})</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitleSpec') }}
                    </td>
                    <td>
                      <span class="text-wrap">
                        <strong class="text-info">{{ VMObject['plan_reseller_name'] }}</strong>
                        {{ VMObject['cpu_core'] }} Core, {{ VMObject['cpu_thread'] }} Thread, CPU {{ VMObject['cpu_name'] }},
                        {{ VMObject['ram'] }}GB RAM,
                        {{ VMObject['disk'] * VMObject['no_of_disk'] }}GB {{ VMObject['disk_type'] }} Disk,
                        Transfer {{ (VMObject['transfer_limit'] > 0) ? `${VMObject['transfer_limit']}GB`: 'Unlimited' }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitleOS') }}
                    </td>
                    <td><span :class="OSTypeIcon" /> {{ VMObject['image_name'] }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitleUser') }}
                    </td>
                    <td class="">
                      {{ VMObject['login_user'] }}
                      <span
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="$t('dedicatedDetail.tooltip.tdClickToCopy')"
                        class="badge bg-gradient-primary cursor-pointer"
                        @click="copyContent(VMObject['login_user'])"
                      >
                        <span class="fa fa-copy" /> {{ $t('dedicatedDetail.table.tdClickToCopy') }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitlePass') }}
                    </td>
                    <td class="">
                      {{ VMObject['login_password'] }}
                      <span
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="$t('dedicatedDetail.tooltip.tdClickToCopy')"
                        class="badge bg-gradient-primary cursor-pointer"
                        @click="copyContent(VMObject['login_password'])"
                      >
                        <span class="fa fa-copy" /> {{ $t('dedicatedDetail.table.tdClickToCopy') }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdTitleNote') }}
                    </td>
                    <td>
                      {{ VMObject.user_remark }}
                      <i
                        class="cursor-pointer fas fa-copy text-primary btn-tooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-container="body"
                        data-animation="true"
                        :title="$t('dedicatedDetail.tooltip.copyRemark')"
                        name="copyNote"
                        @click="copyContent(VMObject.user_remark)"
                      />
                      <label />
                      <i
                        class="cursor-pointer fas fa-edit text-info btn-tooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-container="body"
                        data-animation="true"
                        :title="$t('dedicatedDetail.tooltip.editRemark')"
                        name="editNote"
                        @click="editRemark"
                      />
                    </td>
                  </tr>
                  <tr v-if="currentUser['team_private_key'] === null && VMObject['co_owner_id'] !== null">
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdOwner') }}
                    </td>
                    <td>
                      <a
                        :href="`https://facebook.com/${VMObject['owner_facebook_uid']}`"
                        target="_blank"
                      ><i class="fab fa-facebook-square" /> {{ VMObject['owner_full_name'] }}</a>
                    </td>
                  </tr>
                  <tr v-if="currentUser['team_private_key'] !== null && VMObject['co_owner_id'] !== null">
                    <td class="font-weight-bold">
                      {{ $t('dedicatedDetail.table.tdCo_Owner') }}
                    </td>
                    <td>
                      <a
                        :href="`https://facebook.com/${VMObject['co_owner_facebook_uid']}`"
                        target="_blank"
                      ><i class="fab fa-facebook-square" /> {{ VMObject['co_owner_full_name'] }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--            control button-->
        <div class="card mt-3">
          <div class="card-body">
            <h6 class="card-title">
              <span class="text-primary"><i class="fas fa-cog" /> {{ $t('dedicatedDetail.cardBody.titleControl') }}</span>
            </h6>
            <div class="row">
              <div name="power button">
                <button
                  class="btn btn-detail-control btn-success"
                  @click="confirmVM('start')"
                >
                  <i class="fa fa-play fa-2x" /><br>{{ $t('dedicatedDetail.button.start') }}
                </button>
                <button
                  class="btn btn-detail-control btn-info"
                  @click="confirmVM('shutdown')"
                >
                  <i class="fa fa-power-off fa-2x" /><br>{{ $t('dedicatedDetail.button.shutdown') }}
                </button>
                <button
                  class="btn btn-detail-control btn-danger"
                  @click="confirmVM('stop')"
                >
                  <i class="fa fa-stop fa-2x" /><br>{{ $t('dedicatedDetail.button.stop') }}
                </button>
                <button
                  class="btn btn-detail-control btn-warning"
                  @click="confirmVM('restart')"
                >
                  <i class="fa fa-refresh fa-2x" /><br>{{ $t('dedicatedDetail.button.restart') }}
                </button>
              </div>
              <div name="remote button">
                <!--                <button-->
                <!--                  class="btn btn-detail-control btn-outline-info"-->
                <!--                  @click="consoleVM"-->
                <!--                >-->
                <!--                  <span class="fa-stack fa-1x">-->
                <!--                    <i class="fa fa-television fa-stack-2x" />-->
                <!--                    <span class="text-center">VNC</span>-->
                <!--                  </span><br>-->
                <!--                  {{ $t('dedicatedDetail.button.console') }}-->
                <!--                </button>-->
                <button
                  class="btn btn-detail-control btn-outline-info"
                  @click="generateRDPFile"
                >
                  <i class="fa fa-windows fa-2x" /><br>{{ $t('dedicatedDetail.button.rdp') }}
                </button>
                <button
                  v-if="VMObject['rgc_server_name'] === null || VMObject['rgc_server_name'] === undefined"
                  class="btn btn-detail-control btn-outline-success"
                  @click="createRGC(VMObject)"
                >
                  <i class="fas fa-link fa-2x" /><br>{{ $t('dedicatedDetail.button.createRGC') }}
                </button>
                <button
                  v-if="VMObject['rgc_server_name'] !== null && VMObject['rgc_server_name'] !== undefined"
                  class="btn btn-detail-control btn-outline-danger"
                  @click="deleteRGC(VMObject)"
                >
                  <i class="fas fa-unlink fa-2x" /><br>{{ $t('dedicatedDetail.button.deleteRGC') }}
                </button>
              </div>
              <div
                v-if="!isTeamMember"
                name="other button"
              >
                <button
                  v-if="currentUser['team_private_key'] !== null"
                  class="btn btn-detail-control btn-outline-info"
                  @click="teamMemberShare"
                >
                  <i class="fas fa-users fa-2x" /><br>{{ $t('dedicatedDetail.button.team') }}
                </button>
                <button
                  class="btn btn-detail-control btn-outline-warning"
                  @click="transfer"
                >
                  <i class="fa fa-2x fa-exchange" /><br>{{ $t('dedicatedDetail.button.transfer') }}
                </button>
                <button
                  class="btn btn-detail-control btn-outline-primary"
                  @click="renew"
                >
                  <i class="fa fa-2x fa-calendar" /><br>{{ $t('dedicatedDetail.button.renew') }}
                </button>
                <button
                  v-if="VMObject['auto_renew'] === 0"
                  class="btn btn-detail-control btn-outline-success"
                  @click="setAutoRenew"
                >
                  <i class="fa fa-2x fa-thumbs-o-up" /><br>{{ $t('dedicatedDetail.button.setAutoRenew') }}
                </button>
                <button
                  v-else
                  class="btn btn-detail-control btn-outline-danger"
                  @click="setAutoRenew"
                >
                  <i class="fa fa-2x fa-hand-stop-o" /><br>{{ $t('dedicatedDetail.button.cancelAutoRenew') }}
                </button>
                <button v-if="VMObject['payment_status'] === 'ok'"
                  class="btn btn-detail-control btn-outline-danger"
                  @click="openModalCancel"
                >
                  <i class="fa fa-2x fa-times" /><br>{{ $t('dedicatedDetail.button.cancel') }}
                </button>
                <button v-if="VMObject['payment_status'] === 'cancel'"
                    class="btn btn-detail-control btn-outline-success"
                    @click="revokeCancellation"
                >
                  <i class="fa fa-2x fa-times" /><br>{{ $t('dedicatedDetail.button.revokeCancellation') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <!--            card Payment Information-->
          <div class="card">
            <div class="card-body">
              <h6 class="card-title">
                <span class="text-primary">
                  <i class="fa fa-puzzle-piece" /> {{ $t('dedicatedDetail.cardBody.titlePayment') }}
                </span>
              </h6>
              <div class="table-responsive">
                <table class="table table-sm table-hover table-striped table-responsive">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleStart') }}
                      </td>
                      <td>{{ convertISODate(VMObject['start_date']) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleEnd') }}
                      </td>
                      <td>{{ convertISODate(VMObject['end_date']) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleAutoRenew') }}
                      </td>
                      <td
                        v-if="VMObject['auto_renew'] === 1"
                        class="text-success"
                      >
                        {{ $t('dedicatedDetail.table.tdAutoRenewEnabled') }}
                      </td>
                      <td
                        v-else
                        class="text-danger"
                      >
                        {{ $t('dedicatedDetail.table.tdAutoRenewDisabled') }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitlePlan') }}
                      </td>
                      <td>{{ VMObject['dedicated_plan_name'] }} (<span class="text-primary">${{ VMObject.price_output }} {{ $t('dedicatedDetail.table.tdDataEachMonth') }}</span>)</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleTotalAddonPrice') }}
                      </td>
                      <td><span class="text-primary">${{ VMObject.addon_revenue !== null ? VMObject.addon_revenue : 0 }} {{ $t('dedicatedDetail.table.tdDataEachMonth') }}</span></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleTotalPrice') }}
                      </td>
                      <td><span class="text-primary">${{ parseFloat(VMObject.price_output) + parseFloat(VMObject.addon_revenue !== null ? VMObject.addon_revenue : 0) }} {{ $t('dedicatedDetail.table.tdDataEachMonth') }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--            card Network Information-->
          <div class="card mt-3">
            <div class="card-body">
              <h6 class="card-title">
                <span class="text-primary"><i class="fa fa-wifi" /> {{ $t('dedicatedDetail.cardBody.titleNetwork') }}</span>
              </h6>
              <div class="table-responsive">
                <table
                  class="table table-sm table-hover table-striped"
                  style="table-layout: fixed"
                >
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">
                        <div class="text-wrap">
                          {{ $t('dedicatedDetail.table.tdTitleOutgoing') }}
                        </div>
                      </td>
                      <td><span class="text-success"><strong>{{ VMObject['ip'] }}</strong></span></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleDedicatedIP') }}
                      </td>
                      <td>{{ VMObject['no_ipv4'] }}</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div class="text-wrap">
                          <small>{{ $t('dedicatedDetail.label.localNetwork') }}</small>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleLocalIP') }}
                      </td>
                      <td>{{ VMObject['local_ip'] }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleLocalMask') }}
                      </td>
                      <td>{{ VMObject['local_netmask'] }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleLocalGw') }}
                      </td>
                      <td>{{ VMObject['local_gateway'] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--            card Addon-->
          <div class="card mt-3">
            <div class="card-body">
              <h6 class="card-title">
                <span class="text-primary">
                  <i class="fa fa-puzzle-piece" /> {{ $t('dedicatedDetail.cardBody.titleAddon') }}
                </span>
              </h6>
              <div class="table-responsive">
                <table class="table table-sm table-hover table-striped">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleBackup') }}
                      </td>
                      <td v-if="VMObject['backup_period'] === 7">
                        Weekly
                      </td>
                      <td v-else-if="VMObject['backup_period'] === 1">
                        Daily
                      </td>
                      <td v-else>
                        No
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('dedicatedDetail.table.tdTitleAddon') }}
                      </td>
                      <td v-if="VMAddonObject.activeAddonObjectList === undefined || VMAddonObject.activeAddonObjectList.length === 0">
                        No
                      </td>
                      <!-- To prevent the undefined to JSON.parse -->
                      <td v-else-if="VMAddonObject.activeAddonObjectList.length > 0">
                        <table class="table table-sm table-hover table-striped">
                          <thead>
                            <tr>
                              <td><strong class="text-info">#</strong></td>
                              <td><strong class="text-info">{{ $t('dedicatedDetail.table.tdTitleAddonName') }}</strong></td>
                              <td><strong class="text-info">{{ $t('dedicatedDetail.table.tdTitleAddonPrice') }}</strong></td>
                              <td><strong class="text-info">{{ $t('dedicatedDetail.table.tdTitleAddonAmount') }}</strong></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(addon, index) in VMAddonObject.activeAddonObjectList"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td class="text-wrap">
                                {{ addon['name'] }}
                              </td>
                              <td>${{ addon['price'] === undefined ? 0:addon['price'].toFixed(2) }}</td>
                              <td>{{ addon['amount'] }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      :action="action"
      :active="active"
      :confirm-modal-display-object="confirmModalDisplayObject"
      @modalCancel="finishModal"
      @modalConfirm="onConfirmButton('')"
      @modalAdditionalButton="onModalAdditionalButton"
      @finishAction="finishAction"
    />
    <!-- otp modal -->
    <div
      id="otp_modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otp_modal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <confirm2-fa
              @clicked-confirm-button="confirmOTP"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- cancel modal -->
    <new-confirm-modal
        id="cancel-modal"
        :title="$t('dedicatedDetail.modal.titleConfirmCancel')"
        :confirm-button="$t('dedicatedDetail.modal.buttonCancelVPS')"
        @modalConfirm="confirmCancel"
    >
      <div>
        <span v-html="$t('dedicatedDetail.modal.bodyConfirmCancel')"/>
        <div class="alert modal-alert-info text-left">
          <ol>
            <li
                v-for="(item, index) in [VMObject]"
                :key="index"
            >
              <strong>{{ item['ip'] }}</strong>
            </li>
          </ol>
        </div>
        <div class="d-flex d-inline">
          <span>{{ $t('dedicatedDetail.modal.bodySelectCancelMode') }}</span>
          <div class="form-check mb-3">
            <input value="end-of-billing" class="form-check-input" type="radio" name="rbCancallation" id="rbCancallation1" checked>
            <label class="custom-control-label" for="rbCancallation1">{{ $t('dedicatedDetail.modal.bodyCancelModeEndOfBilling') }}</label>
          </div>
          <div class="form-check">
            <input value="immediate" class="form-check-input" type="radio" name="rbCancallation" id="rbCancallation2">
            <label class="custom-control-label" for="rbCancallation2">{{ $t('dedicatedDetail.modal.bodyCancelModeImmediate') }}</label>
          </div>
        </div>
        <div class="alert modal-alert-secondary">
          <div v-html="$t('dedicatedDetail.modal.bodyCancelWarning')"/>
        </div>
      </div>
    </new-confirm-modal>
  </div>
</template>

<script>
// @todo: This screen and VPS screen share a lot of function/code. Consider to write children component
import async from 'async'

import confirmModal from '@/components/children/ConfirmModal'

import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'

import RDPGenerator from '@/mixins/utilities/RDPGenerator'
import confirm2Fa from "@/components/children/Confirm2Fa";
import {FixBugTheme} from "@/mixins/MaterialTheme";
import NewConfirmModal from "@/components/children/NewConfirmModal.vue";

export default {
  name: 'DedicatedDetail',
  components: {
    NewConfirmModal,
    confirmModal,
    confirm2Fa
  },
  mixins: [dataRequester, inputHelper, confirmModalBuilder, RDPGenerator],
  props: {
    metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    },
  },
  data: function () {
    return {
      action: 'none',
      VMObject: {},
      VMAddonObject: {
        activeAddonObjectList: []
      },
      rgcObject: {
        serverList: null,
        vmObject: null
      },
      OSTypeIcon: '',
      imageValues: {
        imageListCache: [],
        selectedImageId: 0,
        snapshotListCache: [],
        selectedSnapshotId: 0
      },
      teamMembers: []
    }
  },
  computed: {
    isTeamMember: function () {
      return this.VMObject['co_owner_id'] === this.currentUser['userID']
    },
    VMPaymentStatus () {
      switch(this.VMObject['payment_status']) {
        case 'ok':
          if (this.VMObject['end_date'] <= this.getCurrentTime()) {
            return '<span class="badge badge-warning"><span>' + this.$t('dedicatedDetail.table.tdDataOverdue') + '</span></span>'
          } else {
            return '<span class="badge badge-success"><span>' + this.$t('dedicatedDetail.table.tdDataOK') + '</span></span>'
          }
        case 'cancel':
          return '<span class="badge badge-dark"><span>' + this.$t('dedicatedDetail.table.tdDataCancel') + '</span></span>'
        case 'suspend':
          return '<span class="badge badge-danger"><span>' + this.$t('dedicatedDetail.table.tdDataSuspend') + '</span></span>'
        default:
          return '<span class="badge badge-info"><span>' + this.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
      }
    }
  },
  updated() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    this.submitObjectList(this.$config.apiPath.dedicatedDetail, {id: this.$route.params.VMID}, 'dedicated', undefined, function (result) {
      self.VMObject = result.data.VM
      self.submitObjectList(self.$config.apiPath.dedicatedAddon, {
        serviceTypeID: result.data.VM.service_type_id,
        serviceID: result.data.VM.id
      }, 'addon', undefined, function (result) {
        self.VMAddonObject = result.data
      })
      // Display OS icon
      if (self.VMObject['os_type'] === 'Windows') {
        self.OSTypeIcon = 'text-info fa fa-windows'
      } else if (self.VMObject['os_type'] === 'Linux') {
        self.OSTypeIcon = 'text-warning fa fa-linux'
      }
    })
    this.getDataFromAPI(this.$config.apiPath.listMemberOfTeam, function (result) {
      self.teamMembers = result.data['users'].filter(x => x['id'] !== self.currentUser['userID'])
    }, null, false)
    // this.submitObjectList(this.$config.apiPath.dedicatedStatus, {id: this.$route.params.VMID}, 'dedicated', undefined, function (result) {
    //   if (result.data.VMStatus === 'Running') {
    //     self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('dedicatedDetail.table.tdDataRunning') + '</span></span>'
    //   } else if (result.data.VMStatus === 'Stopped') {
    //     self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('dedicatedDetail.table.tdDataStopped') + '</span></span>'
    //   } else if (result.data.VMStatus === 'Suspended') {
    //     self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-warning"><span>' + self.$t('dedicatedDetail.table.tdDataSuspend') + '</span></span>'
    //   } else {
    //     self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-info"><span>' + result.data.VMStatus + '</span></span>'
    //   }
    // })
  },
  methods: {
    FixBugTheme,
    finishAction: function () {
      this.action = 'none'
    },
    confirmVM: function (actionToConfirm) {
      let self = this
      if (this.action === 'none') {
        this.action = actionToConfirm
        this.initConfirmVMAction([this.VMObject], actionToConfirm, function () {
          if (self.action === 'stop' || self.action === 'restart') { // means stop or restart
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('dedicatedDetail.modal.bodyDatalossWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          } else if (self.action === 'renew') {
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('dedicatedDetail.modal.bodyRenewWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          }
        })
        self.showModal(true)
      } else {
        self.$toasted.error(this.$t('dedicatedDetail.toast.errPendingTask'))
      }
    },
    onConfirmButton: function (otp_code) {
      let self = this
      if (this.action === 'start') {
        // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('dedicatedDetail.table.tdDataStarting') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.dedicatedStart, {id: this.VMObject['id']}, 'dedicated', undefined, function (result) {
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('dedicatedDetail.table.tdDataRunning') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.action = 'none'
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
        })
      } else if (this.action === 'shutdown') {
        // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('dedicatedDetail.table.tdDataShuttingDown') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.dedicatedShutdown, {id: this.VMObject['id']}, 'dedicated', undefined, function (result) {
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('dedicatedDetail.table.tdDataStopped') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          // self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'stop') {
        // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('dedicatedDetail.table.tdDataStopping') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.dedicatedStop, {id: this.VMObject['id']}, 'dedicated', undefined, function (result) {
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('dedicatedDetail.table.tdDataStopped') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'restart') {
        // self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('dedicatedDetail.table.tdDataRestarting') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.dedicatedRestart, {id: this.VMObject['id']}, 'dedicated', undefined, function (result) {
          // self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('dedicatedDetail.table.tdDataRunning') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          // self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'editRemark') {
        let self = this
        let note = $('#editRemark').val()
        this.submitObjectList(this.$config.apiPath.dedicatedNote, {id: self.VMObject['id']}, 'dedicated', {note: note}, function (result) {
          self.$toasted.success(result.msg)
          self.action = 'none'
          self.VMObject['user_remark'] = note
        }, function () {
        })
      } else if (this.action === 'confirmTransfer') {
        this.submitObjectList(this.$config.apiPath.dedicatedTransfer,
            {id: self.VMObject['id']}, 'dedicated',
            {
              targetEmail: $('#transferEmailTarget').val(),
              otp_code: otp_code
            },
            function (transferResult) {
              if (transferResult.data.is_enable_2fa) {
                $('#otp_modal').modal('show')
                self.otpConfirmAction = 'confirmTransfer'
              } else {
                self.$toasted.success(transferResult.msg)
                self.$router.push('/home/dedicated-server')
              }
            },
            null, true, function () {
              self.action = 'none'
              $('#otp_modal').modal('hide')
            })
      } else if (this.action === 'teamMemberShare') {
        let memberId = $('#selectTeamMembers').children('option:selected').val()
        this.submitObjectList(this.$config.apiPath.shareTeamDedicated,
            {
              id: self.VMObject['id']
            },
            'dedicated',
            {
              member_id: memberId
            }, function (transferResult) {
              window.location.reload()
              self.$toasted.success(transferResult.msg)
            }, null, true, function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmRenew') {
        let periodRenew = $('#periodRenew').val()
        this.submitObjectList(this.$config.apiPath.dedicatedRenew,
            {id: this.VMObject.id}, 'dedicated',
            {period: periodRenew}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          self.action = 'none'
          window.open('/#/home/order/detail/' + renewResult.data.id, '_blank')
        })
      } else if (this.action === 'confirmAutoRenew') {
        this.submitObjectList(this.$config.apiPath.dedicatedAutoRenew, {id: this.VMObject.id}, 'dedicated', {autoRenew: (self.VMObject['auto_renew'] * -1) + 1}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          self.action = 'none'
          self.VMObject['auto_renew'] = (self.VMObject['auto_renew'] * -1) + 1
        })
      } else if (this.action === 'confirmRevokeCancel') {
        this.submitObjectList(this.$config.apiPath.dedicatedRevokeCancel,
            {id: this.VMObject.id},
            'dedicated',
            null,
            function (revokeResult) {
              self.$toasted.success(revokeResult.msg)
              self.action = 'none'
              self.VMObject['payment_status'] = 'ok'
            },
            function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmCreateRGC') {
        let rgcServerId = $('#selectRGCServer').children('option:selected').val()
        let portRGC = $('#rgcPortCreating')[0].value
        self.$toasted.info(self.$t('dedicatedDetail.toast.rgcCreating', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['osType'])]))
        this.submitObjectList(this.$config.apiPath.rgcCreate, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id'],
          RGCServerID: rgcServerId,
          remotePort: portRGC
        },function (rowDataObject, status) {
          self.VMObject['rgc_server_name'] = rowDataObject.data['rgc_server_name']
          self.VMObject['rgc_forwarded_port'] = rowDataObject.data['rgc_forwarded_port']
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'confirmDeleteRGC') {
        self.$toasted.info(self.$t('dedicatedDetail.toast.rgcDelete', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['osType'])]))
        this.submitObjectList(this.$config.apiPath.rgcDelete, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id']
        },function (rowDataObject, status) {
          self.VMObject['rgc_server_name'] = null
          self.VMObject['rgc_forwarded_port'] = null
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'showAddonDetail') {
        self.action = 'none'
      }
      self.finishModal()
    },
    onModalAdditionalButton: function () {
      let self = this
      if (this.action === 'teamMemberShare') {
        this.submitObjectList(this.$config.apiPath.cancelShareDedicated,
            {id: self.VMObject.id},
            'dedicated',
            null, function (transferResult) {
              self.$toasted.success(transferResult.msg)
              self.VMObject['co_owner_id'] = null
              self.VMObject['co_owner_full_name'] = null
              self.VMObject['co_owner_facebook_uid'] = null
            }, null, true, function () {
              self.action = 'none'
            })
      }
      self.finishModal()
    },
    editRemark: function () {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleAddonDetail', [this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port'])]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyEditRemark')
      this.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="editRemark" aria-describedby="detail" value="' + this.VMObject['user_remark'] + '">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicatedDetail.modal.buttonEditRemark')
      this.action = 'editRemark'
      this.showModal(true)
    },
    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('dedicatedDetail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    },
    showAddonDetail: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleAddonDetail') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(JSON.parse(this.VMObject['addon_detail']), function (addon, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<strong>' + (key + 1) + ': </strong>'
        self.confirmModalDisplayObject.bodyMsg += addon['amount'] + ' x ' + addon['addon_name'] + ' ($' + addon['addon_price'] + self.$t('dedicatedDetail.table.tdDataEachMonth') + ') <br/>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.confirmButtonTitle = 'OK'
        self.action = 'showAddonDetail'
        self.showModal(true)
      })
    },
    consoleVM: function () {
      window.open('./#/vm/console/' + this.VMObject.id, '_blank')
    },
    generateRDPFile: function () {
      let self = this
      this.generateSingleRDP(self.VMObject, function (xml) {
        self.action = 'none'
      })
    },
    transfer: function () {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmTransfer') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmTransfer')
      this.confirmModalDisplayObject.bodyMsg += '<div class="form-row mt-3 mb-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="col-form-label col-3">Email</label>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<input class="form-control col-9" id="transferEmailTarget">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      this.confirmModalDisplayObject.bodyMsg += '<strong>Transfer IP:  ' + this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + this.$t('dedicatedDetail.modal.bodyTransferWarning') + '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicatedDetail.modal.buttonTransfer')

      this.action = 'confirmTransfer'
      this.showModal(true)
    },
    teamMemberShare: function () {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmShare') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = '<div class="form-group"><label for="selectTeamMembers">' + self.$t('dedicatedDetail.modal.bodySelectTeamMember') + '</label><select class="form-select ps-3 mb-3" id="selectTeamMembers">'


        async.forEachOf(self.teamMembers, function (member, key, cb) {
          self.confirmModalDisplayObject.bodyMsg += '<option value="' + member.id + '">' + member.full_name + '</option>'
          cb()
        }, function () {
          self.confirmModalDisplayObject.bodyMsg += '</select></div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>Share IP:  ' + self.getIPPortFormat(self.VMObject['ip'], self.VMObject['port']) + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicatedDetail.modal.bodyTeamMember') + '</div>'
          self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonShare')
          self.confirmModalDisplayObject.additionalButton = {
            isShow: true,
            title: self.$t('dedicatedDetail.modal.buttonDeleteShare'),
            class: 'btn btn-danger'
          }

          self.action = 'teamMemberShare'
          self.showModal(true)
        })

      }
    },
    renew: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmRenew') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="form-label">' + `${this.$t('dedicatedDetail.modal.period')} (${this.$t('dedicatedDetail.modal.month')})` + '</label>'
      this.confirmModalDisplayObject.bodyMsg += '<input id="periodRenew" class="form-control" type="number" min="1" max="24" value="1" onKeyDown="return false">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += this.$t('dedicatedDetail.modal.bodyConfirmRenew')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicatedDetail.modal.bodyRenewWarning') + '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonRenew')

      self.action = 'confirmRenew'
      self.showModal(true)
    },
    setAutoRenew: function () {
      let self = this
      if (this.VMObject['auto_renew'] === 0) {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmSetAutoRenew') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmSetAutoRenew')
      } else {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmCancelAutoRenew') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmCancelAutoRenew')
      }

      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      if (this.VMObject['auto_renew'] === 0) {
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonSetAutoRenew')
      } else {
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonCancelAutoRenew')
      }
      self.action = 'confirmAutoRenew'
      self.showModal(true)
    },
    revokeCancellation: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmRevokeCancel') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmRevokeCancel')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicatedDetail.modal.buttonRevokeCancel')

      this.action = 'confirmRevokeCancel'
      this.showModal(true)
    },
    createRGC: function (rowData) {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        let self = this
        self.rgcObject.vmObject = rowData
        let buildImageSelection = function () {
          self.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>IP. ' + self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['osType']) + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<strong>Remote Port</strong>'
          self.confirmModalDisplayObject.bodyMsg += '<span class="smaller d-block">Nếu quý khách dùng custom remote port khác port remote mặc định thì điền vào đây</span>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline mb-3">'
          self.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="rgcPortCreating" aria-describedby="detail" value="' + rowData['port'] + '">'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<div class="form-group"><label for="selectImageRebuild">' + self.$t('dedicatedDetail.modal.bodySelectRGC') + '</label><select class="form-select ps-3 mb-3" id="selectRGCServer">'

          async.forEachOf(self.rgcObject.serverList, function (item, key, cb) {
            self.confirmModalDisplayObject.bodyMsg += '<option value="' + item['id'] + '">' + item['rgc_server_name'] + ' (' + item['location'] + ')</option>'
            cb()
          }, function () {
            self.confirmModalDisplayObject.bodyMsg += '</select></div>'
            self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonCreate')

            self.action = 'confirmCreateRGC'
            self.showModal(true)
          })
        }
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmCreateRGC') + '</h4>'

        // Get the image list
        if (self.rgcObject.serverList === null) {
          this.getDataFromAPI(this.$config.apiPath.rgcServerAvailable, function (result) {
            self.rgcObject.serverList = result.data['RGCServerObjectList']
            buildImageSelection()
          })
        } else {
          buildImageSelection()
        }
      }
    },
    deleteRGC: function (rowData) {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('dedicatedDetail.toast.errPendingTask'))
        return
      }
      self.rgcObject.vmObject = rowData
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmDeleteRGC') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmDeleteRGC')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['osType']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicatedDetail.modal.buttonDelete')

      this.action = 'confirmDeleteRGC'
      this.showModal(true)
    },
    confirmOTP: function (otp_code) {
      this.action = this.otpConfirmAction
      this.onConfirmButton(otp_code)
      this.otpConfirmAction = 'none'
      $('#otp_modal').modal('hide')
    },
    openModalCancel() {
      $('#cancel-modal').modal('show')
    },
    confirmCancel: function () {
      $('#cancel-modal').modal('hide')
      let self = this
      let cancelMode = $('input[name=rbCancallation]:checked').val()
      this.submitObjectList(this.$config.apiPath.dedicatedCancel,
          {id: self.VMObject['id']}, 'dedicated',
          {cancelMode: cancelMode},
          function (revokeResult) {
            self.$toasted.success(revokeResult.msg)
            self.VMObject['payment_status'] = 'cancel'
            if (cancelMode === 'immediate') {
              self.$router.push('/home/dedicated-server')
            }
          })
    },
  }
}
</script>

<style scoped>

</style>
