import axios from 'axios'
import async from "async";

export default {
  methods: {
    getDataFromAPI: function (apiPath, onSuccess = undefined, onError = undefined, toastedError = true) {
      let self = this
      axios.post(apiPath, {
        username: this.$cookies.get('username'),
        sessionId: this.$cookies.get('sessionId'),
        lang: this.$i18n.locale
      })
        .then(function (response) {
          if (response.data.status === 'success') {
            if (typeof onSuccess === 'function') {
              onSuccess(response.data)
            }
          } else {
            if (toastedError) {
              self.$toasted.error(response.data.msg)
            }
            if (typeof onError === 'function') {
              onError(response.data)
            }
          }
        })
        .catch(function (err) {
          self.$toasted.error('Network error when connecting to API!')
          if (typeof onError === 'function') {
            onError(err)
          }
          // @todo: remove console log when release
          console.log(err)
        })
    },
    // @todo: Consider to use idiomatic way for params like that
    /*
    function thing(...args) {
      var cb = args.pop();
      //... args is now [1, obj]
      cb(args[0]);
    }

    thing(1, { a: 1 }, function (data) {
      console.log(data); // 1
    });
     */
    submitObjectList: function (APIPath, dataObjectList, dataObjectName, additionalData = undefined, onSuccess = undefined, onError = undefined, showToastErr = true, onFinally) {
      let self = this
      let submitData = {
        username: self.$cookies.get('username'),
        sessionId: self.$cookies.get('sessionId'),
        lang: self.$i18n.locale
      }
      submitData[dataObjectName] = dataObjectList
      if (additionalData !== undefined) {
        submitData = Object.assign(submitData, additionalData)
      }
      axios({
        method: 'post',
        url: APIPath,
        data: submitData
      })
        .then(function (response) {
          if (response.data.status === 'success') {
            if (typeof onSuccess === 'function') {
              onSuccess(response.data)
            }
          } else {
            if (showToastErr) {
              self.$toasted.error(response.data.msg)
            }
            if (typeof onError === 'function') {
              onError(response.data)
            }
          }
        })
        .catch(function (err) {
          // if (showToastErr) {
          //   self.$toasted.error('Network error when connecting to API!')
          // }
          if (typeof onError === 'function') {
            onError(err)
          }
          console.log(err)
        })
          .then (function () {
            if (typeof onFinally === 'function') {
              onFinally()
            }
          })
    },
    updateCurrentUserInfo: function () {
      let self = this
      return new Promise((resolve, reject) => {
        axios.post(this.$config.apiPath.profile, {
          'username': this.$cookies.get('username'),
          'sessionId': this.$cookies.get('sessionId')
        })
            .then(function (response) {
              if (response.data.status === 'error') {
                self.$emit('loginEvent', {})
                self.$toasted.error(response.data.msg)
                reject(response.data.msg)
              } else {
                self.$emit('loginEvent', response.data.data)
                resolve(response.data.data)
              }
            })
      })
    },
    getObjectDetail: function (apiPath, data) {
      let self = this
      let submitData = JSON.parse(JSON.stringify(data))
      submitData['username'] = this.$cookies.get('username')
      submitData['sessionId'] = this.$cookies.get('sessionId')

      axios.post(apiPath, submitData)
        .then(function (response) {
          if (response.data.status === 'success') {
            self.$toasted.success(response.data.msg)
          } else {
            self.$toasted.error(response.data.msg)
          }
        })
        .catch(function (err) {
          self.$toasted.error('Error when requesting API!')
          console.log(err)
        })
    },
    submitPVEAction: function (APIPath, dataObjectName, selectedObjectList, callbackOnRow, callbackOnFinish) {
      let self = this
      let noOfSelectedRows = selectedObjectList.length
      let processRowCount = 0

      async.forEachOf(selectedObjectList, function (value, key, callback) {
        self.submitObjectList(APIPath, {id: value.id}, dataObjectName, undefined, function (result) {
          processRowCount++
          callbackOnRow(value, result.data['VMStatus'])
          self.$toasted.success(result.msg)
          if (processRowCount >= noOfSelectedRows) {
            callbackOnFinish()
          }
        }, function (result) {
          processRowCount++
          callbackOnRow(value, 'Unchecked')
          console.log(result.msg)
          if (processRowCount >= noOfSelectedRows) {
            callbackOnFinish()
          }
        })
      })
    }
  }
}
