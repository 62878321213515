<template>
  <div class="row">
    <div class="col-lg-9">
      <div id="step1">
        <div name="step1">
          <div class="d-flex justify-content-start align-items-center">
            <div class="number-circle">
              1
            </div>
            <span
              class="font-weight-bold text-primary"
              style="font-size: 32px"
            >&ensp;{{ $t('orderNew.label.serviceType') }}</span>
          </div>
          <div
            class="btn-group mt-3 row"
            role="group"
          >
            <div
              v-for="serviceType in serviceTypes"
              :key="serviceType['id']"
              class="col-lg-4 col-md-6 col-sm-6 pb-3"
              @change="serviceTypeOnChange($event)"
            >
              <template v-if="!isPromotionFilter || !isPromotionSoldOut(serviceType['id'])">
                <input
                  :id="'serviceTypeId_' + serviceType['id']"
                  type="radio"
                  class="btn-check"
                  name="btnradioType"
                  :value="serviceType['id']"
                  autocomplete="off"
                >
                <label
                  class="btn btn-outline-primary width-full height-full p-1"
                  :for="'serviceTypeId_' + serviceType['id']"
                >
                  <div class="card height-full">
                    <div class="card-header border-bottom">
                      <img
                        :src="serviceType['icon']"
                        :alt="`${serviceType['service_type_name']} VPS LOGO`"
                        class="w-auto height-100"
                      >
                    </div>
                    <div
                      :class="{
                        'card-body': true,
                      }"
                    >
                      <div
                        class="text-justify text-uppercase-none text-dark text-small"
                        style="font-size: small"
                      >{{ serviceType['description'] ? serviceType['description'].find(x => Object.keys(x)[0] === languageSelected.substring(0, 2))[languageSelected.substring(0, 2)] : '' }}</div>
                    </div>
                  </div>
                </label>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div id="step2">
        <div
          v-if="newOrderObject.service_type_id !== null && this.countries.length > 0"
          id="step2"
          name="step2"
          class="mt-3"
        >
          <div class="d-flex justify-content-start align-items-center">
            <div class="number-circle">
              2
            </div>
            <span
              class="font-weight-bold text-primary"
              style="font-size: 32px"
            >&ensp;{{ $t('orderNew.label.location') }}</span>
          </div>
          <div
            class="btn-group mt-3 width-full row"
            role="groupCountry"
          >
            <div
              v-for="(item, key) in this.countries"
              :key="key"
              :class="{'col-lg-3 col-md-6 col-sm-6': true, 'mt-sm-0 mt-4': key !== 0}"
            >
              <input
                :id="item['country_code']"
                type="radio"
                class="btn-check"
                name="btnradioCountry"
                autocomplete="off"
                @change="countryOnChange($event)"
              >
              <label
                class="btn btn-outline-primary button-country width-full p-1"
                :for="item['country_code']"
              >
                <div class="card border-radius-xl">
                  <div>
                    <country-flag
                      class="my-3"
                      :country="item['country_code']"
                      size="big"
                    /><br>
                    <span class="font-size-18 text-primary">{{ item['country'] }}</span>
                  </div>
                </div>
              </label>
              <br>
            </div>
          </div>
          <div v-if="this.countryCodeSelected !== null">
            <div class="font-weight-bold min-with-125">
              {{ $t('orderNew.label.stateSelect') }}:
            </div>
            <select
              class="form-select ps-3 my-2"
              style="background-color: white"
              @change="stateOnChange($event)"
            >
              <option
                v-for="(value, index) in this.states"
                :key="index"
              >
                {{ value }}
              </option>
            </select>
            <div
              class="form-check ps-0"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('orderNew.tooltip.randomState')"
            >
              <input
                id="fcustomCheck1"
                v-model="newOrderObject.random_state"
                class="form-check-input"
                type="checkbox"
                value=""
              >
              <label
                class="custom-control-label"
                for="customCheck1"
              >{{ $t('orderNew.label.randomState') }}</label>
            </div>
          </div>
        </div>
      </div>
      <div id="step3">
        <div
          v-if="newOrderObject.service_region_id !== null"
          id="step3"
          name="step3"
          class="mt-3"
        >
          <title-number
            :title="$t('orderNew.label.plan')"
            number="3"
          />
          <dedicated-plans
            v-if="newOrderObject.service_type_id === $constants.DEDICATED_SERVICE_TYPE_ID"
            :items="this.planFilter"
            :is-promotion-filter="isPromotionFilter"
            class="btn-group mt-3 width-full row"
            role="group"
            @change="planIdOnChange"
          />
          <proxy-plans
            v-else-if="newOrderObject.service_type_id === $constants.PROXY_SERVICE_TYPE_ID"
            :items="this.planFilter"
            class="btn-group mt-3 width-full row"
            role="group"
            @change="planIdOnChange"
          />
          <hosting-plans
            v-else-if="newOrderObject.service_type_id === $constants.HOSTING_SERVICE_TYPE_ID"
            class="btn-group mt-3 width-full row"
            role="group"
            :items="this.hostingPlanListFilter"
            @change="planIdOnChange"
          />
          <vm-plans
            v-else
            :items="this.planFilter"
            class="btn-group mt-3 width-full row overflow-auto"
            role="group"
            @change="planIdOnChange"
          />
        </div>
      </div>
      <div id="step4">
        <div
          v-if="!['none', 'proxy', 'hosting'].includes(planServiceTypeSelected) || (planServiceTypeSelected === 'hosting' && hostingPlanId !== null)"
          name="step4"
          class="mt-4"
        >
          <title-number
            :title="$t('orderNew.label.serverOS')"
            number="4"
          />

          <hosting-info
            v-if="planServiceTypeSelected === 'hosting'"
            v-model="hostingInfo"
          />
          <os-list
            v-else-if="planServiceTypeSelected === 'vms'"
            :items="imageList"
            :image-id-default="newOrderObject.image_detail['id']"
            :language-selected="languageSelected"
            @change="imageOnChange"
          />
        </div>
      </div>
    </div>
    <div
      v-if="newOrderObject.service_type_id !== null"
      class="col-lg-3 mt-lg-0 mt-4"
    >
      <div class="card position-sticky top-1">
        <div class="card-header pb-0">
          <h5 class="text-center">
            {{ $t('orderNew.label.orderDetail') }}
          </h5>
          <hr>
        </div>
        <div class="card-body pt-0">
          <div class="nav-item">
            <a
              class="nav-link text-dark d-flex align-items-center"
              data-scroll=""
              href="javascript:;"
            >
              <span class="material-icons me-2">&#xe335;</span>
              <span class="">{{ newOrderObject['service_type']['service_type_name'] }}</span>
            </a>
          </div>
          <div
            v-if="newOrderObject.service_region_id !== null"
            class="nav-item"
          >
            <a
              class="nav-link text-dark d-flex align-items-center"
              data-scroll=""
              href="javascript:;"
            >
              <span class="material-icons me-2">&#xe0c8;</span>
              <span>
                {{ regions.filter(region => region['region_id'] === newOrderObject.service_region_id).map(r => r['state'])[0] }}
              </span>
            </a>
          </div>
          <!--            service name-->
          <div
            v-if="![null, undefined].includes(selectedPlan)"
            class="nav-item"
          >
            <a
              class="nav-link text-dark d-flex"
              data-scroll=""
              href="javascript:;"
            >
              <span class="material-icons me-2">&#xe30a;</span>
              <span>{{ servicePlanName }}</span>
            </a>
          </div>
          <div v-if="![null, undefined].includes(selectedPlan) && planServiceTypeSelected !== 'hosting'">
            <!--            cpu-->
            <div
              v-if="newOrderObject['service_type']['service_type_name'] !== 'Static Proxy'"
              class="nav-item"
            >
              <a
                class="nav-link text-dark d-flex align-items-center"
                data-scroll=""
                href="javascript:;"
              >
                <span class="material-icons me-2">&#xe322;</span>
                <span v-if="selectedPlan['service_type']['service_type_id'] === 1">
                  <span class="text-primary">{{ selectedPlan['spec']['cpu_core'] }}</span> <span class="small">core</span>,
                  <span class="text-primary">{{ selectedPlan['spec']['cpu_thread'] }}</span> <span class="small">thread ({{ selectedPlan['spec']['cpu_name'] }})</span>
                </span>
                <span v-else>
                  <span class="text-primary">{{ selectedPlan['vcores'] }}</span> <span class="small">VCORES CPU</span>
                </span>
              </a>
            </div>
            <!--            ram-->
            <div
              v-if="newOrderObject['service_type']['service_type_name'] !== 'Static Proxy'"
              class="nav-item"
            >
              <a
                class="nav-link text-dark d-flex align-items-center"
                data-scroll=""
                href="javascript:;"
              >
                <i class="material-icons me-2">&#xe30d;</i>
                <span v-if="selectedPlan['service_type']['service_type_id'] === 1">
                  <span class="text-primary">{{ selectedPlan['spec']['ram'] }}</span> <span class="small">GB RAM</span>
                </span>
                <span v-else>
                  <span class="text-primary">{{ parseInt((selectedPlan['ram'] / 1024).toFixed(0)) }}</span> <span class="small">GB RAM</span>
                </span>
              </a>
            </div>
            <!--            storage-->
            <div
              v-if="newOrderObject['service_type']['service_type_name'] !== 'Static Proxy'"
              class="nav-item"
            >
              <a
                class="nav-link text-dark d-flex align-items-center"
                data-scroll=""
                href="javascript:;"
              >
                <span class="material-icons me-2">&#xe1db;</span>
                <span v-if="selectedPlan['service_type']['service_type_id'] === 1">
                  <span class="text-primary">{{ selectedPlan['spec']['disk'] * selectedPlan['spec']['no_of_disk'] }}</span> <span class="small">GB {{ selectedPlan['spec']['disk_type'] }} storage</span>
                </span>
                <span v-else>
                  <span class="text-primary">{{ selectedPlan['disk'] }}</span> <span class="small">GB {{ selectedPlan['disk_type'] }}</span>
                </span>
              </a>
            </div>
            <!--            transfer limit-->
            <div class="nav-item">
              <a
                class="nav-link text-dark d-flex align-items-center"
                data-scroll=""
                href="javascript:;"
              >
                <span class="material-icons me-2">&#xebba;</span>
                <span v-if="selectedPlan['service_type']['service_type_id'] === 1">
                  <span
                    v-if="selectedPlan['spec']['transfer_limit'] < 0"
                    class="small"
                  >Unlimited transfer limit</span>
                  <span v-else>
                    <span class="text-primary">{{ selectedPlan['spec']['transfer_limit'] }}</span> <span class="small">GB transfer limit</span>
                  </span>
                </span>
                <span v-else>
                  <span
                    v-if="selectedPlan['transfer_limit'] < 0"
                    class="small"
                  >Unlimited transfer limit</span>
                  <span v-else>
                    <span class="text-primary">{{ selectedPlan['transfer_limit'] }}</span> <span class="small">GB transfer limit</span>
                  </span>
                </span>
              </a>
            </div>
            <!--            images-->
            <div
              v-if="newOrderObject.image_detail !== null && newOrderObject['service_type_id'] !== this.$constants.PROXY_SERVICE_TYPE_ID"
              class="nav-item"
            >
              <a
                class="nav-link text-dark d-flex align-items-center"
                data-scroll=""
                href="javascript:;"
              >
                <div
                  class="d-flex text-center me-2"
                  style="width: 24px!important;"
                >
                  <i
                    v-if="newOrderObject.image_detail['os_type'] === 'Windows'"
                    class="material-icons fab fa-windows text-info"
                  />
                  <i
                    v-else
                    class="material-icons fab fa-linux text-primary"
                  />
                </div>
                <span class="small">{{ newOrderObject.image_detail['name'] }}</span>
              </a>
            </div>
          </div>
          <div v-if="newOrderObject.service_type_id === 1">
            <span class="text-danger small text-bold">* {{ $t('orderNew.label.dedicatedCreated24h') }}</span>
          </div>
          <div v-if="newOrderObject.image_detail.description !== null">
            <span class="text-danger small text-bold">* {{ imageDescription }}</span>
          </div>
          <hr style="margin-bottom: 30px">
          <div
            v-if="planServiceTypeSelected !== 'hosting'"
            class="nav-item d-flex justify-content-between align-items-center"
          >
            <a
              class="nav-link text-dark d-flex"
              data-scroll=""
              href="javascript:;"
            >
              <i class="material-icons me-2">&#xebba;</i>
            </a>
            <div class="input-group input-group-sm input-group-dynamic">
              <label class="form-label">{{ $t('orderNew.label.serviceQuantity') }}</label>
              <input
                v-model="newOrderObject.addon.qty"
                type="number"
                min="1"
                style="font-size: 22px"
                class="form-control text-danger text-bold text-center pe-7"
                :disabled="is_testing_order"
              >
              <label class="input-group-text">{{ $t('orderNew.label.services') }}</label>
            </div>
          </div>
          <div class="nav-item d-flex justify-content-between align-items-center mt-3">
            <a
              class="nav-link text-dark d-flex"
              data-scroll=""
              href="javascript:;"
            >
              <i class="material-icons me-2">&#xebcc;</i>
            </a>
            <div class="input-group input-group-sm input-group-dynamic">
              <label class="form-label">{{ $t('orderNew.label.serviceMonth') }}</label>
              <input
                v-model="newOrderObject.addon.period"
                type="number"
                min="1"
                style="font-size: 22px"
                class="form-control text-danger text-bold text-center pe-7"
                :disabled="is_testing_order"
              >
              <label class="input-group-text">{{ is_testing_order ? $t('orderNew.label.dayTrial') : $t('orderNew.label.month') }}</label>
            </div>
          </div>
          <div class="nav-item d-flex justify-content-between align-items-center mt-3">
            <a
              class="nav-link text-dark d-flex"
              data-scroll=""
              href="javascript:;"
            >
              <div style="width: 32px">
                <div
                  class="text-center"
                  style="width: 24px"
                >
                  <i class="fas fa-edit" />
                </div>
              </div>
            </a>
            <lev-input
              v-model="newOrderObject.remark"
              :label="$t('orderNew.label.descOrderNote')"
              form-type="dynamic"
            />
          </div>
          <div class="text-center pt-2">
            <div v-if="is_testing_order">
              <h6 class="font-weight-bold mt-2">
                <span class="text-danger">${{ selectedPlan === null ? 0 : (selectedPlan['testing_price'] || 0) }}</span><small> | {{ newOrderObject.addon.qty }} {{ $t('orderNew.label.services') }} x {{ newOrderObject.addon.period }} {{ $t('orderNew.label.dayTrial') }}</small>
              </h6>
            </div>
            <div v-else>
              <h6 class="font-weight-bold mt-2">
                <span class="text-danger">${{
                  selectedPlan === null ? 0 : ((selectedPlan['price'] + newOrderObject.addon.vcores * 1.5 + newOrderObject.addon.ram * 1 + newOrderObject.addon.disk / 10 * 1) * newOrderObject.addon.qty * newOrderObject.addon.period).toFixed(2)
                }}</span><small> | {{ newOrderObject.addon.qty }} {{ $t('orderNew.label.services') }} x {{ newOrderObject.addon.period }} {{ $t('orderNew.label.month') }}</small>
              </h6>
              <h6
                v-if="newOrderObject.service_type_id === $constants.DEDICATED_SERVICE_TYPE_ID &&
                  selectedPlan !== null && selectedPlan['real_setup_fee'] > 0 &&
                  parseInt(newOrderObject.addon.period) === 1"
              >
                <lev-dropdown-menu
                  class="dropdown-button-without-arrow"
                  :items="[{label: `<div class='text-justify'>${$t('orderNew.tooltip.setupFee')}</div>`}]"
                >
                  <template v-slot:dropdown-button>
                    <span class="text-danger">${{ selectedPlan['real_setup_fee'] * newOrderObject.addon.qty }}</span>
                    <small> | {{ newOrderObject.addon.qty }} x {{ $t('orderNew.label.setupFee') }}</small>&nbsp;
                    <i class="fas fa-question-circle cursor-pointer text-warning" />
                  </template>
                </lev-dropdown-menu>
              </h6>
            </div>
            <hr>
            <div>
              <strong>{{ $t('orderNew.label.total') }}</strong>
              <h1 class="font-weight-bold">
                <small class="text-lg align-top me-1">$</small><span class="text-danger">{{ priceTotal.toFixed(2) }}</span>
              </h1>
            </div>
          </div>
          <div class="form-check ps-0 ms-0">
            <label class="d-inline-flex">
              <input
                id="checkedAcceptPolicy"
                v-model="newOrderObject.acceptPolicy"
                type="checkbox"
                class="form-check-input pe-3"
              >
              <label
                class="custom-control-label"
                for="checkedAcceptPolicy"
                v-html="$t('orderNew.label.agreePolicyAndTerms', [
                  metaData.tosURL,
                  metaData.slaURL,
                  metaData.privacyURL,
                  metaData.siteName
                ])"
              />
            </label>
          </div>
          <div
            v-if="newOrderObject.service_type_id === this.$constants.DEDICATED_SERVICE_TYPE_ID && ![null, undefined].includes(selectedPlan) && selectedPlan['testing_price'] > 0"
            class="form-check ps-0 ms-0 mt-1"
          >
            <label class="d-inline-flex">
              <input
                id="testingOrderAccept"
                v-model="is_testing_order"
                type="checkbox"
                class="form-check-input pe-3"
              >
              <label
                class="custom-control-label font-weight-bold text-danger"
                for="testingOrderAccept"
                v-html="$t('orderNew.label.testingFor24h')"
              />
            </label>
          </div>
          <div class="nav-item pt-2 text-center">
            <lev-button
              type="button"
              variant="success"
              :loading="isCheckOutButtonLoading"
              :class="{
                'disabled': newOrderObject.acceptPolicy === false || selectedPlan === null || newOrderObject.image_detail === null
              }"
              @click="createOrder"
            >
              {{ is_testing_order ? $t('orderNew.button.trial') : $t('orderNew.button.checkOut') }}
            </lev-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {FixBugTheme} from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from "@/mixins/utilities/InputHelper";

import LevDropdownMenu from "@/components/children/lev_components/LevDropdownMenu.vue";
import HostingPlans from "@/components/children/order/table-plans/HostingPlans.vue";
import DedicatedPlans from "@/components/children/order/table-plans/DedicatedPlans.vue";
import ProxyPlans from "@/components/children/order/table-plans/ProxyPlans.vue";
import VmPlans from "@/components/children/order/table-plans/VmPlans.vue";
import OsList from "@/components/children/order/OsList.vue";
import HostingInfo from "@/components/children/order/HostingInfo.vue"
import TitleNumber from "@/components/children/order/TitleNumber.vue";
import LevButton from "@/components/children/lev_components/LevButton.vue";
import LevInput from "@/components/children/lev_components/LevInput.vue";

export default {
  name: "NewOrder",
  components: {
    LevDropdownMenu,
    HostingPlans,
    DedicatedPlans,
    ProxyPlans,
    VmPlans,
    OsList,
    HostingInfo,
    TitleNumber,
    LevButton,
    LevInput
  },
  mixins: [dataRequester, inputHelper],
  props: {
    metaData: Object,
    languageSelected: String,
    currentUser: Object
  },
  data() {
    return {
      serviceTypes: [],
      planList: [],
      hostingPlanList: [],
      newOrderObject: {
        random_state: false,
        service_type: null,
        service_type_id: null,
        qty: null,
        service_plan_id: null,
        service_region_id: null,
        service_other_info: null,
        period: null,
        image_detail: null,
        remark: '',
        addon: {
          vcores: 0,
          ram: 0,
          disk: 0,
          period: 1,
          qty: 1,
        },
        acceptPolicy: true
      },
      selectedPlan: null,
      countryCodeSelected: null,
      stateSelected: undefined,
      countries: [],
      regions: [],
      states: [],
      planFilter: [],
      imageList: [],
      is_testing_order: false,
      filter: undefined,
      hostingInfo: {},
      hostingPlanId: null,
      isCheckOutButtonLoading: false,
    }
  },
  computed: {
    priceTotal: function () {
      if (this.is_testing_order) {
        return this.selectedPlan === null ? 0 : (this.selectedPlan['testing_price']) || 0
      } else {
        return this.selectedPlan === null ? 0 : ((this.selectedPlan['price'] + this.newOrderObject.addon.vcores * 1.5 + this.newOrderObject.addon.ram * 1 + this.newOrderObject.addon.disk / 10 * 1) * this.newOrderObject.addon.period * this.newOrderObject.addon.qty + (parseInt(this.newOrderObject.addon.period) === 1 ? (this.selectedPlan['real_setup_fee'] || 0) : 0) * this.newOrderObject.addon.qty)
      }
    },
    imageDescription: function () {
      if (this.newOrderObject.service_plan_id !== null
          && this.newOrderObject.image_detail['description'] !== null
          && this.newOrderObject['service_type_id'] !== this.$constants.PROXY_SERVICE_TYPE_ID) {
        try {
          return JSON.parse(this.newOrderObject.image_detail['description'])['description'][this.languageSelected.substring(0,2)]
        } catch {
          return null
        }
      } else {
        return null
      }
    },
    isPromotionFilter: function () {
      return this.filter === 'promotion'
    },
    isPromotionSoldOut: function () {
      return function (serviceTypeId) {
        return this.planList.filter(plan => plan['service_type']['service_type_id'] === serviceTypeId).every(plan => plan['stock'] === 0)
      }
    },
    planServiceTypeSelected: function () {
      if (this.newOrderObject['service_type_id'] === this.$constants.PROXY_SERVICE_TYPE_ID) {
        return 'proxy'
      } else if (this.newOrderObject['service_type_id'] === this.$constants.HOSTING_SERVICE_TYPE_ID) {
        return 'hosting'
      } else {
        if (this.newOrderObject['service_plan_id'] !== null) {
          return 'vms'
        } else {
          return 'none'
        }
      }
    },
    hostingPlanListFilter: function () {
      if (this.stateSelected === undefined) {
        return []
      } else {
        return this.hostingPlanList.filter(plan => plan['region']['country_code'] === this.countryCodeSelected && plan['region']['state'] === this.stateSelected)
      }
    },
    servicePlanName: function () {
      if (![null, undefined].includes(this.selectedPlan['service_type']) && this.selectedPlan['service_type']['service_type_id'] === this.$constants.DEDICATED_SERVICE_TYPE_ID) {
        return this.selectedPlan['dedicated_plan_name']
      } else if (this.selectedPlan['service_type_id'] === this.$constants.HOSTING_SERVICE_TYPE_ID) {
        return this.selectedPlan['name']
      } else {
        return this.selectedPlan['vm_plan_name']
      }
    }
  },
  watch: {
    states: {
      handler: function () {
        let self = this
        self.newOrderObject.service_region_id = self.planList.filter(plan => plan['region']['country_code'] === self.countryCodeSelected && plan['region']['state'] === self.states[0]).map(plan => plan['region']['region_id'])[0]
        self.newOrderObject.service_plan_id = null
        // self.newOrderObject.image_detail = null
        self.clearCheckedRadioButtonByName('btnradioPlan')
        this.newOrderFilterLoad()
      },
      deep: true
    },
    is_testing_order: {
      handler: function () {
        let self = this
        if(self.is_testing_order){
          self.newOrderObject.addon.qty = 1
          self.newOrderObject.addon.period = 1
        }
      },
      deep: true
    },
    selectedPlan: {
      handler: function () {
        if (![null, undefined].includes(this.selectedPlan)) {
          if ([null, undefined, 0].includes(this.selectedPlan['testing_price'])) {
            this.is_testing_order = false
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  created() {
    let self = this
    this.filter = this.$route.query['filter']

    this.submitObjectList(this.$config.apiPath.vmPlanList, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.planList = result.data['VMPlanObjectList']
        self.submitObjectList(self.$config.apiPath.dedicatedPlanList, undefined, undefined, undefined, function(response) {
          if(response.status === 'success'){
            self.planList = self.planList.concat(response.data['dedicatedPlanObjectList'])
            if (self.isPromotionFilter) {
              self.planList = self.planList.filter(plan => plan['is_promoted'] === 1)
            } else {
              self.planList = self.planList.filter(plan => plan['is_promoted'] === 0)
            }
          } else {
            self.$toasted.error(response.msg)
          }
        })
      } else {
        self.$toasted.error(result.msg)
      }
    })
    this.submitObjectList(this.$config.apiPath.hostingPlanList, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.hostingPlanList = result.data['WebHostingPlansList']
      } else {
        self.$toasted.error(result.msg)
      }
    })
    this.getDataFromAPI(this.$config.apiPath.imageList, function (result) {
      // self.imageList = result.data.sort((a, b) => a['os_type'] < b['os_type'] ? 1 : -1)
      self.imageList = result.data
      self.newOrderObject.image_detail = self.imageList.filter(x => x['name'].includes('2012R2'))[0]
    })
    this.getDataFromAPI(this.$config.apiPath.getServiceTypes, function (result) {
      self.serviceTypes = result.data['serviceTypeObjectList']
    })
  },
  methods: {
    FixBugTheme,
    newOrderFilterLoad: function () {
      let self = this

      if (self.newOrderObject.service_type_id !== null) {
        self.planFilter = self.planList.filter(plan => plan['service_type']['service_type_id'] === self.newOrderObject.service_type_id &&
            plan['region']['region_id'] === self.newOrderObject.service_region_id)

        if(self.newOrderObject.service_type_id === self.$constants.DEDICATED_SERVICE_TYPE_ID) {
          self.planFilter = self.planFilter.sort((a, b) => a['dedicated_plan_name'] > b['dedicated_plan_name'] ? 1 : -1)
        } else if(self.newOrderObject.service_type_id === self.$constants.HOSTING_SERVICE_TYPE_ID) {
          self.planFilter = self.hostingPlanList
        } else {
          self.planFilter = self.planFilter.sort((a, b) => a['vm_plan_name'] > b['vm_plan_name'] ? 1 : -1)
        }
      }
    },
    countryOnChange: function (event) {
      let self = this
      self.countryCodeSelected = event.target.id
      self.is_testing_order = false
      self.states = [...new Map(self.regions.filter(r => r['country_code'] === self.countryCodeSelected).map(region => [region['state'], region])).values()].map(x => x['state'])
      this.stateSelected = self.states[0]
      this.newOrderFilterLoad()
      window.scrollTo({
        top: document.getElementById("step3").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    stateOnChange: function (event) {
      let self = this
      this.stateSelected = event.target.value
      self.newOrderObject.service_region_id = self.planList.filter(plan => plan['region']['country_code'] === self.countryCodeSelected && plan['region']['state'] === this.stateSelected).map(plan => plan['region']['region_id'])[0]
      self.newOrderObject.service_plan_id = null
      self.is_testing_order = false
      // self.newOrderObject.image_detail = null
      self.clearCheckedRadioButtonByName('btnradioPlan')
      this.newOrderFilterLoad()
    },
    serviceTypeOnChange: function (event) {
      let self = this
      self.newOrderObject.service_type = self.serviceTypes.find(x => x.id === parseInt(event.target.value))
      self.newOrderObject.service_type_id = parseInt(event.target.value)
      self.newOrderObject.service_plan_id = null
      // self.newOrderObject.image_detail = null
      self.newOrderObject.service_region_id = null
      self.hostingPlanId = null
      self.selectedPlan = null
      self.countryCodeSelected = null
      self.is_testing_order = false
      self.stateSelected = undefined
      if (this.planServiceTypeSelected === 'hosting') {
        self.newOrderObject.addon.qty = 1
        self.hostingInfo['email'] = self.currentUser['email']
        self.regions = [...new Map(self.hostingPlanList.filter(plan => plan['service_type_id'] == self.$constants.HOSTING_SERVICE_TYPE_ID).map(item => [item['region']['region_id'], item])).values()].map(vm => vm['region']).sort((a, b) => a['region_id'] > b['region_id'] ? 1 : -1)
        self.countries = [...new Map(self.regions.map(region => [region['country_code'], region])).values()]
      } else {
        self.regions = [...new Map(self.planList.filter(plan => plan['service_type']['service_type_id'] == self.newOrderObject.service_type_id).map(item => [item['region']['region_id'], item])).values()].map(vm => vm['region']).sort((a, b) => a['region_id'] > b['region_id'] ? 1 : -1)
        self.countries = [...new Map(self.regions.map(region => [region['country_code'], region])).values()]
      }
      self.clearCheckedRadioButtonByName('btnradioCountry')
      this.newOrderFilterLoad()
      window.scrollTo({
        top: document.getElementById("step2").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    planIdOnChange: function (planId) {
      let self = this
      if (this.planServiceTypeSelected === 'hosting') {
        self.hostingPlanId = planId
        self.selectedPlan = self.hostingPlanList.find(plan => plan['id'] === planId)
      } else {
        self.newOrderObject.service_plan_id = planId
        self.selectedPlan = self.planFilter.filter(plan => plan['service_plan_id'] === self.newOrderObject.service_plan_id)[0]
      }

      window.scrollTo({
        top: document.getElementById("step4").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    imageOnChange: function (imageId) {
      let self = this
      self.newOrderObject.image_detail = self.imageList.find(image => image['id'] === imageId)
    },
    clearCheckedRadioButtonByName: function (radioButtonName) {
      $('input:radio[name=\"' + radioButtonName +'\"]').removeAttr('checked');
      $('input:radio[name=\"' + radioButtonName +'\"]').prop('checked', false);
    },
    createOrder() {
      let self = this
      let itemRequest = []

      if (self.planServiceTypeSelected === 'hosting') {
        itemRequest = [
          {
            service_type_id: self.newOrderObject.service_type_id,
            qty: self.newOrderObject.addon.qty,
            period: self.newOrderObject.addon.period,
            hosting_info: {
              plan_id: self.hostingPlanId,
              region_id: self.newOrderObject.service_region_id,
              service_country: self.countryCodeSelected,
              domain: self.hostingInfo.domain,
              email: self.hostingInfo.email,
              username: self.hostingInfo.username,
              password: self.hostingInfo.password,
            }
          }
        ]
      } else {
        itemRequest = [
          {
            service_type_id: self.newOrderObject.service_type_id,
            qty: self.newOrderObject.addon.qty,
            service_plan_id: self.newOrderObject.service_plan_id,
            service_region_id: self.newOrderObject.service_region_id,
            period: self.newOrderObject.addon.period,
            service_other_info: JSON.stringify({image_id: self.newOrderObject.image_detail['id']})
          }
        ]
      }

      self.isCheckOutButtonLoading = true
      this.submitObjectList(this.$config.apiPath.orderPlace, {
        type: self.is_testing_order ? 'testing' : 'new',
        random_state: self.newOrderObject.random_state,
        remark: self.newOrderObject.remark,
        items: itemRequest
      }, 'order', undefined, function(result) {
        if(result.status === 'success'){
          window.location.href = "#/home/order/detail/" + result.data.id
        } else {
          self.$toasted.error(result.msg)
        }
      }, undefined, true, function () {
        self.isCheckOutButtonLoading = false
      })
    }
  }
}
</script>
