<template>
  <!-- eslint-disable vue/no-v-html -->
  <nav class="row">
    <ul
      v-if="tablePagination !== null"
      class="justify-content-start col-3"
    >
      <label>{{ this.$t('levtable.pagination.show', [tablePagination.from, tablePagination.to, tablePagination.total]) }}</label>
    </ul>
    <ul class="pagination pagination-primary justify-content-end col-9">
      <li :class="['page-item', {'disabled': isOnFirstPage}]">
        <a
          class="page-link"
          @click.prevent="loadPage('prev')"
        >
          <span aria-hidden="true"><span class="material-icons">&#xe5e0;</span></span>
        </a>
      </li>

      <template v-if="notEnoughPages">
        <li
          v-for="n in totalPage"
          :key="n"
          :class="['page-item', {'active': isCurrentPage(n)}]"
        >
          <a
            class="page-link"
            @click.prevent="loadPage(n)"
            v-html="'<span>' + (n) + '</span>'"
          />
        </li>
      </template>
      <template v-else>
        <!-- Show ... for the first page -->
        <template v-if="windowStart > firstPage">
          <li class="page-item">
            <a
              class="page-link"
              @click.prevent="loadPage(firstPage)"
              v-html="'<span>' + (firstPage) + '</span>'"
            />
          </li>
          <li :class="['page-item', {'disabled': true}]">
            <a class="page-link">
              <span aria-hidden="true"><span class="material-icons">&#xe5d3;</span></span>
            </a>
          </li>
        </template>
        <li
          v-for="n in windowSize"
          :key="n"
          :class="['page-item', {'active': isCurrentPage(windowStart + n - 1)}]"
        >
          <a
            class="page-link"
            @click.prevent="loadPage(windowStart + n - 1)"
            v-html="'<span>' + (windowStart + n - 1) + '</span>'"
          />
        </li>
        <!-- Show ... for the last page -->
        <template v-if="windowStart + windowSize < totalPage">
          <li :class="['page-item', {'disabled': true}]">
            <a class="page-link">
              <span aria-hidden="true"><span class="material-icons">&#xe5d3;</span></span>
            </a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click.prevent="loadPage(lastPage)"
              v-html="'<span>' + (lastPage) + '</span>'"
            />
          </li>
        </template>
      </template>

      <li :class="['page-item', {'disabled': isOnLastPage}]">
        <a
          class="page-link"
          href=""
          @click.prevent="loadPage('next')"
        >
          <span aria-hidden="true"><span class="material-icons">&#xe5e1;</span></span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import VuetablePaginationMixin from "vuetable-2/src/components/VuetablePaginationMixin";
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  mixins: [VuetablePaginationMixin],
  mounted() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme
  }
};
</script>
