<template>
  <main class="main-content  mt-0">
    <div
      class="page-header align-items-start min-vh-100"
      style="background-image: url('../../assets/img/bg/upgrading.jpeg');"
    >
      <span class="mask bg-gradient-dark opacity-6" />
      <div class="container my-auto">
        <div class="row">
          <div class="col-lg-12 m-auto text-center">
            <h1 class="display-1 text-bolder text-white">
              Upgrading system...
            </h1>
            <h3
              class="text-white"
              style="font-weight: normal"
            >
              Erm. We are upgrading <strong class="text-primary">LowendViet</strong> management system.
            </h3>
            <p class="lead text-white">
              Pleaes wait a moment. If you need any support, contact us at
              <a
                href="https://fb.com/LowendVietServer"
                class="text-bold text-primary"
                target="_blank"
              >Facebook</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Upgrading"
}
</script>

<style scoped>

</style>
