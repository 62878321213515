<template>
  <div class="p-0 m-0">
    <div>
      <div class="width-full p-1 mb-1">
        <div class="card">
          <div class="p-2">
            <div class="row text-center text-bold small text-uppercase">
              <template v-for="(column, index) in columns">
                <div v-if="!column['isHide']" class="col align-self-center" :key="index">
                  <lev-dropdown-menu v-if="column['key'] === 'setup_fee'"
                      class="text-uppercase-none dropdown-button-without-arrow"
                      :items="[{label: `<div class='text-justify'>${$t('orderNew.tooltip.setupFee')}</div>`}]"
                  >
                    <template v-slot:dropdown-button>
                      <span class="text-uppercase">{{ $t('orderNew.table.h_setupFee') }}&nbsp;</span>
                      <i class="fas fa-question-circle text-warning cursor-pointer" />
                    </template>
                  </lev-dropdown-menu>
                  <lev-dropdown-menu v-else-if="column['key'] === 'setup_free'"
                      class="text-uppercase-none dropdown-button-without-arrow"
                      :items="[{label: `<div class='text-justify'>${$t('orderNew.tooltip.noRefund')}</div>`}]"
                  >
                    <template v-slot:dropdown-button>
                      <span class="text-uppercase">{{ $t('orderNew.table.h_setupFree') }}&nbsp;</span>
                      <i class="fas fa-question-circle text-warning cursor-pointer" />
                    </template>
                  </lev-dropdown-menu>
                  <span v-else>{{ column['label'] }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="mt-2 text-center">
          <strong class="text-danger font-italic">*** {{ $t('orderNew.label.setupFree2Months') }}</strong>
        </div>
      </div>
    </div>
    <div
        v-for="(value, index) in this.items"
        :key="index"
    >
      <template v-if="value['stock'] > 0 || !isPromotionFilter">
        <input
            :id="'planId_' + value['service_type']['service_type_id'] + value['service_plan_id']"
            type="radio"
            class="btn-check"
            name="btnradioPlan"
            :value="value['service_plan_id']"
            autocomplete="off"
            @change="$emit('change', value['service_plan_id'])"
        >
        <label
            class="btn btn-outline-primary width-full p-1 "
            :for="'planId_' + value['service_type']['service_type_id'] + value['service_plan_id']"
        >
          <div class="card">
            <div v-if="isPromotionFilter" class="ribbon"><span>{{ value['promotion_content'] }}</span></div>
            <div class="row p-2 align-items-center text-dark">
              <div class="col text-md">
                <div>
                  <span>{{ value['dedicated_plan_name'] }}</span>
                </div>
              </div>
              <div class="col">
                      <span class="text-xs">
                        {{ value['spec']['cpu_core'] }} core,
                        {{ value['spec']['cpu_thread'] }} thread<br>
                        CPU {{ value['spec']['cpu_name'] }}
                      </span>
              </div>
              <div class="col">
                <span class="text-md">{{ value['spec']['ram'] }}</span>GB</div>
              <div class="col">
                <span class="text-md">{{ value['spec']['no_of_disk'] }} <span class="smaller">x</span> {{ value['spec']['disk'] }}</span>GB {{ value['spec']['disk_type'] }}
              </div>
              <div class="col">
                <div v-if="value['spec']['transfer_limit'] > 0"><span class="text-md">{{ value['spec']['transfer_limit'] }}</span> GB</div>
                <div v-else>{{ $t('orderNew.table.unlimited') }}</div>
                <div><span class="text-md">{{ value['spec']['network_speed'] }}</span> Mbps</div>
              </div>
              <div class="col">
                <span class="text-md">${{ value['price'] }}</span>/{{ $t('orderNew.table.mo') }}
              </div>
              <div class="col">
                      <span
                          v-if="value['real_setup_fee'] < value['standard_setup_fee']"
                          class="text-lg"
                      >
                        <small class="text-decoration-line-through text-secondary text-normal text-xs">${{ value['standard_setup_fee'] }}</small> <span class="text-success">${{ value['real_setup_fee'] }}</span>
                      </span>
                <span
                    v-else-if="value['real_setup_fee'] === 0"
                    class="text-lg text-success"
                >${{ value['real_setup_fee'] }}</span>
                <span
                    v-else
                    class="text-lg"
                >${{ value['real_setup_fee'] }}</span>
              </div>
              <div class="col height-full" v-if="isPromotionFilter">
                <div class="align-content-center text-lg text-success" style="height: 100%">{{ `${value['stock']}` }}</div>
              </div>
              <div v-if="!isPromotionFilter" class="col text-justify">
                <label
                    class="text-uppercase-none smaller"
                    v-html="freeSetupLabel(value['free_setup_period'])"
                />
              </div>
              <div class="col text-justify">
                <label
                    class="text-uppercase-none smaller"
                    v-html="value['description']"
                />
              </div>
            </div>
          </div>
        </label>
      </template>
    </div>
  </div>
</template>

<script>
import LevDropdownMenu from "@/components/children/lev_components/LevDropdownMenu.vue";
import moment from "moment/moment";
import inputHelper from "@/mixins/utilities/InputHelper";

export default {
  name: "DedicatedPlans",
  components: {LevDropdownMenu},
  mixins: [inputHelper],
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    isPromotionFilter: {
      type: Boolean,
      default: false
    },
  },
  data () {
    let self = this
    return {
      columns: [
        {
          key: 'name',
          label: self.$t('orderNew.table.h_name')
        },
        {
          label: self.$t('orderNew.table.h_cpu')
        },
        {
          label: self.$t('orderNew.table.h_ram')
        },
        {
          label: self.$t('orderNew.table.h_storage')
        },
        {
          label: self.$t('orderNew.table.h_network')
        },
        {
          label: self.$t('orderNew.table.h_price')
        },
        {
          key: 'setup_fee',
          label: self.$t('orderNew.table.h_setupFee')
        },
        {
          label: self.$t('orderNew.table.h_stock'),
          isHide: !self.isPromotionFilter
        },
        {
          key: 'setup_free',
          label: self.$t('orderNew.table.h_setupFree'),
          isHide: self.isPromotionFilter
        },
        {
          label: self.$t('orderNew.table.h_note')
        }
      ]
    }
  },
  computed: {
    freeSetupLabel: function () {
      let self = this
      return (freeSetup => {
        if (!freeSetup) {
          return ``
        } else {
          let freeSetupString = ''
          for(let i = 0; i < freeSetup.length; i++) {
            let datePoint = 5 //5th of the month
            let item = freeSetup[i]
            const currentDate = moment()
            let dateToday = currentDate.date()
            let dateList = item['dates']
                .map(x => {
                  let month = item['month'].toString().padStart(2, '0')
                  let year = item['year'].toString().padStart(2, '0')
                  let date = x.toString().padStart(2, '0')
                  return `${year}-${month}-${date}`
                })
                .sort()

            for (let j = 0; j < dateList.length; j++) {
              let date = dateList[j]
              if (moment(date).isSameOrAfter(currentDate)) {
                if (dateToday < datePoint && moment(date).date() < datePoint) {
                  freeSetupString += `<strong class="text-success">${date}</strong><br/>`
                } else if (dateToday >= datePoint) {
                  if (moment(date).isSameOrBefore(currentDate.clone().add(1, 'months'))) {
                    freeSetupString += `<strong class="text-success">${date}</strong><br/>`
                  }
                }
              }
            }

            if (dateToday < datePoint) {
              break
            }
          }

          if (freeSetupString === '') {
            return ``
          } else {
            return `${self.$t('orderNew.label.setupFree')}<br/> ${freeSetupString}`
          }
        }
      })
    }
  }
}
</script>

<style lang="css">
.box {
  position: relative;
  border: 1px solid #BBB;
  background: #EEE;
}
.ribbon {
  position: absolute;
  left: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
  pointer-events: none;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; left: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #79A70A;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #79A70A;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
</style>
